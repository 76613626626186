import React from "react";
import { useState, createContext, useContext, useEffect } from "react";

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useParams,
    useNavigate,
    useLocation,
} from 'react-router-dom';

import SectionClauses from "../../components/tenancyAgreements/SectionClauses.js"
import ErrorBox from "../../components/tenancyAgreements/Clause/ErrorBox.js"
import AgreementStartSplashOverlay from "../../components/tenancyAgreements/AgreementStartSplashOverlay.js"
import ContinueButton from "../../components/ContinueButton.js"
import BackButton from "../../components/BackButton.js"
import FormProgressBar from "../../components/FormProgressBar.js"
import FormProgressBar2 from "../../components/FormProgressBar2.js"
import NavBar from "../../components/NavBar.js"
import Page from "../../components/Page.js"

// import {AgreementDataContext} from "../../App.js"
import {AgreementDataContext} from "../../screens/tenancyAgreements/Container.js"


function Section(){
  const {checkSectionComplete, agreementData, setAgreementData, agreementDataLoaded, uploadAgreementData, checkPreviousSectionsComplete} = useContext(AgreementDataContext);
  const { tenancyId, taId, sectionSlug } = useParams()
  const navigate = useNavigate();
  const location = useLocation()
  const [sectionIndex, setSectionIndex] = useState(null);
  const [error, setError] = useState();
  const [allValid, setAllValid] = useState(false);

  useEffect(() => {
    console.log("section " + sectionSlug + " loaded with ad ", agreementData);
    window.scrollTo(0, 0)
  }, []);



  useEffect(() => {
    console.log("section slug changed to ", sectionSlug);
  }, [sectionSlug]);

  useEffect(() => {
    if(['inTenantSigning', 'testMode'].includes(agreementData.status)){
      setAllValid(true)
    } else {
      const [complete, incompleteClauses] = checkSectionComplete(sectionIndex)
      setAllValid(complete)
      if (complete){
        setError()
      }
    }
  }, [agreementData]);

  function getSectionIndex(){
    console.log("attempting to get section index. It was", sectionIndex, " new slug is '" + sectionSlug +  "' ad is ", agreementData);
    if (agreementData){
      for (var i = 0; i < agreementData.sections.length; i++) {
        if (agreementData.sections[i].slug == sectionSlug){
          setSectionIndex(i)
          console.log("the section '", sectionSlug, "' has index: ", i);
          console.log("clauses should be", agreementData.sections[i].clauses);
          break;
        }
      }
    } else {
      console.log("couldn't get section because no AD");
      console.log("fail ad", agreementData);
    }
  }

  useEffect(() => {
    if (agreementDataLoaded){
      console.log("ADloaded is ", agreementDataLoaded, "The AD is ", agreementData);
      getSectionIndex()
    }
  }, [sectionSlug, agreementDataLoaded]);


  // Function to check whether there are previous incomplete sections (and nav back if necessary)
  useEffect(() => {
    if (sectionIndex){
      console.log("Checking if all previous sections are complete");
      const [c, is, lis, his] = checkPreviousSectionsComplete(sectionIndex)
      if (c != true){
        // There are incomplete sections so navigating back to those
        navigate("/tenancies/" + tenancyId + "/agreements/" + taId + "/s/" + agreementData.sections[lis].slug + location.search)
      }
    }
  }, [sectionIndex]);

  useEffect(() => {
    console.log("section index has been updated to ", sectionIndex);
  }, [sectionIndex]);

  function navigateForward(){
    if (sectionIndex+1 >= agreementData.sections.length){
      console.log("this is the last section");
      // update doc status to inLandlordSigning

      navigate("/tenancies/" + tenancyId + "/agreements/" + taId + "/" + 'sign' + location.search)
      // navigate(location.pathname.split('/').slice(0,-2).join('/') + '/' + 'sign' + location.search)
    } else {
      window.scrollTo(0, 0)
      navigate("/tenancies/" + tenancyId + "/agreements/" + taId + "/s/" + agreementData.sections[sectionIndex+1].slug + location.search)
      // navigate(location.pathname.split('/').slice(0,-1).join('/') + '/' + agreementData.sections[sectionIndex+1].slug + location.search)
    }
  }

  function handleHideSplash(){

  }

  // useEffect(() => {
  //   console.log("ADloaded is ", agreementDataLoaded, "The AD is ", agreementData);
  // }, [agreementDataLoaded]);


  // console.log("taID: ", taId);
  // console.log("sectionSlug: ", sectionSlug);
  // console.log("ad: ", agreementData);


  if (sectionIndex === null){
    console.log("no index");
    return (<></>)
  }

  // return(
  //     <div className="" >
  //       <p>We could find the tenancy agreement for {taId} within this section '{sectionSlug}'</p>
  //       <p>Section index is {sectionIndex}</p>
  //       <p>{JSON.stringify(agreementData)}</p>
  //     </div>
  // )

  return (
      <div className="SectionContainer">
      <div className="SectionHeaderAll" >
        <div className="SectionHeaderMain">
          <div style={{float: 'left', position: 'absolute'}} >
            <BackButton onClick={() => {
                try {
                  if (sectionIndex == 0){
                    navigate(-1)
                  } else {
                    // const prevSectionSlug = agreementData.sections[index-1].slug
                    // navigate("/" + prevSectionSlug)
                    // navigate(location.pathname.split('/').slice(0,-1).join('/') + '/' + agreementData.sections[sectionIndex-1].slug + location.search)
                    navigate("/tenancies/" + tenancyId + "/agreements/" + taId + "/s/" + agreementData.sections[sectionIndex-1].slug + location.search)
                    console.log("navvvvv");
                    window.scrollTo(0, 0)
                  }
                } catch (e) {
                  console.log("defaulting back in browser");
                  navigate(-1)
                }

              }}
            />
          </div>
        <h1 className="SectionTitle">{agreementData.sections[sectionIndex].title}</h1>

        <FormProgressBar2 length={agreementData.sections.length} activeIndex={sectionIndex}/>
        </div>
      </div>
        {agreementData.sections[sectionIndex].starterText &&
          <p className="SectionStarterText">{agreementData.sections[sectionIndex].starterText}</p>
        }
        <SectionClauses
          clauses={agreementData.sections[sectionIndex].clauses}
          sectionIndex={sectionIndex}
        />
      <ContinueButton
        style={{opacity: allValid?1:0.5}}
        onClick={async() => {
          // navigateForward()
          // return

          if(['inTenantSigning', 'testMode'].includes(agreementData.status)){
            console.log("skipping validation due to status");
            navigateForward()
          } else {
            const [complete, incompleteClauses] = checkSectionComplete(sectionIndex)
            if (complete===true){
              console.log("all clauses complete");
              setAgreementData(() => {
                const newAD = {...agreementData};
                newAD.sections[sectionIndex].complete = true
                return (newAD)
              })
              await uploadAgreementData(
                () => {
                  console.log("TA Upload: Success ON OUTER AWAIT");
                },
                () => {
                  console.log("TA Upload: FAILED ON OUTER AWAIT");
                }
               )
              // .then(() => {
              //   console.log("TA Upload: Success ON OUTER AWAIT");
              // })
              // .catch(() => {
              //   console.log("TA Upload: FAILED ON OUTER AWAIT");
              // })
              setError()
              navigateForward()
            } else {
              setAgreementData(() => {
                const newAD = {...agreementData};
                newAD.sections[sectionIndex].complete = false
                return (newAD)
              })
              setError({message: "You must complete all clauses"})
              // console.log("incompleteClauses when continuing", incompleteClauses);
            }
          }


        }}/>
        {error && <ErrorBox {...error}/>}
        {agreementData.showInitialSplash!=false && <AgreementStartSplashOverlay id={"section-splash-" + taId} onHide={handleHideSplash}/> }
      </div>
  )
}

export default Section
