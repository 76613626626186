import React, {} from "react";

function MultiLineP({children, marginBottom,  containerStyle, pStyle, className, ...otherProps}) {

  if (!children) return null

  if (Array.isArray(children)){
    // console.log("MultiLineP has an array", children);
    if(children.length > 0){
      children = children[0]
    }
  }


  function splitAtNewLine1(text){
    var items = []
    try {
      const splits = text.split('\\n').join('\n').split('\n')
      for (let i = 0; i < splits.length; i++) {
        items.push(<p key={splits[i]} className={"MultiLineItem " + className} style={{...{marginBottom: marginBottom || "1em"}, ...pStyle}}>{splits[i]}</p>)
      }
      return items
    } catch (e) {
      console.error("could not split for MultiLineP");
      return [text]
    }
  }

  function splitAtNewLine(text){
    var items = []
    try {
      const splits = text.split('\\n').join('\n').split('\n')
      for (let i = 0; i < splits.length; i++) {
        items.push(splits[i])
      }
      return items
    } catch (e) {
      console.error("could not split for MultiLineP");
      return [text]
    }
  }

  function makeBold(text){
    // console.log("make bold with text", text);
    var items = []
    try {
      var bold = /\*\*(.*?)\*\*/gm;
      // var children = children.replace(bold, '<strong>$1</strong>');
      var splits = text.split(bold);
      // console.log("bold splits: ", splits);
      for (var i = 0; i < splits.length; i++) {
        if (i% 2 != 0){
          items.push(<strong>{splits[i]}</strong>)
        } else {
          items.push(splits[i])
        }
      }
      // return (text)
      return(items)
    } catch (e) {
      return text
      console.error("could not split for ClauseMainText");
    }
  }

  const splitChildren = splitAtNewLine(children)
  // console.log("split children: ", splitChildren);
  const newChildren = []

  for (var i = 0; i < splitChildren.length; i++) {
    // console.log("running b on sc ",i, splitChildren[i], makeBold(splitChildren[i]));
    const bolded = makeBold(splitChildren[i])
    newChildren.push(
      <p key={i} className={"MultiLineItem " + className} style={{...{marginBottom: marginBottom || "1em"}, ...pStyle}}>
        {bolded}
      </p>
    )
  }

  return (
    <div className="MultiLineContainer" style={containerStyle} {...otherProps}>
      {newChildren}
    </div>
  );

  // try {
  //
  //
  //
  // } catch (e) {
  //   console.error("could not split for MultiLineP");
  //   return {children}
  // }



}


export default MultiLineP;
