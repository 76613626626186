import React from "react"
import axios from "axios"
// import ClauseOption from "./ClauseOption"
import { useState, createContext, useContext, useRef, useEffect } from "react";

import {AgreementDataContext} from "../../../screens/tenancyAgreements/Container.js"

import ErrorBox from "./ErrorBox.js"
import MultiLineP from "../../MultiLineP.js"
import ClauseMainText from "../../tenancyAgreements/Clause/ClauseMainText.js"

function PlainClause({text, icon, iconSlug, sectionIndex, clauseIndex, minChar, maxChar}){
  const {agreementData, setAgreementData} = useContext(AgreementDataContext);
  const [expanded, setExpanded] = React.useState(false);
  const [data, setData] = React.useState(agreementData.sections[sectionIndex].clauses[clauseIndex]);
  const [errorMessage, setErrorMessage] = React.useState("")

  function handleClick(event){
    if (data.description){
      setExpanded(!expanded)
      event.preventDefault()
    }
  }

  //
  if (data?.hidden === true){
    return (<></>)
  }


  //
  return (
    <div className={"Clause" + (expanded ? " expanded": "")}  onClick={handleClick} id={data.id}>
      <div className="ClauseInner">
        <div className="clauseIcon">
          <img src={"/clause-image/" + data.iconSlug}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src="/clause-image/placeholder.svg";
            }}
            className="ClauseIcon"
            alt={text}
          />
        </div>
        <div className="ClauseTextContainer">
          <ClauseMainText>{data?.text}</ClauseMainText>
        </div>
      </div>
      {expanded &&
        <div className="ClauseExpansion">
          <MultiLineP className="ClauseDescription">{data.description}</MultiLineP>
        </div>
      }
      {errorMessage && <ErrorBox message={errorMessage}/>}
    </div>

  )

}

export default PlainClause;
