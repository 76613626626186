import React from "react"
import axios from "axios"
// import ClauseOption from "./ClauseOption"
import { useState, createContext, useContext, useRef, useEffect } from "react";

import ExpandingInput from "../../ExpandingInput.js"
import ErrorBox from "./ErrorBox.js"
import MultiLineP from "../../MultiLineP.js"


import {AgreementDataContext} from "../../../screens/tenancyAgreements/Container.js"

function Currency({text, icon, iconSlug, sectionIndex, clauseIndex}){
  const {agreementData, setAgreementData, editable:agreementEditable} = useContext(AgreementDataContext);
  const [expanded, setExpanded] = React.useState(false);
  const [data, setData] = React.useState(agreementData.sections[sectionIndex].clauses[clauseIndex]);
  const [errorMessage, setErrorMessage] = React.useState("")
  const [value, setValue] = useState(agreementData.sections[sectionIndex].clauses[clauseIndex].value || "");
  // const [valid, setValid] = useState(false);
  const [showConditional, setShowConditional] = useState(false);
  const [conditionalText, setConditionalText] = useState();
  const hackSpan = useRef();
  const inputRef = useRef();


  // useEffect(() => {
  //   if (!data.CriteriaTexts || !value){
  //     // console.log("nah");
  //   } else {
  //       function compare(comparisonValue, operator, ) {
  //         switch (operator) {
  //           case '>':   return value > comparisonValue;
  //           case '<':   return value < comparisonValue;
  //           case '>=':  return value >= comparisonValue;
  //           case '<=':  return value <= comparisonValue;
  //           case '==':  return value == comparisonValue;
  //           case '!=':  return value != comparisonValue;
  //           case '===': return value === comparisonValue;
  //           case '!==': return value !== comparisonValue;
  //         }
  //       }
  //
  //       let anyMet = false
  //       for (var i = 0; i < data.CriteriaTexts.length; i++) {
  //         if (compare(data.CriteriaTexts[i].comparisonValue, data.CriteriaTexts[i].operator)){
  //           console.log("yeah this condition was met");
  //           anyMet = true
  //           setShowConditional(true)
  //           setConditionalText(
  //             <p className="ClauseText" onClick={() => {inputRef.current.focus()}}>
  //               {data?.CriteriaTexts[i]?.text?.pre}
  //               <span className="ClauseTextUnderlined">{data?.CriteriaTexts[i]?.text?.underlined}</span>
  //             {data?.CriteriaTexts[i]?.text?.post}
  //             </p>
  //           )
  //           break
  //         }
  //       }
  //       if (!anyMet) {
  //         setShowConditional(false)
  //         setConditionalText(null)
  //       }
  //   }
  //
  // }, [value]);


  function handleClick(event){
    if (data.description){
      setExpanded(!expanded)
      event.preventDefault()
    }
  }

  //
  if (data?.hidden === true){
    return (<></>)
  }


  // function CriteriaText(){
  //   if (!data.CriteriaTexts || !value){return null}
  //
  //   function compare(comparisonValue, operator, ) {
  //     switch (operator) {
  //       case '>':   return value > comparisonValue;
  //       case '<':   return value < comparisonValue;
  //       case '>=':  return value >= comparisonValue;
  //       case '<=':  return value <= comparisonValue;
  //       case '==':  return value == comparisonValue;
  //       case '!=':  return value != comparisonValue;
  //       case '===': return value === comparisonValue;
  //       case '!==': return value !== comparisonValue;
  //     }
  //   }
  //
  //   for (var i = 0; i < data.CriteriaTexts.length; i++) {
  //     if (compare(data.CriteriaTexts[i].comparisonValue, data.CriteriaTexts[i].operator)){
  //       console.log("yeah this condition was met");
  //       setShowConditional(true)
  //       return (
  //         <p className="ClauseText" onClick={() => {inputRef.current.focus()}}>
  //           {data?.CriteriaTexts[i]?.text?.pre}
  //           <span className="ClauseTextUnderlined">{data?.CriteriaTexts[i]?.text?.underlined}</span>
  //         {data?.CriteriaTexts[i]?.text?.post}
  //         </p>
  //       )
  //     }
  //   }
  //
  //
  // }

  //
  return (
    <div className={"Clause" + (expanded ? " expanded": "")}  onClick={handleClick} id={data.id}>
      <div className="ClauseInner">
        <div className="clauseIcon">
          <img src={"/clause-image/" + data.iconSlug}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src="/clause-image/placeholder.svg";
            }}
            className="ClauseIcon"
            alt={text}
          />
        </div>
        <div className="ClauseTextContainer">
          {conditionalText}
          {!showConditional &&
          <p className={"ClauseText" + (value ? "" :" placeholder")} onClick={() => {}}>
            {data?.text?.pre}
            <span style={{whiteSpace: "noWrap"}}>
              &nbsp;£
              <ExpandingInput
                type="number"
                min={data.min}
                max={data.max}
                minWidth={30}
                extraWidth={15}
                className={"ClauseTextInput currency" + (data?.editable==false? " not-editable" : "")}
                value={value}
                disabled={!agreementEditable}
                onChange={(v) => {
                  if (!agreementEditable) return
                  setValue(v.target.value)
                  const newAD = {...agreementData};
                  newAD.sections[sectionIndex].clauses[clauseIndex].value = v.target.value
                  if (v.target.value){
                    // Set this field as complete
                    newAD.sections[sectionIndex].clauses[clauseIndex].complete = true
                    setAgreementData(newAD)
                    // setAgreementData({...agreementData, testupdate : true})
                  } else {
                    // Set this field as not complete
                    newAD.sections[sectionIndex].clauses[clauseIndex].complete = false
                    setAgreementData(newAD)

                  }
                }}
              />
            </span>
            {data?.text?.post}
          </p>
        }
        </div>
      </div>
      {expanded &&
        <div className="ClauseExpansion">
          <MultiLineP className="ClauseDescription">{data.description}</MultiLineP>
        </div>
      }
      {errorMessage && <ErrorBox message={errorMessage}/>}
    </div>

  )

}

export default Currency;
