import React, {} from "react"
import { useState, createContext, useContext, useRef, useEffect } from "react";

function ExpandingInput({className, validate, value, onBlur, onChange, placeholder, style, minWidth, extraWidth, inputRef,  ...otherProps}){
  const [width, setWidth] = useState(10);
  const hackSpan = useRef();
  const inputRefInternal = useRef();
  const [localVal, setLocalVal] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    // console.log("is error", error);
  }, [error]);


  useEffect(() => {
    if (inputRef){
      inputRef.current = inputRefInternal.current
    }
  }, [inputRefInternal.current]);

  if (!minWidth) minWidth = 0
  // extraWidth = 10

  function calculateWidth(){
    if(!window.chrome) extraWidth = 10
    console.log("offeset width is", hackSpan.current.offsetWidth);
    const w = 10 + Math.max(hackSpan.current.offsetWidth + (extraWidth||0) , minWidth)
    if (w > 375){
      // display as text which needs to be clicked to enable the input
    }
    console.log("updating width: ",value,  w);
    setWidth(w);
  }


  useEffect(() => {
    // console.log("expanding input set");
    calculateWidth()
    // console.log("is len: ", value.length, " greater than ", (1 || minChar), " : ", value.length >= (1 || minChar));
  }, [ value, inputRefInternal.current]);

  // useEffect(() => {
  //   const w = Math.max(hackSpan.current.offsetWidth + (widthConstant||0) ,10)
  //   setWidth(w);
  //   console.log("updating width by local: ", w);
  // }, [localVal]);

  // className={className + (error? " error":"")}

  // style={{width, ...{maxWidth: "100%"}, ...style}}

  return (
    <>
      <input
        ref={inputRefInternal}
        className={className + (error? " Error":"")}
        style={{maxWidth: width, ...style}}
        value={value}
        placeholder={placeholder}
        onClick={(e)=> {
          // console.log("clicked on the input");
          e.stopPropagation();
        }}
        onChange={(e) => {
          // console.log("onchange within ExpandingInput", inputRefInternal.current);
          onChange(e)
          setLocalVal(e.target.value)
        }}
        onBlur={(e) => {
          // console.log("blur ei");
          if (onBlur) onBlur()
          try {
            const vd = validate(e.target.value)
            // console.log("vd is", vd);
            setError(!validate(e.target.value))
          } catch {}
        }}
        {...otherProps}
      />
      <span
        className={className}
        style={{
          position: "fixed",
          opacity: 0,
          whiteSpace: 'pre-wrap',
          whiteSpace: 'no-wrap',
          zIndex: -10000,
        }}
        ref={hackSpan}>
          {value || inputRefInternal.current?.value || placeholder}
        </span>
    </>
  )
}

export default ExpandingInput
