import React from "react";
import {Link, Routes, Route, useNavigate} from 'react-router-dom';


function BackButton({onClick, white, outline, blue, border}){

  var imgPath = '/back-arrow'
  // if (outline){
    imgPath += '/outline'
  // }

  if (border){
    imgPath += '-border'
  }

  if (white){
    imgPath += '/white.svg'
  } else {
    imgPath += '/blue.svg'

  }


  const navigate = useNavigate();

  return (
    <div
      onClick={() => {
        console.log("clicked back button");
        if (onClick){
          console.log("custom onclick provided");
          onClick()
        } else {
          console.log("no custom onclick so going back");
          navigate(-1)
        }
      }}
      className="BackButtonContainer"
    >
      <img src={imgPath} className="BackButton" style={{height: 48}}/>
    </div>
  )
}

export default BackButton
