import React from "react"

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useParams
} from 'react-router-dom';

function Home(){
  const { name } = useParams()
  console.log("name", name);


  return (
      <div className="FullSizeScreen" style={{}}>
        <div style={{margin: "auto"}} >
          <h1 style={{fontFamily: "Arvo", color: "white", fontSize: 38, textAlign: "center"}}>Oopsie...</h1>
          <h2 style={{fontFamily: "Arvo", color: "white", fontSize: 26, textAlign: "center"}}>That page doesn't seem to exist</h2>
          <Link to="/">View tenancies I manage</Link>
          <Link to="/">Create a new tenancy</Link>
        </div>
      </div>

  )

}

export default Home;
