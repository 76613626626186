import React from "react";
import { useState, createContext, useContext, useRef, useEffect } from "react";

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useParams,
    useNavigate,
    useLocation,
} from 'react-router-dom';

import { getFirestore, doc, getDocs, getDoc, addDoc, setDoc, updateDoc, collection, collectionGroup, query, limit, orderBy, onSnapshot, docRef, where, orderByChild } from "firebase/firestore";

import {AgreementDataContext} from "../../screens/tenancyAgreements/Container.js"
import { useAuth, } from "../../contexts/AuthContext"

import BackButton from "../BackButton.js"
import ErrorBox from "../tenancyAgreements/Clause/ErrorBox.js"

const db = getFirestore();

function SelectPlan({}){
  const {tenancy, agreementData, setAgreementData, userIsLandlordOrManager} = useContext(AgreementDataContext);
  const { tenancyId, taId, sectionSlug } = useParams()
  const {currentUser} = useAuth()
  const navigate = useNavigate();
  const location = useLocation()
  const agreeRef = useRef()
  const [error, setError] = useState();

  useEffect(() => {
    document.title = "Select 10NC plan"
  }, []);

  useEffect(() => {
    if(!userIsLandlordOrManager(currentUser)){
      navigate("/tenancies/" + tenancyId + "/agreements/" + taId + "/" +  "status" +  location.search)
    } else if (tenancy.managementPlan?.active == true)(
      navigate("/tenancies/" + tenancyId + "/agreements/" + taId + "/" +  "status" +  location.search)
    )
  }, [currentUser, tenancy.managementPlan]);

  return (
    <div
      className="AgreementFinalisePage"
    >
      <div className="SectionHeaderAll" >
        <div style={{float: 'left', position: 'absolute'}} >
          <BackButton onClick={() => {
            // navigate(-1)
            return
            try {
                navigate("/tenancies/" + tenancyId + "/agreements/" + taId + "/" + agreementData.sections[agreementData.sections.length-1].slug + location.search)
                // navigate(location.pathname.split('/').slice(0,-1).join('/') + '/' + agreementData.sections[agreementData.sections.length-1].slug + location.search)
                window.scrollTo(0, 0)
            } catch (e) {
              console.log("defaulting back in browser");
              navigate(-1)
            }
          }}/>
        </div>
      </div>
      <h1 className="AgreementMakerH1" style={{marginTop: 0, marginBottom: 10}}>Nice work!</h1>
      <h2 className="AgreementMakerH2" style={{marginTop: 0, marginBottom: 20}}>Your tenancy agreement is ready</h2>
      <div className="PlanBox" style={{marginTop: 30}}>
        <h1 style={{marginBottom: 0}}><span style={{textDecoration: "line-through", opacity: 0.4}}>£20</span> £5<span style={{fontSize:15}}>/month</span></h1>
        <p style={{fontStyle: "italic", marginTop: 0, opacity: 0.6}}>(early-bird offer)</p>
        <h3 style={{margin: 0, textAlign: "center"}}>Pay nothing until let</h3>
        <ul className="DashedList">
          <li>Save £200 vs agent/lawyer</li>
          <li>1 click tenant sign-ups</li>
          <li>Stay compliant</li>
          <li>Interactive plain-english tenancy agreements</li>
          <li>Reminders for safety checks, licences & more - <em>coming soon</em></li>
          <li>Maintenance reporting and dashboard - <em>coming soon</em></li>
          <li>Avoid disputes & confusion without the legalese</li>
          <li>No fees if tenants don't sign</li>
          <li>Cancel at any time</li>
        </ul>
        <div className="" style={{marginTop: 0, paddingTop: 15, paddingBottom: 15}} >
          {/* <input type="checkbox" name="agreeTerms" ref={agreeRef} style={{alignSelf: "flex-start"}}/>
          <label for="agreeTerms" onClick={() => {agreeRef.current.checked = !agreeRef.current.checked}}> I agree to the <a href="https://www.10nc.app/policies/10nc-manager-service-agreement" style={{color: "#7EBBC6", textDecoration: "none"}}>terms of service</a></label> */}
          <p style={{fontSize: 14, margin: 0}}> By clicking below you are agreeing to the <a href="https://www.10nc.app/policies/10nc-manager-service-agreement" style={{color: "#7EBBC6", textDecoration: "none"}}>terms of service</a></p>
        </div>
        <button
          className="RoundButton Yellow"
          style={{ color: "white", fontSize: 18, fontWeight: "bold", paddingLeft: 40, paddingRight: 40, marginTop: 0}}
          onClick={async () => {
            // if(agreeRef.current.checked ){
            if(true ){
              // console.log(agreeRef.current.checked);
              window.analytics.track("tenancy_agreement.plan.selected", { tenancyID:  tenancyId, plan: "simple", value: 5,});
              await updateDoc(doc(db, "tenancies", tenancyId), {
                "activeManagementPlan" : true,
                "managementPlan" : {
                  "creator" : currentUser.uid,
                  "createdAt" : new Date(),
                  "value" : 5,
                  "active" : true,
                }
              })
              .then(snap => {
                console.log(snap);
                // navigate()
              })
              .catch(error => {
                window.analytics.track("tenancy_agreement.plan.error", {});
                setError({"type": "error", message: "We were not able to confirm your plan"})
              })

            } else {
              setError({"type": "error", message: "Please agree to the terms of service"})
            }
          }}
        >
            Select plan
          </button>
      </div>
      {error && <ErrorBox {...error}/>}
    </div>
  )
}

export default SelectPlan
