import React, {useState, useEffect, useRef, forwardRef} from "react";

import Modal from "../../components/Modal.js"
import BlurBehind from "../../components/BlurBehind.js"
import MultiLineP from "../../components/MultiLineP.js"
import Markdown from "../../components/Markdown.js"

function ComplianceModal(props, ref){
  const {title, index, content, buttonLink, buttonText, tenancyId, itemText} = props;


  return (
    <Modal ref={ref} key={"modal-for-" + index} id="mytestmodal1" showClose dismissAnywhere>
      <div className="Flex P20 Column W100" >
        {/*
          <BlurBehind blur={true} radius={3}>
            <div className="InlineBlock Column AlignCenter P10 M15 BgBlue BR8" style={{border: "1px solid grey"}}>
              <h2>Start your plan</h2>
              <p>You'll need a plan to read this</p>
            </div>
          </BlurBehind>
        */}
        <h1>{title}</h1>
        <Markdown className="ComplianceModalMarkdown">{content}</Markdown>
      {buttonLink && <a style={{alignSelf: "center"}} href={buttonLink} onClick={() => { window.analytics.track("tenancy_overview.compliance.modal_cta_clicked", { tenancyID:  tenancyId, complianceItem: itemText, href: buttonLink, buttonText: buttonText})}} target="_blank"><button className="Yellow BR10" >{buttonText || "Learn More"}</button></a>}
      </div>
    </Modal>
  )
}

export default forwardRef(ComplianceModal)
