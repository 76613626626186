import React from "react"
import axios from "axios"

import MultiLineP from "../../MultiLineP.js"

function ClauseOption({title, isDefault, contextData, infoText, onSelect}){
  // const [expanded, setExpanded] = React.useState(false);

  function handleClick(event){
    event.stopPropagation()
    onSelect()
  }

  return (
    <div className="ClauseOption" onClick={handleClick}>
      <div className="ClauseOptionInner">
        <div className="ClauseOptionLine1">
          <p className="ClauseOptionTitle">{title}</p>
          {isDefault && <p className="ClauseOptionDefault">(default)</p>}
          {contextData && <p className="ClauseOptionContextData">- {contextData}</p>}
        </div>
        <div className="ClauseOptionLine2">
          {infoText && <MultiLineP className="ClauseOptionInfoText">{infoText}</MultiLineP>}
        </div>
      </div>
      <div className="ClauseOptionDivider"></div>
    </div>


  )

}

export default ClauseOption;
