import React from "react";
import { useState, createContext, useContext } from "react";
import {sendEmailLink} from "../firebase.js"


function Preferences(){
  const [email, setEmail] = useState(window.localStorage.getItem('emailForSignIn', 'email') ?? '');
  const [link, setLink] = useState('');
  const [loginError, setLoginError] = useState('');
  const [awaitingLink, setAwaitingLink] = useState( false);

  return (
    <div className="LoginPage" >
      <div className="loginContainer" >
        <div className="LoginLogo" >
          <img src="/10NC-logo/blue.png" style={{height: 40, marginBottom: 40}}/>
        </div>
        <div className="LoginTitleContainer">
          <h1 className="LoginTitle">Preferences</h1>
        </div>
      </div>
    </div>
  )
}

export default Preferences
