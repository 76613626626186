import React, {useRef, useState, useEffect} from "react";
// import { useOutletContext } from "react-router-dom";

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useParams,
    useNavigate,
    useLocation,
    Outlet,
    useOutletContext,
} from 'react-router-dom';

import ContinueButton from "../../components/ContinueButton.js"
import AddressInput from "../../components/inputs/AddressInput.js"
import AddressDropdown from "../../components/inputs/AddressDropdown.js"
import ErrorBox from "../../components/tenancyAgreements/Clause/ErrorBox.js"

import { useAuth, } from "../../contexts/AuthContext"

export default function Email({}){
  const emailRef = useRef()
  const navigate = useNavigate();
  const {currentUser} = useAuth()
  const {email, setEmail, newPropertyAddress, setNewPropertyAddress, newPropertyData, setnewPropertyData, tenancyRef} = useOutletContext();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [awaitingResponse, setAwaitingResponse] = useState(false);
  const { id} = useParams()

  useEffect(() => {
    console.log("tref", tenancyRef);
    console.log("cuser", currentUser);
  }, []);


  // useEffect(() => {
  //   if (!newPropertyData){
  //     console.log("no property data, navigating");
  //     navigate("../")
  //   } else if (!email){
  //     console.log("no email, navigating");
  //     navigate("../email")
  //   }
  //   console.log("email", email);
  //   console.log("newPropertyData", newPropertyData);
  // }, []);

  async function makeAgreement(){
    const reqData = {
      templateId: "p81Ed08bHoRS0HS5H61J",
      tenancyId: tenancyRef?.id || id,
    }
    console.log("Creating TA with data", reqData);
    const token = await currentUser.getIdToken()

    fetch('https://europe-west2-nc-e9104.cloudfunctions.net/create-ta-from-template-1', {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(reqData)
    })
      .then(response => response.json())
      .then(data => {
        console.log("got response", data);
        navigate("/tenancies/" + data.tenancy_id + "/agreements/" + data.tenancy_agreement_id )
        setAwaitingResponse(false)
      })
      .catch(error => {
        console.log("error:", error);
        setError({type:"warn", message: "Oops, we're fixing this as we speak. Check back in a few hours."})
        setAwaitingResponse(false)
      })
  }



  return (
      <div name="main" style={styles.mainContent} >
        <div name="top" style={styles.topSection}>
          <img src="/join-tenancy-image.svg" style={{width: 300, height: 300}}/>
          <div style={styles.topText}>
            <p style={styles.headerp}>{newPropertyData?newPropertyData[0]: id || tenancyRef?.id}</p>
            <p style={styles.subHeaderp}>Congrats, your tenancy has been created!</p>
          </div>
        </div>
        <div name="mid" style={styles.midSection}>

          <div style={{width: "100%", display: "flex", alignItems: "center", flexDirection: "column", marginBottom: 50}} className="" >
            {false && <p style={styles.formLabel2}>What is your email?</p>}
            <p style={styles.mainText}>Now you should create an agreement to send to your tenants</p>
          </div>
        </div>
        <div name="top" style={styles.bottomSection}>
          <button
            className="ContinueButton solid white"
            disabled={awaitingResponse}
            onClick={() => {
              makeAgreement()
            }}
          >
            Create a tenancy agreement
          </button>
          {error && <ErrorBox {...error}/>}
        </div>
      </div>
  )
}

const styles = {
  continueButton: {
    margin: 0,
    marginTop: 25,
    width: "100%",
    boxSizing: "border-box",
  },
  formLabel: {
    fontFamily: "lato",
    fontSize: 24,
    color: "white",
    fontWeight: 200,
  },
  formLabel2: {
    fontFamily: "arvo",
    fontSize: 18,
    color: "white",
    fontWeight: 200,
  },
  formLabelMini: {
    fontFamily: "lato",
    fontSize: 18,
    color: "white",
    fontWeight: 500,
    textAlign: "left",
    alignSelf: "flex-start",
  },
  topSection: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: 'column',
    alignItems: "center",
    width: "100%",
    // paddingTop: 30,
    boxSizing: "border-box",
    // marginTop: "5vh",
    // minHeight: 100,
    // maxHeight: 100,
    // border: "2px solid pink",
  },
  midSection: {
    display: "flex",
    justifyContent: "center",
    flexDirection: 'column',
    alignItems: "center",
    width: "100%",
    flex: 1,
    // minHeight: 100,
    // maxHeight: 100,
    // height: 800,
    // border: "2px solid blue",
  },
  midSectionFromBottom: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: 'column',
    alignItems: "stretch",
    width: "100%",
    flex: 1,
    marginBottom: 40,
    // minHeight: 100,
    // maxHeight: 100,
    // height: 800,
    // border: "2px solid blue",
  },
  main : {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  bottomSection: {
    display: "flex",
    justifyContent: "flexStart",
    flexDirection: 'column',
    alignItems: "center",
    width: "100%",
    // minHeight: 100,
    // maxHeight: 100,
    // height: 200,
    marginBottom: 50,
    boxSizing: "border-box",
    maxWidth: 620,
    // border: "2px solid green",
  },
  textBox : {
    backgroundColor: "white",
    border: 0,
    minHeight: 65,
    width: "100%",
    maxWidth: 400,
    borderRadius: 10,
    justifyContent: "center",
    // padding: 12,
    fontFamily: "HelveticaNeue",
    fontSize: 18,
    color: "#48525D",
    paddingLeft: 12,
    paddingRight: 12,
    marginBottom: 10,
    boxSizing: "border-box",
  },
  mainContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    // width: "100vw",
    // minHeight: "100vh",
    minHeight: "100%",
    // border: "2px solid cyan",
    // padding: 30,
    // marginLeft: 30,
    // marginRight: 30,
    alignItems:"center",
    justifyContent: "space-between",
    boxSizing: "border-box",
  },
  mainText: {
    margin: 10,
    color: "white",
  },
  topText: {
    // flex: 1,
    justifyContent: "center",
    textAlign: "center",
  },
  headerp: {
    fontFamily: 'Bebas Neue',
    fontFamily: 'Arvo',
    fontWeight: "bold",
    color: "white",
    fontSize: 36,
    margin: 0,
    marginBottom: 11,
    pAlign: "center",
  },
  subHeaderp: {
    fontFamily: "Arvo",
    // fontWeight: "bold",
    color: "white",
    fontSize: 26,
    pAlign: "center",
    paddingHorizontal: 20,
    marginBottom: 15,
    marginTop: 15,
  }
}
