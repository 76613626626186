import React from "react";
import { useState, createContext, useContext } from "react";
import {
  sendEmailLink,
} from "../firebase.js"
import {Link, useNavigate} from 'react-router-dom'

// import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { useAuth, } from "../contexts/AuthContext"

import ErrorBox from "../components/tenancyAgreements/Clause/ErrorBox.js"

// const auth = getAuth()



function Register(){
  const [email, setEmail] = useState(window.localStorage.getItem('emailForSignIn', 'email') ?? '');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [link, setLink] = useState('');
  const [loginError, setLoginError] = useState('');
  const [awaitingLink, setAwaitingLink] = useState( false);
  const {register, login} = useAuth()
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()


  const handleRegister = (event) => {
    setLoading(true)
    event.preventDefault()
    if (!password || !email) {
      setLoginError("Must provide a valid email and password")
      setLoading(false)
      return null
    }

    if (password != passwordConfirm) {
      setLoginError("Passwords must match")
      setLoading(false)
      return null
    }

    register( email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        console.log("user is ", user);
        // ...
        setLoading(false)
        navigate("/")
      })
      .catch((error) => {
        const errorCode = error.code;
        console.log(errorCode);
        if (errorCode === "auth/email-already-in-use"){
          login( email, password)
            .then((userCredential) => {
              // Signed in
              const user = userCredential.user;
              console.log("user logged in as", user);
              setLoading(false)
              navigate("/")
              // ...
            })
            .catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
              setLoginError(errorMessage)
              setLoading(false)
            });
        } else {
          const errorMessage = error.message;
          setLoginError(errorMessage)
          setLoading(false)
        }
        // ..
      });
  }

  return (
    <div className="LoginPage" >
      <form className="loginContainer" >
        <div className="LoginLogo" >
          <img src="/10NC-logo/manager.png" style={{height: 80, marginBottom: 40}}/>
        </div>
        <div className="LoginTitleContainer" >
          <h1 className="LoginTitle" style={{display: 'none'}}>Register</h1>
        </div>
        <div className="LoginForm" >
          <input className="LoginInput" type="email" placeholder="Email" value={email} onChange={(event) => {setEmail(event.target.value)}}/>
          <input className="LoginInput" type="password" placeholder="Password" onChange={(event) => {setPassword(event.target.value)}}/>
          <input className="LoginInput" type="password" placeholder="Confirm Password" onChange={(event) => {setPasswordConfirm(event.target.value)}}/>
        {loginError && <ErrorBox message={loginError}/>}
          <button disabled={loading} type="submit" className="Button BlueOutline" style={{marginTop: 10}} onClick={handleRegister}>
            REGISTER
        </button>
        </div>
      </form>
      <div className="w-100 text-center mt-2" >
        <Link to="/login" >Already have an account?</Link>
      </div>
    </div>
  )
}

export default Register
