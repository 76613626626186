import React from "react";

import NavBar from "./NavBar.js"
import NavBar2 from "./NavBar2.js"
import Footer from "./Footer.js"

import {Route,  Navigate, useNavigate } from 'react-router-dom'
import {useAuth} from '../contexts/AuthContext'

function Page({hideBack, publicRoute, noPadding, children, style, header, center, footer, showBreadcrumbs, onBackClick}){
  const {currentUser} = useAuth()
  const navigate = useNavigate()

  // console.log('onbc in page is', onBackClick);

  if (!publicRoute && !currentUser) {
    console.log("this page is not a public route and we don't have a current user");
    // navigate("/login" + "?redirect=" + encodeURIComponent(window.location.pathname), { replace: true })
    return (<Navigate to={"/login" + "?redirect=" + encodeURIComponent(window.location.pathname)} replace/>)
    return
  }

  const extraStyles = {}
  if(center) extraStyles.alignItems = 'center'
  if(center===false) extraStyles.alignItems = 'flex-start'

  // extraStyles.alignItems = center


  return (
    <div
      className="Page2"
    >
      {!(header === false) && <NavBar2 onBackClick={onBackClick} hideBack={!!hideBack} showBreadcrumbs={showBreadcrumbs} publicLogo={currentUser && !currentUser.isManager}/>}
      <div className={"PageMain2" + (noPadding?" noPadding":"")} style={{...style, ...extraStyles}}>
        {children}
      </div>
      <Footer/>
    </div>
  )
}

export default Page
