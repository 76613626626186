import React from "react"
import axios from "axios"
// import ClauseOption from "./ClauseOption"
import { useState, createContext, useContext, useRef, useEffect } from "react";

import ExpandingInput from "../../ExpandingInput.js"
import ErrorBox from "./ErrorBox.js"
import MultiLineP from "../../MultiLineP.js"
import ClauseMainText from "../../tenancyAgreements/Clause/ClauseMainText.js"

import {AgreementDataContext} from "../../../screens/tenancyAgreements/Container.js"

function Number({text, icon, iconSlug, sectionIndex, clauseIndex}){
  const {agreementData, setAgreementData, editable:agreementEditable} = useContext(AgreementDataContext);
  const [expanded, setExpanded] = React.useState(false);
  const [data, setData] = React.useState(agreementData.sections[sectionIndex].clauses[clauseIndex]);
  const [error, setError] = React.useState();
  const [value, setValue] = useState(agreementData.sections[sectionIndex].clauses[clauseIndex].value || "");
  // const [valid, setValid] = useState(false);
  const [showConditional, setShowConditional] = useState(false);
  const [conditionalText, setConditionalText] = useState();
  const [valid, setValid] = useState();
  const hackSpan = useRef();
  const inputRef = useRef();
  const [validationTimeout, setValidationTimeout] = useState();

  // useEffect(() => {
  //   const adV = agreementData.sections[sectionIndex].clauses[clauseIndex].value
  //   if (adV === undefined || adV === null){
  //     setValue("")
  //   } else {
  //     setValue(adV)
  //   }
  // }, []);

  useEffect(() => {
    let runValid = true
    if(value){
      if(data.min && value < data.min) runValid = false
      if(data.max && value > data.max) runValid = false
    } else {
      runValid = false
    }
    setValid(runValid)

    const newAD = {...agreementData};
    newAD.sections[sectionIndex].clauses[clauseIndex].value = value
    if (value){
      // Set this field as complete
      newAD.sections[sectionIndex].clauses[clauseIndex].complete = true
    } else {
      // Set this field as not complete
      newAD.sections[sectionIndex].clauses[clauseIndex].complete = false
    }
    setAgreementData(newAD)
  }, [value]);


  // useEffect(() => {
  //   if (!data.CriteriaTexts || !value){
  //     // console.log("nah");
  //   } else {
  //       function compare(comparisonValue, operator, ) {
  //         switch (operator) {
  //           case '>':   return value > comparisonValue;
  //           case '<':   return value < comparisonValue;
  //           case '>=':  return value >= comparisonValue;
  //           case '<=':  return value <= comparisonValue;
  //           case '==':  return value == comparisonValue;
  //           case '!=':  return value != comparisonValue;
  //           case '===': return value === comparisonValue;
  //           case '!==': return value !== comparisonValue;
  //         }
  //       }
  //
  //       let anyMet = false
  //       for (var i = 0; i < data.CriteriaTexts.length; i++) {
  //         if (compare(data.CriteriaTexts[i].comparisonValue, data.CriteriaTexts[i].operator)){
  //           console.log("yeah this condition was met");
  //           anyMet = true
  //           setShowConditional(true)
  //           setConditionalText(
  //             <p className="ClauseText" onClick={() => {inputRef.current.focus()}}>
  //               {data?.CriteriaTexts[i]?.text?.pre}
  //               <span className="ClauseTextUnderlined">{data?.CriteriaTexts[i]?.text?.underlined}</span>
  //             {data?.CriteriaTexts[i]?.text?.post}
  //             </p>
  //           )
  //           break
  //         }
  //       }
  //       if (!anyMet) {
  //         setShowConditional(false)
  //         setConditionalText(null)
  //       }
  //   }
  //
  // }, [value]);


  function handleClick(event){
    if (data.description){
      setExpanded(!expanded)
      event.preventDefault()
    }
  }

  //
  if (data?.hidden === true){
    return (<></>)
  }


  return (
    <div className={"Clause" + (expanded ? " expanded": "")}  onClick={handleClick} id={data.id}>
      <div className="ClauseInner">
        <div className="clauseIcon">
          <img src={"/clause-image/" + data.iconSlug}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src="/clause-image/placeholder.svg";
            }}
            className="ClauseIcon"
            alt={text}
          />
        </div>
        <div className="ClauseTextContainer">
          {conditionalText}
          {!showConditional &&
          <p className={"ClauseText" + (value && valid ? "" :" placeholder")} onClick={() => {}}>
            {data?.text?.pre}
            <span >
              <ExpandingInput
                type="number"
                min={data?.min}
                max={data?.max}
                extraWidth={20}
                inputRef={inputRef}
                className={"ClauseTextInput number center" + (data?.editable==false? " not-editable" : "")}
                value={value}
                disabled={!agreementEditable}
                onClick={(e) => {e.stopPropagation()}}
                onChange={(e) => {
                  console.log("onchange inside Number");
                  if (!agreementEditable) return
                  // If the value is between any limits (if they are set)
                  clearTimeout(validationTimeout)
                  const v = e.target.value
                  console.log("the e from input is", e);
                  console.log("the v from input is", v);
                  setValue(v)
                  if((!data.min || data.min<= v) && (!data.max || data.max >= v)){
                    console.log(v, "falls betwen the min", data.min, "and max", data.max);
                  } else {
                    console.log(v, "not falls betwen the min", data.min, "and max", data.max);
                    const vt = setTimeout((v) => {
                      console.log("timeout val v", v);
                      var val = v
                      if( (v) && data.min && data.min > v){
                        console.log(v + " is less than the min");
                        val = data.min
                        setError({"message": "The value must be greater than " + data.min + " and " + "less than " + data.max, "type": "warn"})
                      } else if( v && data.max && data.max < v){
                        console.log(v + " is greater than the min");
                        val = data.max
                        setError({"message": "The value must be between " + data.min + " and " + data.max, "type": "warn"})
                      } else {
                        setError()
                      }
                      setValue(val)

                    }, 1000, e.target.value)
                    console.log("setting timeout");
                    setValidationTimeout(vt)
                  }

                  // setValue(v.target.value)
                  // const newAD = {...agreementData};
                  // newAD.sections[sectionIndex].clauses[clauseIndex].value = v.target.value
                  // if (v.target.value){
                  //   // Set this field as complete
                  //   newAD.sections[sectionIndex].clauses[clauseIndex].complete = true
                  //   setAgreementData(newAD)
                  //   // setAgreementData({...agreementData, testupdate : true})
                  // } else {
                  //   // Set this field as not complete
                  //   newAD.sections[sectionIndex].clauses[clauseIndex].complete = false
                  //   setAgreementData(newAD)
                  //
                  // }
                }}
              />
            {data?.units}
            </span>
            {data?.text?.post}
            {/* }<span id="ClauseTextInputHiddenHack" ref={hackSpan}>{value}</span>*/}
          </p>
        }
        </div>
      </div>
      {expanded &&
        <div className="ClauseExpansion">
          <MultiLineP className="ClauseDescription">{data.description}</MultiLineP>
        </div>
      }
      {error && <ErrorBox {...error}/>}
    </div>

  )

}

export default Number;
