import React from "react";

export default function AccessDenied({}){

  return (
    <div
      className="AccessDeniedBlock"
    >
    <h1>Oops...</h1>
    <p>You do not have permission to access this page</p>
    </div>
  )
}
