import React from "react";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

export default function Phone({value, onChange, ...otherProps}){

  return (
    <PhoneInput
      country={'gb'}
      preferredCountries={['gb', 'in', 'fr', 'pl', 'pk', 'ie', 'DE', 'BD', 'za', 'ng', 'us', 'cn', 'jm', 'it', 'au']}
      // onlyCountries={}
      value={value}
      onChange={onChange}
      // containerStyle={styles.textBox}
      inputStyle={styles.textBox}
      buttonStyle={styles.button}
      dropdownStyle={styles.dropDown}
      placeholder="+44 077989 98998"
      autoFormat={true}
      countryCodeEditable={false}
      alwaysDefaultMask={false}
      enableLongNumbers={false}
      isValid={(value, country, countries, hiddenAreaCodes) => {
        return false
      }}
      {...otherProps}
      // buttonStyle={}
    />
  )
}


const styles = {
  textBox : {
    backgroundColor: "white",
    border: 0,
    minHeight: 65,
    width: "100%",
    maxWidth: 400,
    borderRadius: 10,
    justifyContent: "center",
    // padding: 12,
    fontFamily: "HelveticaNeue",
    fontSize: 18,
    color: "#48525D",
    // paddingLeft: 12,
    paddingRight: 12,
    marginBottom: 10,
    boxSizing: "border-box",
    overflow: "hidden",
  },
  button : {
    backgroundColor: "white",
    // border: "1px solid grey",
    border: 0,
    // minHeight: 65,
    // width: "100%",
    // maxWidth: 400,
    borderRadius: 10,
    // justifyContent: "center",
    // padding: 12,
    // fontFamily: "HelveticaNeue",
    // fontSize: 18,
    // color: "#48525D",
    // // paddingLeft: 12,
    // paddingRight: 12,
    // marginBottom: 10,
    // boxSizing: "border-box",
    // overflow: "hidden",
  },
  dropdownStyle: {
    borderRadius: 10,
  }
}
