import React from "react"
import axios from "axios"
// import { useState, createContext, useContext } from "react";


  // function handleClick(event){
  //   setExpanded(!expanded)
  //   event.preventDefault()
  // }

  function ErrorBox({message, type, show}){
    var icon = "/error.svg"
    var colour = ""
    if (type == "warn"){
      icon = "/warn.svg"
    } else {

    }
    return (
      <div className={"ClauseErrorBox " + type}>
        <img src={icon} className="ClauseErrorIcon"/>
        <p className="ClauseErrorText">{message}</p>
      </div>
    )
  }

export default ErrorBox;
