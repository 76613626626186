import React from "react"
import axios from "axios"
import ClauseOption from "./ClauseOption"
import { useState, createContext, useContext } from "react";

import {AgreementDataContext} from "../../../screens/tenancyAgreements/Container.js"
import ErrorBox from "./ErrorBox.js"
import MultiLineP from "../../MultiLineP.js"
import ClauseIcon from "./ClauseIcon.js"


function Clause({text, icon, iconSlug,sectionIndex, clauseIndex}){
  const {agreementData, setAgreementData, editable:agreementEditable} = useContext(AgreementDataContext);
  const [expanded, setExpanded] = React.useState(false);
  const [data, setData] = React.useState(agreementData.sections[sectionIndex].clauses[clauseIndex]);
  const [value, setValue] = React.useState(agreementData.sections[sectionIndex].clauses[clauseIndex].value || 0);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [error, setError] = React.useState();
  const [editable, setEditable] = useState([data.editable, agreementEditable].every(v => v != false));

  function getOption(id){
    for (var i = 0; i < data.options.length; i++){
      if (data.options[i].id == id){
        return (data.options[i])
      }
    }
  }


  React.useEffect(() => {
    const selectedOptionId = agreementData.sections[sectionIndex].clauses[clauseIndex]?.value
    if (selectedOptionId){
      // console.log("Select clause: we have a selected option for ", sectionIndex, ":", clauseIndex, " -> ", selectedOptionId)
      setValue(selectedOptionId)
    } else {
      for (var i = 0; i < data.options.length; i++){
        if (data.options[i].default === true){
          // console.log("Select clause: we have a default");
          if (data.options[i].id != agreementData.sections[sectionIndex].clauses[clauseIndex]?.value){
            setAgreementData(() => {
              const newAD = {...agreementData};
              newAD.sections[sectionIndex].clauses[clauseIndex].value = data.options[i].id
              return (newAD)
            })
          }
          // setSelectedOption(data.options[i].id)
          // setValue(data.options[i].id)
          break
        }
      }
    }
  },[data]);

  function handleClick(event){
    if (!editable) return
    setExpanded(!expanded)
    event.preventDefault()
  }


  function ClauseOptions({editable}){
    const optionComponents = []
    for (var i = 0; i < data.options.length; i++){
      const o = data.options[i]
      optionComponents.push(
        <ClauseOption
          key={o.id}
          clauseId={data.id}
          title={o.title}
          isDefault={o.default}
          contextData={o.contextData}
          infoText={o.info}
          editable={true}
          onSelect={() => {
            if(editable === false){
              return
            }
            // setSelectedOption(o.id)
            setValue(o.id)
            // To DO: Do something to update the context
            const newAD = {...agreementData};
            // newAD.sections[sectionIndex].clauses[clauseIndex].selectedOption = o.id
            newAD.sections[sectionIndex].clauses[clauseIndex].value = o.id
            setAgreementData(newAD)
            // console.log("updating clause ",  clauseIndex, " in section ", sectionIndex);
            // console.log("new data", newAD);
            setExpanded(false)
          }}
        />
      )
    }
    return (
      <div className="ClauseOptions">
        {optionComponents}
      </div>
    )
  }

  if (data?.hidden === true){
    return (<></>)
  }

  function ClauseText(){

    if (value){
      return (
        <p className="ClauseText">
          {getOption(value)?.text?.pre}
          <span className={"ClauseTextUnderlined" + (!editable? " disabled" : "")}>{getOption(value)?.text?.underlined }</span>
          {getOption(value)?.text?.post}
        </p>
      )
    } else {
      return (
        <p className="ClauseText placeholder">
          {data?.placeholder?.pre}
          <span className={"ClauseTextUnderlined placeholder" + (!editable? " disabled" : "")}>{data?.placeholder?.underlined || " "}</span>
          {data?.placeholder?.post}
        </p>
      )
    }

  }

  function ClauseError(message){
    return (
      <div className="ClauseErrorBox">
        <img src="/error.svg" className="ClauseErrorIcon"/>
        <p className="ClauseErrorText">{message}</p>
      </div>
    )
  }


  return (
    <div className={"Clause" + (expanded ? " expanded": "")} onClick={handleClick} id={data.id}>
      <div className="ClauseInner">
        <ClauseIcon slug={data.iconSlug} alt={text}/>
        <div className="ClauseTextContainer">
          <ClauseText/>
        </div>
      </div>
      {expanded &&
        <div className="ClauseExpansion">
          <ClauseOptions editable={data.editable}/>
          {data.description && <MultiLineP className="ClauseDescription">{data.description}</MultiLineP>}

          {false && data.editable === false &&
            <p className="SelectNotEditableWarning" > Note: This has been set automatically based on the other information provided about the tenancy. It cannot be edited.
            </p>
          }

        </div>
      }
      {error && <ErrorBox {...error}/>}
    </div>

  )

}

export default Clause;
