import logo from './logo.svg';
import './App.css';
import { useState, createContext, useContext, useEffect } from "react";
// import {auth} from './firebase.js'

// import 'bootstrap/dist/css/bootstrap.min.css';

import { getFirestore, doc, getDocs, getDoc, addDoc, collection, query, limit, orderBy, onSnapshot, docRef, where, orderByChild } from "firebase/firestore";

import { BrowserRouter, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import PrivateRoute from "./components/PrivateRoute"


import "./analytics/segment.js"

import TenancyHome from "./screens/TenancyHome.js"
import TAContainer from "./screens/tenancyAgreements/Container.js"
  import Section from "./screens/tenancyAgreements/Section.js"
  import FullAgreement from "./components/tenancyAgreements/FullAgreement.js"
  import ImportantClauses from "./components/tenancyAgreements/ImportantClauses.js"
  import SectionTest from "./components/tenancyAgreements/SectionTest.js"
  import SelectPlan from "./components/tenancyAgreements/SelectPlan.js"
  import ShareTA from "./components/tenancyAgreements/Share.js"
  import SignTA from "./components/tenancyAgreements/Sign.js"
  import ViewCompleteTA from "./components/tenancyAgreements/ViewComplete.js"
  import NoTA from "./components/tenancyAgreements/NoTA.js"
  import TAHome from "./screens/tenancyAgreements/Home.js"
  import AgreementLinkRouter from "./screens/tenancyAgreements/LinkRouter.js"
  import Join from "./screens/tenancyAgreements/Join.js"
import JoinTContainer from "./screens/joinTenancy/Container.js"
  import JoinTLanding from "./components/joinTenancy/Landing.js"
  import JoinTEmail from "./components/joinTenancy/Email.js"
import NewTContainer from "./screens/newTenancy/Container.js"
  import NewTLanding from "./components/newTenancy/Landing.js"
  import NewTEmail from "./components/newTenancy/Email.js"
  import NewTYou from "./components/newTenancy/You.js"
  import NewTPassword from "./components/newTenancy/Password.js"
  import NewTLogin from "./components/newTenancy/Login.js"
  import NewTSuccess from "./components/newTenancy/Success.js"
import Test from "./screens/Test.js"
import Four0Four from "./screens/Four0Four.js"
import Login from "./screens/Login.js"
import Register from "./screens/Register.js"
import Preferences from "./screens/Preferences.js"
import Account from "./screens/Account.js"
import FormTest from "./screens/FormTest.js"
import Properties from "./screens/Properties.js"
import Tenancies from "./screens/Tenancies.js"

import {AuthProvider} from "./contexts/AuthContext.js"

// export const AgreementDataContext = createContext();
// export const UserContext = createContext();





const db = getFirestore();
const analytics = window.analytics

function App() {
  const [token, setToken] = useState();
  const location = useLocation()
  // const [agreementData, setAgreementData] = useState()

  // useEffect(() => {
  //   console.log("agreement data changed", agreementData);
  // }, [agreementData]);

  useEffect(() => {
    //Set your APP_ID
    var APP_ID = "sv2y4zk2";

    window.intercomSettings = {
        app_id: APP_ID
      };

    // document.body.appendChild(script);

    (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
  }, []);


  useEffect(() => {
    console.log("location change", location.pathname);
    window.analytics.page();
  }, [location]);


  return (
      <AuthProvider>
          <div className="wrapper">
            <Routes>
              <Route exact path="/" element={<Navigate to="/tenancies" replace={true} />} />
              <Route path="/tenancies" element={<Tenancies/>} />
              <Route path="tenancies/:tenancyId/join" element={<JoinTContainer/>} >
                <Route index element={<JoinTLanding/>} />
                <Route path="email" element={<JoinTEmail/>} />
                <Route path="#test" element={<p>test</p>} />
              </Route>
              <Route path="tenancies/:tenancyId" element={<TenancyHome/>}/>
              <Route path="tenancies/:tenancyId/agreements/:taId" element={<TAContainer/>}>
                <Route index element={<TAHome/>} />
                <Route path="s/:sectionSlug" element={<Section />} />
                <Route path="full" element={<FullAgreement />} />
                <Route path="important" element={<ImportantClauses />} />
                {/* <Route path="edit/confirm" element={<ConfirmAgreementCreation />} /> */}
                <Route path="summary" element={<></>} />
                <Route path="select-plan" element={<SelectPlan/>} />
                <Route path="share" element={<ShareTA/>} />
                <Route path="status" element={<ShareTA/>} />
                <Route path="sign" element={<SignTA/>} />
                <Route path="view" element={<ViewCompleteTA/>} />
                <Route path="*" element={<TAHome/>} />
              </Route>
              <Route path="tenancy-agreement/:agreementId" element={<AgreementLinkRouter/>} />
              <Route path="new-tenancy" element={<NewTContainer/>}>
                <Route index element={<NewTLanding/>} />
                <Route path="email" element={<NewTEmail/>} />
                <Route path="you" element={<NewTYou/>} />
                <Route path="password" element={<NewTPassword/>} />
                <Route path="login" element={<NewTLogin/>} />
                <Route path="success/:id" element={<NewTSuccess/>} />
              </Route>
              <Route path="/test/:name" element={<Test/>} />
              <Route path="/login" element={<Login/>} />
              <Route path="/register" element={<Register/>} />
              <Route path="/properties" element={<Properties/>} />
              <Route path="/account" element={<Account/>} />

            <Route path="*" element={<Four0Four/>} />
            </Routes>
          </div>
      </AuthProvider>
  )

}

export default App;
