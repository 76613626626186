import React from "react";
import { useState, createContext, useContext, useEffect, useRef } from "react";

import Item from "./MultiUser/Item.js"
import {useOutsideAlerter} from "../../utility/events.js"
import {useFirstRender} from "../../utility/firstRender.js"

export default function MultiUser({value, numbered, lettered, onChange, itemIconSlug, editable}){
  const itemRefs = useRef([])
  const mainRef = useRef()
  const [valueInternal, setValueInternal] = useState(value);
  // const [valueInternal, setValueInternal] = useState([
  //   {
  //     "name": {"first" : "Charlie", "last": "Morton"},
  //     "id" : "asdfasj345fadzx",
  //     "email" : "charlie@lawya.com"
  //   },
  //   {
  //     "name": {"first" : "Tom", "last": "Smith"},
  //     "id" : "8reghiuerw",
  //     "email" : "tom@lawya.com"
  //   },
  //   {
  //     "name": {"first" : "Jeff", "last": "Finks"},
  //     "email" : "jeff@lawya.com"
  //   },
  //   {
  //     "name": {"first" : "Steve",},
  //     "email" : "steve@lawya.com"
  //   },
  //   {
  //     "name": {"first" : "Bad",},
  //     "email" : "steve lawya.com"
  //   },
  //   {
  //     "name": {"first" : "Bob",},
  //   },
  //   {
  //     "email" : "tenant365somelongo@lawya.com"
  //   },
  // ]);
  const firstRender = useFirstRender()
  useOutsideAlerter(mainRef, () => {shrinkAll()})

  var iconColours = ["pink", "DeepSkyBlue", "orange", "LightGreen", "LemonChiffon", "#AD70CC", "#EC6060", "Peru", "Navy", "orchid", "SlateGray", "gold", "Chartreuse"];

  useEffect(() => {
    if (firstRender === false){
      try {
        onChange(valueInternal)
      } catch (e) {}
    }
  }, [valueInternal]);

  function handleClick(event){
    if (editable === false){
      // setError({"message": "This field is not editable", "type": "warn"})
    }
    event.preventDefault()
  }

  function shrinkAll(){
    itemRefs.current.map((item, index) => {
      try{
        item.shrink()
      } catch {}
    })
  }

  function shrinkOthers(clickedIndex){
    itemRefs.current.map((item, index) => {
      try{
        if (index != clickedIndex){
          item.shrink()
        }
      } catch {}
    })
  }

  function handleItemDelete(i){
    console.log("clicked on delete", i);
    console.log("0 to i", valueInternal.slice(0,i));
    console.log("I+1 to length", valueInternal.slice(i+1, valueInternal.length));
    const newVal = valueInternal.slice(0,i).concat(valueInternal.slice(i+1, valueInternal.length))
    console.log("newval", newVal);
    setValueInternal(newVal)
  }

  function handleItemAdd(){
    console.log("clicked on Add");
    shrinkAll()
    const newValItem = {}
    // for (var i = 0; i < fields.length; i++) {
    //   const fieldId = fields[i].id
    //   newValItem[fieldId] = ""
    // }
    setValueInternal([...valueInternal, newValItem])
    // itemRefs.current[itemRefs.current.length-1].expand()
  }

  return (
    <div
      className=""
      ref={mainRef}
      onClick={e => {
        console.log("clicked outside");
        e.stopPropagation()
        // itemRefs.current.map((item, index) => {item.shrink()})

      }}
    >
      {valueInternal.map((item, index) => {
        return (
          <Item
            ref={el => itemRefs.current[index]=el}
            key={item.id || index}
            item={item}
            index={index}
            colour={iconColours[index]}
            editable={true}
            handleItemDelete={() => handleItemDelete(index)}
            onClick={() => {
              console.log("running oncccc");
              shrinkOthers(index)
            }}
            onChange={v => {
              let newVal = valueInternal.map((element, vi) => vi == index ? v : element);
              setValueInternal(newVal)
            }}
          />
      )
      })}

    {!(editable===false) &&
      <div className="ListAddItemButton" onClick={handleItemAdd}>
        <img src="/add-icon-grey.svg" className="SelectedAreaIcon"/>
      </div>
    }

    </div>
  )
}
