import React from "react";
import { useState, createContext, useContext } from "react";
import {
  sendEmailLink,
} from "../firebase.js"
import {Link, useNavigate, useSearchParams} from 'react-router-dom'

// import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { useAuth, } from "../contexts/AuthContext"

import ErrorBox from "../components/tenancyAgreements/Clause/ErrorBox.js"

// const auth = getAuth()


function Login(){
  const [email, setEmail] = useState(window.localStorage.getItem('emailForSignIn', 'email') ?? '');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [link, setLink] = useState('');
  const [loginError, setLoginError] = useState('');
  const [awaitingLink, setAwaitingLink] = useState( false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()
  const {register, login} = useAuth()
  const [searchParams] = useSearchParams();


  const handleLogin = (event) => {
    setLoading(true)
    event.preventDefault()
    if (!password || !email) {
      setLoginError("Must provide a valid email and password")
      setLoading(false)
      return null
    }


    login( email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        console.log("user is ", user);
        // ...
        setLoading(false)
        if (searchParams.get("redirect")){
          console.log("got redirect parameter");
          try {
            navigate(decodeURIComponent(searchParams.get("redirect")))
          } catch {
            navigate("/tenancies")
          }
        } else {
          navigate("/tenancies")
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        console.log(errorCode);
        const errorMessage = error.message;
        setLoginError(errorMessage)
        setLoading(false)
        // ..
      });
  }

  return (
    <div className="LoginPage" >

      <form className="loginContainer" >
        <div className="LoginLogo" >
          <img src="/10NC-logo/manager.png" style={{height: 80, marginBottom: 40}}/>
        </div>
        <div className="LoginTitleContainer" >
          <h1 className="LoginTitle" style={{display: 'none'}}>Register</h1>
        </div>
        <div className="LoginForm" >
          <input className="LoginInput" type="email" placeholder="Email" value={email} onChange={(event) => {setEmail(event.target.value)}}/>
          <input className="LoginInput" type="password" placeholder="Password" onChange={(event) => {setPassword(event.target.value)}}/>
        {loginError && <ErrorBox message={loginError}/>}
          <button disabled={loading} type="submit" className="Button BlueOutline" style={{marginTop: 10}} onClick={handleLogin}>
            LOGIN
        </button>
        </div>
      </form>
      <div className="w-100 text-center mt-2" >
        <Link to="/register" >Need an account?</Link>
      </div>
    </div>
  )
}

export default Login
