import React from "react";

export default function SimpleSelect({onChange, options, value, ...otherProps}){

  return (
    <select value={value} className="ClauseTextDropdown"
      onChange={onChange}
      {...otherProps}
    >
      {options.map((item, index) => (
        <option value={item.value} key={item.value} disabled={item.disabled == true}>{item.text}</option>
      ))}
    </select>
  )
}
