import React from "react";

import MultiLineP from "../../MultiLineP.js"


function ClauseMainText({children, expanded, placeholder, ...otherProps}){

  // return (
  //   <p className="ClauseText" {...otherProps}>
  //     {children}
  //   </p>
  // )

  return (
    <MultiLineP className="ClauseText" {...otherProps}>
      {children}
    </MultiLineP>
  )

}

export default ClauseMainText
