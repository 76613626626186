import React, {useRef} from "react"

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useParams,
    useNavigate,
} from 'react-router-dom';

import ComplianceBox from "../components/tenancy/ComplianceBox.js"
import Modal from "../components/Modal.js"
import NavBar2 from "../components/NavBar2.js"

function Home(){
  const { name } = useParams()
  const navigate = useNavigate()
  const modalRef = useRef()
  const agreeRef = useRef()

  console.log("name", name);


  return (
      <div className="FullSizeScreen" style={{}}>
        <NavBar2/>
        <div style={{margin: "auto"}} >


        </div>


      </div>

  )

}

export default Home;
