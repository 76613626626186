import React from "react";
import { useState, useEffect, createContext, useContext, useRef } from "react";
// import SignatureCanvas from 'react-signature-canvas'
import SignaturePad from 'react-signature-canvas'

import Signature from "../inputs/Signature.js"
import BackButton from "../BackButton.js"
import ExpandingInput from "../ExpandingInput.js"
import MultiLineP from "../MultiLineP.js"
import ErrorBox from "../tenancyAgreements/Clause/ErrorBox.js"

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useParams,
    useNavigate,
    useLocation,
} from 'react-router-dom';

import {AgreementDataContext} from "../../screens/tenancyAgreements/Container.js"
import { useAuth, } from "../../contexts/AuthContext"

function ViewComplete({}){
  const {agreementData, setAgreementData, getValueByValueReference, userTenancyRoles} = useContext(AgreementDataContext);
  const { tenancyId, taId, sectionSlug } = useParams()
  const {currentUser} = useAuth()
  const navigate = useNavigate();
  const location = useLocation()
  const sigPadRef  = useRef()
  const [pointsArray, setPointsArray] = useState(null);
  const [signature, setSignature] = useState();
  const [nameConfirm, setNameConfirm] = useState("");
  const [signerRole, setSignerRole] = useState(userTenancyRoles);
  const [error, setError] = useState();
  const [awaitingResponse, setAwaitingResponse] = useState(false);

  useEffect(() => {
    console.log("sign loaded with ad", agreementData);
    console.log("SIGNING status: ", agreementData.status);
    console.log("SIGNING roles: ", userTenancyRoles);

    if ((userTenancyRoles.includes('manager')|| userTenancyRoles.includes('landlord')) && ['inTenantSigning'].includes(agreementData.status)){
      console.log("SIGNING: is LL and signed", userTenancyRoles);
      navigate("/tenancies/" + tenancyId + "/agreements/" + taId + "/share")
      // navigate(location.pathname.split('/').slice(0,-1).join('/') + '/' + 'share' + location.search)
    }

    if (false && userTenancyRoles.includes('tenant') && ['inTenantSigning'].includes(agreementData.status)){
      console.log("SIGNING: is LL and signed", userTenancyRoles);
      navigate("/tenancies/" + tenancyId + "/agreements/" + taId + "/share")
      // navigate(location.pathname.split('/').slice(0,-1).join('/') + '/' + 'share' + location.search)
    }

  }, []);


  // Don't show the page if ....
  // if ((userTenancyRoles.includes('landlord') || (userTenancyRoles.includes('manager')) && ['inTenantSigning'].includes(agreementData.status)){


  return (
    <div
      className="AgreementFinalisePage"
    >
      <div className="SectionHeaderAll" >
        <div style={{float: 'left', position: 'absolute'}} >
          <BackButton onClick={() => {
            try {
                navigate("/tenancies/" + tenancyId + "/agreements/" + taId + "/" + agreementData.sections[agreementData.sections.length-1].slug + location.search)
                // navigate(location.pathname.split('/').slice(0,-1).join('/') + '/' + agreementData.sections[agreementData.sections.length-1].slug + location.search)
                window.scrollTo(0, 0)
            } catch (e) {
              console.log("defaulting back in browser");
              navigate(-1)
            }
          }}/>
        </div>
      </div>
      <h1 className="AgreementMakerH1" style={{marginTop: 0, marginBottom: 10}}>Time to sign</h1>
      <h2 className="AgreementMakerH2" style={{marginTop: 0, marginBottom: 20}}>Let's make your new tenancy official!</h2>



      <div className="SigningArea" >
        <p style={{marginBottom: 5, color: "#48525D", fontWeight: "bold"}}>Sign in the box</p>
      <Signature sigRef={sigPadRef} style={{marginTop: 10, marginBottom: 40}}/>
        <p style={{marginBottom: 5, color: "#48525D", fontWeight: "bold"}}>Confirm your legal name</p>
        <p className={"ClauseText center" + (nameConfirm ? "" :" placeholder")}>
          <ExpandingInput
            min={1}
            max={365}
            extraWidth={0}
            placeholder="e.g Joseph Francies Tribbiani Jr."
            className={"ClauseTextInput"}
            value={nameConfirm}
            onChange={(v) => {
              setNameConfirm(v.target.value)
            }}
          />
         {/* for and on behalf of The Landlord */}
         {signerRole=="landlord" && "signing as The Landlord"}
         {signerRole=="tenant" && "signing as a tenant"}
        </p>
      </div>

      <div className="" style={{alignItems: "left", maxWidth: 520}}>
        <p className="SigningTsCs">By signing in the box above you are agreeing to the terms of the tenancy agreement whose details are below and which linked you to this page.</p>
        <table width="100%" className="SigningTsCs" style={{alignItems: "left", marginLeft: 30, textAlign: "left", verticalAlign: "top", }}>
          <tbody>
            <tr style={{verticalAlign: "top"}}>
              <td style={{verticalAlign: "top"}}>
                <p className="SigningTsCs">Agreement ID:</p>
              </td>
              <td>
                <p className="SigningTsCs">{agreementData.id}</p>
              </td>
            </tr>
            <tr style={{verticalAlign: "top"}}>
              <td style={{verticalAlign: "top"}}>
                <p className="SigningTsCs">Agreement URL:</p>
              </td>
              <td style={{ maxWidth: 200}}>
                <a href={"https://manager.10nc.app/tenancies/" + 'tenancyId' + /agreements/ + "agreementData.id"} target="_blank"><p className="SigningTsCs" style={{wordWrap: "break-word", fontSize: 12}}>https://manager.10nc.app/tenancies/{tenancyId}/agreements/{agreementData.id}</p></a>
              </td>
            </tr>
            <tr>
              <td style={{verticalAlign: "top"}}>
                <p className="SigningTsCs" style={{marginRight: 20, wordWrap: "break-word"}}>Property address:</p>
              </td>
              <td>
                <MultiLineP className="SigningTsCs" pStyle={{marginBottom: 0, marginTop: 0}}>{getValueByValueReference("property_address")}</MultiLineP>
              </td>
            </tr>
          </tbody>
        </table>
        <p className="SigningTsCs">Any descriptions of the terms, shown in italics underneath the terms when clicked, as well as details of alternative term options, are provided by 10NC for informational purposes only and are not legally binding between landlords and tenants.</p>
        <p className="SigningTsCs">You are also agreeing to the 10NC terms of service which can be found here</p>
      </div>

      <button className="ContinueButton outline green"
        onClick={async() => {
          if(awaitingResponse) return

          if(!(sigPadRef.current.isEmpty()===false && nameConfirm)){
            setError({type: "error", message: "Missing signature or legal name"})
            return
          } else {
            setError(null)
          }

          setAwaitingResponse(true)
          const signData = {
            "tenancyId" : tenancyId,
            "agreementId" : taId,
            "signatureString" : sigPadRef.current.toDataURL('base64string'),
            "role" : signerRole,
            "legalName": nameConfirm
          }
          console.log("signData", signData)
          const token = await currentUser.getIdToken()
          console.log("got token", token);
          fetch('https://europe-west2-nc-e9104.cloudfunctions.net/sign-tenancy-agreement', {
            method: 'POST',
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(signData)
          })
            .then(response => response.json())
            .then(data => {
              console.log("got response", data);
              if (data.status == "inTenantSigning"){
                console.log("doc now in tenant signing");
                navigate("/tenancies/" + tenancyId + "/agreements/" + taId + "/" + "share")
                // navigate(location.pathname.split('/').slice(0,-1).join('/') + '/' + 'share' + location.search)
              }
            })
            .catch(error => {
              console.log("error:", error);
              setError({"type": "error", message: "We were not able to complete the signing. Please   email help@10nc.app  "})
              setAwaitingResponse(false)
            })
        }}
      >
        CONFIRM SIGNATURE
      </button>
      {error && <ErrorBox {...error}/>}
    </div>
  )
}

export default ViewComplete
