import React, {useState, useEffect, useRef, forwardRef} from "react";
import { useNavigate } from 'react-router-dom';

import OverviewBox from "./OverviewBox"
import DateInput from "../inputs/Date.js"
import ExpandingInput from "../ExpandingInput.js"
import ErrorBox from "../tenancyAgreements/Clause/ErrorBox.js"
import ErrorTriangle from "../ErrorTriangle.js"

import {useFirstRender} from "../../utility/events.js"

import { getFirestore, doc, collection, updateDoc } from "firebase/firestore";

const db = getFirestore();


export default function DepositBox({tenancyId, tenancy, editable}){
  const navigate = useNavigate()
  const firstRender = useFirstRender();
  const [showSection, setShowSection] = useState("required");
  const [isRequired, setIsRequired] = useState(tenancy?.deposit?.required);
  const isRequiredRef = useRef(tenancy?.deposit?.required)
  const [depositAmount, setDepositAmount] = useState(tenancy?.deposit?.amount || "");
  const depositAmountRef = useRef(tenancy?.deposit?.amount || "")
  const [dueDate, setDueDate] = useState();
  const depositDueDateRef = useRef()
  const [myDee, setMyDee] = useState();

  const updateTimer = useRef()



  useEffect(() => {
    if (isRequired === true){
      setShowSection("amount")
    }
  }, [isRequired]);

  useEffect(() => {
    if (tenancy?.deposit?.dueDate){
      try {
        let newdd = new Date(tenancy.deposit.dueDate.seconds*1000).toISOString().split('T')[0]
        setDueDate(newdd)
        depositDueDateRef.current = newdd
      } catch{
      }
    }
  }, [tenancy?.deposit?.dueDate]);

  async function updateTenancyValue(){
    const tRef = doc(db, "tenancies", tenancyId)
    const updatedValues = {
      "updatedAt": new Date(),
      "deposit.required" : isRequiredRef.current
    }
    if (isRequiredRef.current === true){
      updatedValues["deposit.amount"] = depositAmountRef.current || null
      updatedValues["deposit.dueDate"] = null
      if (depositDueDateRef.current){
        try {
          updatedValues["deposit.dueDate"] = new Date(depositDueDateRef.current)
        } catch {}
      }

    } else {
      updatedValues["deposit.amount"] = null
      updatedValues["deposit.dueDate"] = null
    }
    console.log("updating tenancy values", updatedValues);
    await updateDoc(tRef, updatedValues);
  }

  function delayedUpdate(){
    clearTimeout(updateTimer.current);
    updateTimer.current = setTimeout(() => {
      updateTenancyValue()
    }, 500)
  }


  function maxPermittedDeposit(){
    if (!tenancy?.rent?.amount){
      return null
    }
    switch (tenancy?.rent?.interval) {
      case "week":
        return Math.floor(tenancy?.rent?.amount  * 5)
      case "fortnight":
        return Math.floor(tenancy?.rent?.amount  * 2.5)
      case "month":
        return Math.floor(((tenancy?.rent?.amount*12)/52)* 5)
      case "year":
        return Math.floor((tenancy?.rent?.amount/52)* 5)
      default:
        return null
    }
  }

  function checkPermittedDeposit(){
    return depositAmountRef.current <= maxPermittedDeposit()
  }

  function makeDepositCompliant(d){
    const maxPermitted = maxPermittedDeposit()
    if (maxPermitted>0){
      return Math.min(d, maxPermitted)
    } else {
      return d
    }
  }


  return (
    <OverviewBox title="Security deposit" complete={isRequired===false || (!!depositAmount && !!dueDate)} className="MH260">

      { isRequired != true &&
        <div className="Flex Column" style={{paddingTop:20, paddingBottom: 20}} >
        <button
          className="Blue Square H50"
          style={isRequired===false? {opacity:0.8}:{}}
          disabled={false}
          onClick={() => {
            isRequiredRef.current =true
            setIsRequired(true)
            delayedUpdate()
            setShowSection("amount")
            window.analytics.track("tenancy_overview.deposit_box.set_is_required", { tenancyID:  tenancyId, value: true});
          }}
        >
          Deposit required
        </button>
        <button
          className={"Blue Square H50" + (isRequired===false?" Outline":"")}
          style={isRequired===true? {opacity:0.8}:{}}
          disabled={false}
          onClick={() => {
            window.analytics.track("tenancy_overview.deposit_box.set_is_required", { tenancyID:  tenancyId, value: false});
            if (isRequiredRef.current === false){
              isRequiredRef.current = null
              setIsRequired(null)
            } else {
              isRequiredRef.current = false
              setIsRequired(false)
            }
            delayedUpdate()
          }}
        >
          No deposit required
        </button>

      </div>
      }
      { isRequired === true &&
        <div style={{paddingTop:20, paddingBottom: 20}} >
          <p className="FormLabelUpper">Deposit amount</p>
          <span className="ClauseText" style={{whiteSpace: "noWrap"}}>
            &nbsp;£
            <ExpandingInput
              type="number"
              min={0}
              max={maxPermittedDeposit() || null}
              // step={10}
              minWidth={30}
              extraWidth={15}
              className={"ClauseTextInput currency" + (editable==false? " not-editable" : "")}
              value={depositAmount}
              disabled={editable === false}
              onChange={(v) => {
                window.analytics.track("tenancy_overview.deposit_box.amount_change", { tenancyID:  tenancyId});
                console.log("onc", v.target.value);
                if (v.target.value === "0"){
                  console.log("it's a zero");
                  depositAmountRef.current = ""
                  setDepositAmount("")
                  isRequiredRef.current = false
                  setIsRequired(false)
                } else {
                  const maxPD = makeDepositCompliant(v.target.value)
                    depositAmountRef.current = maxPD
                    setDepositAmount(maxPD)
                }

                updateTenancyValue()
                // delayedUpdate()
              }}
            />
            {/* <span>max 5 weeks' rent</span> */}
            {/* <ErrorBox message="max 5 weeks' rent" type="error"/> */}

          </span>
          <div className="MT30"/>
          <p className="FormLabelUpper">it must be paid before</p>
          <DateInput
            editable={true}
            value={dueDate}
            onChange={(v) => {
              window.analytics.track("tenancy_overview.deposit_box.due_date_change", { tenancyID:  tenancyId});
              console.log("oncccc");
              setDueDate(v)
              depositDueDateRef.current = v
            }}
            onChangeComplete={(v) => {
              console.log("change completedd", v);
              setDueDate(v)
              depositDueDateRef.current = v
              // updateTenancyValue()
              delayedUpdate()
            }}
          />

        </div>
      }
    </OverviewBox>
  )
}
