import React from "react"
import axios from "axios"
import { useState, createContext, useContext, useEffect } from "react";

import ExpandingInput from "../../ExpandingInput.js"
import MultiLineP from "../../MultiLineP.js"
import MultiLineInput from "../../inputs/MultiLineInput.js"


import {AgreementDataContext} from "../../../screens/tenancyAgreements/Container.js"

export default function KeyValueList({text, icon, iconSlug,sectionIndex, clauseIndex, listItemPlaceholder}){
  const {agreementData, setAgreementData, editable:agreementEditable} = useContext(AgreementDataContext);
  const [expanded, setExpanded] = React.useState(false);
  const [data, setData] = React.useState(agreementData.sections[sectionIndex].clauses[clauseIndex]);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [value, setValue] = useState(agreementData.sections[sectionIndex].clauses[clauseIndex].value || []);

  const fields = data.fields
  console.log("fields are ", data.fields);

  useEffect(() => {
    console.log("got a list with data", data);
    console.log("editable for list clause " + data.id + " is ", data.editable);
  }, []);

  useEffect(() => {
    console.log("Value in KV has changed", value);
    setAgreementData(() => {
      const newAD = {...agreementData};
      newAD.sections[sectionIndex].clauses[clauseIndex].value = value
      return (newAD)
    })
  }, [value]);


  if (data?.hidden === true){
    return (<></>)
  }


  function ClauseError({message}){
    return (
      <div className="ClauseErrorBox">
        <img src="/error.svg" className="ClauseErrorIcon"/>
        <p className="ClauseErrorText">{message}</p>
      </div>
    )
  }

  function handleClick(event){
    if (data.editable === false){
      // setError({"message": "This field is not editable", "type": "warn"})
    }
    setExpanded(!expanded)
    event.preventDefault()
  }

  function handleItemDelete(i){
    console.log("clicked on ", text);
    setValue(() => (value.slice(0,i).concat(value.slice(i+1, value.length))))
  }

  function handleItemAdd(){
    console.log("clicked on Add", text);
    const newValItem = {}
    for (var i = 0; i < fields.length; i++) {
      const fieldId = fields[i].id
      newValItem[fieldId] = ""
    }
    setValue([...value, newValItem])
  }

  var iconColours = ["pink", "DeepSkyBlue", "orange", "LightGreen", "LemonChiffon", "#AD70CC", "#EC6060", "Peru", "Navy", "orchid", "SlateGray", "gold", "Chartreuse"];

  try {
    return (
      <div className={"Clause" + (expanded ? " expanded": "")} onClick={handleClick} id={data.id}>
        <div className="ClauseInner">
          <div className="clauseIcon">
            <img src={"/clause-image/" + data.iconSlug}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src="/clause-image/placeholder.svg";
              }}
              className="ClauseIcon"
              alt={text}
            />
          </div>
          <div className="ClauseTextContainer">
            <p className="ClauseText">
              {data?.text?.pre}
              {data.text && typeof data.text === 'string' && data.text}
            </p>
          </div>

        </div>
        <ul className="SelectedAreas">
          {value.map((item, index) => (
            <li className="SelectedAreaRow" key={index} onClick={(e) => {e.stopPropagation()}}>
              <div className="PeopleRowLeft">
              {data.numbered && <p className="ClauseListNumber">{index + 1}</p>}
              {data.lettered && <p className="ClauseListNumber">{(index + 10).toString(36).toUpperCase()}</p>}
              <img
                src={"/" + data.itemIconSlug || listItemPlaceholder}
                className="PeopleListItemIcon"
                style={{backgroundColor: iconColours[index], borderRadius: 100}}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src="/list-item-icon.svg";
                }}
              />
            {
              // data.editable===false &&
              // <p className="ClauseListItem">{item.text}</p>
            }

              <div className="" style={{display: "table", width: "auto", verticalAlign: "top"}}>
                {fields.map((field, fieldIndex) => (
                  <div className="" style={{display: "table-row", width: "auto", clear: "both", paddingTop: 20, verticalAlign: "top"}}>
                      <div style={{display: "table-cell", width: "auto", verticalAlign: "top"}}><p className="ClauseText" >{field.title}:</p></div>
                      <div style={{display: "table-cell", width: "auto", }}>
                        <MultiLineInput
                          bottomLine
                          resizeable={false}
                          value={value[index][field.id]}
                          disabled={!agreementEditable}
                          style={{marginLeft: 10, marginRight: 10, marginBottom: 15, width: "100%"}}
                          onChange={(v) => {
                            if (!agreementEditable) return

                            const nV = [...value]
                            nV[index][field.id] = v.target.value
                            setValue(nV)
                          }}
                        />
                      </div>
                  </div>
                ))}

              </div>


            </div>
            {agreementEditable &&
              <div className="SelectedAreaRowRight" onClick={() => {handleItemDelete(index)}}>
                <img src="/delete.svg" className="DeleteIcon"/>
              </div>
            }
          </li>
            ))}
        </ul>
      {!(agreementEditable===false) &&
        <div className="ListAddItemButton" onClick={handleItemAdd}>
          <img src="/add-icon-grey.svg" className="SelectedAreaIcon"/>
        </div>
      }
      <p className="ClauseText" style={{marginLeft: 82}}>
        {data?.text?.post}
      </p>
      {expanded && data.description &&
        <div className="ClauseExpansion">
          {data.description && <MultiLineP className="ClauseDescription">{data.description}</MultiLineP>}
        </div>
      }
        {errorMessage && <ClauseError message={errorMessage}/>}
      </div>
    )
  } catch (e) {
    console.error("could not render a clause", e);
    return null
  }

}
