import React, {useEffect, useState} from "react";
// import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import { AddressFinder } from "@ideal-postcodes/address-finder-bundled";
// import { AddressFinder } from "@ideal-postcodes/address-finder";



// import 'bootstrap/dist/css/bootstrap.min.css';
import "../css/properties.css"

import ExpandingInput from "../components/ExpandingInput.js"
import AddressInput from "../components/inputs/AddressInput.js"
import ErrorBox from "../components/tenancyAgreements/Clause/ErrorBox.js"
import NavBar from "../components/NavBar.js"
import Page from "../components/Page.js"
import Page3 from "../components/Page3.js"

import {parseDateObject} from "../utility/dates"

import { useAuth, } from "../contexts/AuthContext"
import { getFirestore, doc, getDocs, getDoc, addDoc, collection, query, limit, orderBy,onSnapshot, docRef, where, orderByChild } from "firebase/firestore";
// import {db} from "../firebase.js"

import {AiOutlineCloseCircle, AiOutlinePlusCircle} from "react-icons/ai"

const db = getFirestore();

// const controller = AddressFinder.setup({
//   apiKey: "ak_kmdcbe2udADioU7Yh0S2sl3fktIAo",
//
// });



function PropertyBox({l1, l2, id, onClick, style}){

  return (
    <div className="PropertyBox" onClick={onClick} style={style}>
      <p className="PropertyBoxL1">{l1}</p>
      <p className="PropertyBoxL2">{l2}</p>
    </div>
  )
}



function Properties(){
  const {currentUser} = useAuth()
  const [properties, setProperties] = useState([]);
  const [propertyBoxes, setPropertyBoxes] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [newPropertyAddress, setNewPropertyAddress] = useState();
  const [newPropertyData, setnewPropertyData] = useState({});
  const [showAddProperty, setshowAddProperty] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [creatingProperty, setCreatingProperty] = useState(false);
  const [activeProperty, setActiveProperty] = useState('');

  async function getProperties(uid) {

      console.log("getting properties for user ", uid);

      const colRef = collection(db, "properties");
      const q = query(colRef, where("users." + uid + ".roles", "array-contains", "manager"))

      // SECURITY CONCERN: should only show tenancies where the role is given
      const colSnap = await getDocs(q)
      .then(snap => {
        const docs = snap.docs
        const ids = docs.map(x => x.id);
        console.log("ids: ", ids);
        const ps = snap.docs.map(doc => ({
            id: doc.id,
            data: doc.data()
          })
        )
        console.log("properties ", ps);
        setProperties(ps)
        getTenancies(ps)
        //
        // const tenancySectionsObj = {future:future, current: current, past:past}
        // setTenancySections(tenancySectionsObj)
        setLoaded(true)

      })
      .catch(error => {
        console.log("error" , error);
        // alert(error)
        setLoaded(true)
      });
    }

    const getTenancies = async(ps) => {
      console.log("getting tenancies for ps ", ps);
      const propertyIds = []
      for (var i = 0; i < ps.length; i++) {
        propertyIds.push(ps[i].id)
      }
      const colRef = collection(db, "tenancies");
      // const q = query(colRef, where("propertyId", 'in', propertyIds))
      // const q = query(colRef, where("propertyId", 'in', ['tslO42jDL7z2LBKElD9X']))
      // const q = query(colRef, where("propertyId", '==', 'tslO42jDL7z2LBKElD9X'))
      // console.log("finding tenancies where user has a role ", currentUser.uid);
      const q = query(colRef, where("users." + currentUser.uid + ".roles", "array-contains-any", ["manager", ]))
      // const q = query(colRef, where("users." + currentUser.uid + ".roles", "array-contains", "manager"))

      // SECURITY CONCERN: should only show tenancies where the role is given
      const colSnap = await getDocs(q)
      .then(snap => {
        const docs = snap.docs
        const ids = docs.map(x => x.id);
        console.log("tenancy ids: ", ids);
        const ts = snap.docs.map(doc => ({
            id: doc.id,
            data: doc.data()
          })
        )
        console.log("tenancies ", ts);
        const psCopy = [...ps]

        for (var j = 0; j < ps.length; j++) {
          psCopy[j].tenancies = []
          for (var i = 0; i < ts.length; i++) {
            if (ps[j].id === ts[i].data.propertyId){
              psCopy[j].tenancies.push(ts[i])
            }
          }
        }
        setProperties(psCopy)
        console.log("properties is now ", psCopy);
        // setTenancies(ps)

      })
      .catch(error => {
        console.log("error getting tenancies" , error);
      });
    }

  useEffect(() => {
    if (currentUser){
      getProperties(currentUser.uid)
    } else {
      console.log("can't get properties, no user");
    }
  }, [currentUser]);

  useEffect(() => {
    console.log("propertyData change", newPropertyData);
  }, [newPropertyData]);

  useEffect(() => {
    console.log("properties change: ", properties);
  }, [properties]);


  // useEffect(() => {
  //   if (properties){
  //     const boxesList = []
  //     for (var i = 0; i < properties.length; i++) {
  //       const pid = properties[i].id
  //       console.log("pid is", pid);
  //       boxesList.push(
  //         <PropertyBox
  //           key={properties[i].id}
  //           id={properties[i].id}
  //           onClick={() => {
  //             console.log("click");
  //             console.log("setting active prop", pid);
  //             setActiveProperty(pid)
  //           }}
  //           l1={properties[i].data.propertyData.premise}
  //           l2={properties[i].data.propertyData.thoroughfare}
  //           style={pid === activeProperty? {backgroundColor: 'red', flex: 'auto', width: '100%'} : {flex: 1, width: 150}}
  //         />
  //       )
  //     }
  //     setPropertyBoxes(boxesList)
  //   }
  // }, [properties, activeProperty]);

  async function createProperty(){
    setCreatingProperty(true)
    try {
      if (!newPropertyData.urls){return null}
      let url = ""
      if (newPropertyData.urls.umprn){
        // console.log("got umprn", newPropertyData.current.urls.umprn);
        url = newPropertyData.urls.umprn
      } else if (newPropertyData.urls.udprn){
        // console.log("got udprn", newPropertyData.current.urls.udprn);
        url = newPropertyData.urls.udprn
      } else {
        console.log("got no address identifier");
        setCreatingProperty(false)
        return null
      }

      const apiURL = "https://api.ideal-postcodes.co.uk" + url + "?api_key=ak_l8fw4suesSAZlnZ6D0hnnflwtKZkQ"
      console.log("apiURL ", apiURL);
      const fullAddressData = await fetch(apiURL)
      .then((response) => response.json())
      .then((json) => {
        console.log("full address data is", json);
        return json;
      })
      .catch((error) => {
        console.error(error);
        setCreatingProperty(false)
      });

      console.log("fullAddressData", fullAddressData);

      const data = {
        users: {},
        propertyData: fullAddressData.result,
      }

      data.users[currentUser.uid] = {roles: ['manager', 'propertyCreator']}
      await addDoc(collection(db, "properties"), data)
      .then(snap => {
        console.log("property created");
        setnewPropertyData({})
        setNewPropertyAddress('')
        setshowAddProperty(false)
        getProperties(currentUser.uid)
      })
      .catch(error => {
        console.log("error creating property");
        console.log(error.message);
        setCreatingProperty(false)
      })
      setCreatingProperty(false)
    } catch (error){
      setCreatingProperty(false)
    }

  }

  return (
    <Page3 noPadding>
      <div className="PropertiesPage">
        <h1 className="PageTitle">Your Properties</h1>
        { !loaded && <img src="/Spinner-1s-200px.gif" style={{height: 80, marginBottom: 80}}/>}
        <div className="PropertiesGrid" >
          {properties.length < 1 &&
            <p>no properties</p>
          }
          {properties && properties.map((p, idx) =>
            <div className="PropertyBox"
              onClick={() => {
                if (idx ==0){
                  return
                }
                console.log("setting active prop", p.id);
                // setActiveProperty(p.id)
                const arr = [...properties]
                arr.splice(idx, 1)
                arr.unshift(p)
                setProperties(arr)
                console.log("properties reset to", arr);
              }}
              style={p.id === activeProperty? {} : {}}
              key={p.id}
              >
                <div className="PropertyBoxAddress" >
                  <p className="PropertyBoxL1">{p.data.propertyData.premise}</p>
                  <p className="PropertyBoxL2">{p.data.propertyData.thoroughfare}</p>
                </div>
                {idx == 0 &&
                  <div className="PropertyBoxInfo" >
                    <div style={{height: 1, marginHorizontal: 10, backgroundColor: "white", opacity: 0.2, marginBottom: 15}}/>
                  {/* <p className="PropertyBoxLink">- Tenancies</p> */}
                  <p className="PropertyBoxSectionHeader">Tenancies</p>
                  <div className="PropertyBoxTenanciesContainer" >
                    {properties[idx].tenancies && properties[idx].tenancies.map((t, idx2) =>
                      <div className="PropertyBoxTenancy" >
                        <p className="PropertyBoxTenancyDate">{parseDateObject(t.data.dates.start).string} - {parseDateObject(t.data.dates.end).string}</p>
                        {/* <p className="PropertyBoxTenancyInfo">Charlie M, John T & 2 others</p> */}
                        <p className="PropertyBoxTenancyInfo">{t.id}</p>
                      </div>
                    )}

                    <div className="PropertyBoxTenancyNew" >
                      <AiOutlinePlusCircle size={30} color="#E4C28C"/>
                    </div>
                  </div>
                  {/* <p className="PropertyBoxLink">- Edit property</p>
                  <p className="PropertyBoxLink">- Delete</p> */}
                  </div>
                }

            </div>

          )}
        </div>

          <div className="NewPropertyDiv" style={
              showAddProperty?
              {border:  '1px solid #F0F0F0'}:
              {}
            }>
            {showAddProperty &&
              <form>
                <AiOutlineCloseCircle color="silver" style={{position: 'absolute', right: 10, top: 10, cursor: 'pointer'}} onClick={() => {setshowAddProperty(false)}}/>
                <h2 className="AddPropertyTitle">Add a new property</h2>
                <AddressInput type="text" className="ClauseTextInput"
                  onChange={(v)=> {
                    setNewPropertyAddress(v)
                    setnewPropertyData({})
                  }}
                  onSelected={(d) => {setnewPropertyData(d)}} placeholder="26 Brockhurst Road, London, N2 1JL" style={{minWidth: 150}}/>
                {errorMessage && <ErrorBox message={errorMessage}/>}
              </form>
            }
            <button className="Button BlueOutline " style={{marginTop: 10,cursor: 'pointer', opacity: (creatingProperty || !newPropertyData)? 0.4 : 1}} onClick={() => {
                if (creatingProperty){
                  return null
                }
                if (!showAddProperty){
                  setshowAddProperty(true)
                } else if (newPropertyAddress != ""){
                  if (newPropertyData){
                    // Create the property
                    setErrorMessage("")
                    createProperty()
                  } else {
                    // got an address but not data
                    setErrorMessage("please select a property from the dropdown")
                  }
                }
              }}>{showAddProperty? "SUBMIT" : "ADD NEW PROPERTY"}</button>
          </div>


      </div>
    </Page3>
  );

}

export default Properties
