import React from "react";

function ContinueButton({onClick, children, style, colour, ...otherProps}){
  var classes = "ContinueButton outline"
  if (otherProps.disabled ===true){
    classes += " disabled"
  }

  const thisStyle = style || {}

  switch(colour) {
  case "green":
    colour = "#7EC696"
    break;
  case "blue":
    colour = "#4DA2B0"
    break;
  case undefined:
    colour = "#7EC696"
  default:
    colour = colour
}

  if (colour){
    thisStyle.borderColor = colour
    thisStyle.color = colour
  }

  return (
    <button onClick={otherProps.disabled? () => {} : onClick} className={classes} style={thisStyle} {...otherProps}>
      <p className="ContinueButtonTitle">{children || "continue"}</p>
    </button>
  )
}

export default ContinueButton
