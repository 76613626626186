import React from "react";

import { useNavigate, useLocation } from 'react-router-dom'


export default function Breadcrumbs({items, style}){
  const navigate = useNavigate()
  const location = useLocation();


  // items = ['Tenancies','112 Gillespe Rd']
  if (!items){
    items = []
    const splits = location.pathname.split('/')
    splits.forEach((item, idx) => {
      if (item){
        const href = splits.slice(0, idx+1).join('/')
        items[idx] =  {text: item, href: href}
      }
    });

  }

  return (
    <div className="Breadcrumbs Blue LeftPad30" style={style}>
      <p>
      {items && items.map((p, idx) => {
        // {idx <items.length && <span>></span>}
        let next = ">"
        if (!p) return
        return (
          <>
            <span key={p} style={{marginRight: 8, cursor: "pointer"}} onClick={() => {navigate(p.href)}}>{p.text}</span>
            {idx < items.length -1 && <span style={{marginRight: 8}}>></span>}
          </>
        )
      })}
      </p>
    </div>
  )
}
