import React from "react"
import axios from "axios"
// import ClauseOption from "./ClauseOption"
import { useState, createContext, useContext, useRef, useEffect } from "react";

import ErrorBox from "./ErrorBox.js"
import ExpandingInput from "../../ExpandingInput.js"
import MultiLineP from "../../MultiLineP.js"

import {AgreementDataContext} from "../../../screens/tenancyAgreements/Container.js"

function Phone({text, icon, iconSlug, sectionIndex, clauseIndex}){
  const {agreementData, setAgreementData, editable:agreementEditable} = useContext(AgreementDataContext);
  const [expanded, setExpanded] = React.useState(false);
  const [data, setData] = React.useState(agreementData.sections[sectionIndex].clauses[clauseIndex]);
  const [errorMessage, setErrorMessage] = React.useState("")
  const [value, setValue] = useState(agreementData.sections[sectionIndex].clauses[clauseIndex].value || "");
  const inputRef = useRef();


  function handleClick(event){
    if (data.description){
      setExpanded(!expanded)
      event.preventDefault()
    }
  }

  //
  if (data?.hidden === true){
    return (<></>)
  }


  return (
    <div className={"Clause" + (expanded ? " expanded": "")} onClick={handleClick} id={data.id}>
      <div className="ClauseInner">
        <div className="clauseIcon">
          <img src={"/clause-image/" + data.iconSlug}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src="/clause-image/placeholder.svg";
            }}
            className="ClauseIcon"
            alt={text}
          />
        </div>
        <div className="ClauseTextContainer">
          <p className={"ClauseText" + (value && value.length >= (data.minChars || 6)  ? "" :" placeholder")} onClick={() => {inputRef.current.focus()}}>
            {data?.text?.pre}
            <span >
              <ExpandingInput
                className={"ClauseTextInput" + (agreementEditable===false? " not-editable" : "")}
                type="tel"
                value={value}
                min={data?.min?.value}
                max={data?.max?.value}
                inputRef={inputRef}
                disabled={!agreementEditable}
                onBlur={() => {}}
                onChange={(v) => {
                  if (!agreementEditable) return
                  setValue(v.target.value)
                  const newAD = {...agreementData};
                  newAD.sections[sectionIndex].clauses[clauseIndex].value = v.target.value
                  setAgreementData(newAD)
                  // if (v.target.value){
                  //   setAgreementData(newAD)
                  // } else {
                  //   newAD.sections[sectionIndex].clauses[clauseIndex].complete = false
                  //   setAgreementData(newAD)
                  //
                  // }
                }}
              />
            </span>
            {data?.text?.post}
          </p>
        </div>
      </div>
      {expanded &&
        <div className="ClauseExpansion">
          <MultiLineP className="ClauseDescription">{data.description}</MultiLineP>
        </div>
      }
      {errorMessage && <ErrorBox message={errorMessage}/>}
    </div>

  )

}

export default Phone;
