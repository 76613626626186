export const nthDay = function(d) {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1:  return "st";
    case 2:  return "nd";
    case 3:  return "rd";
    default: return "th";
  }
}

export const friendlyDate = function(date){
  if (!date){return ""}
  if (typeof date === 'object' && date.seconds){
    date = dateFromObject(date)
  }
  // const date = new Date
  // console.log("converting date", date);
  const dayShorts = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"]
  const day = dayShorts[date.getDay()]

  const monthShorts = ["Jan", "Feb", "Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec",]

  // const day = date.toLocaleDateString("en-us", {weekday: "short"})
  // const month = date.toLocaleDateString("en-us", {month: "short"})
  const month = monthShorts[date.getMonth()]
  // const time = date.toLocaleDateString("en-us", { hour: "numeric", minute: "numeric"})
  const dateOfMonth = date.getDate()

  const nth = function(d) {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1:  return "st";
    case 2:  return "nd";
    case 3:  return "rd";
    default: return "th";
  }
}
  var hours = date.getHours()
  if (hours < 10){hours = "0" + hours.toString()}
  var mins = date.getMinutes()
  if (mins < 10){mins = "0" + mins.toString()}
  const time = hours
  return (time + ":" + mins + " on " + day + " " + dateOfMonth + nth(dateOfMonth) + " " + month)

  // if less than 7 days ago, show DoW and time

}

export const simpleDayMonthYear = function(date){
  // console.log("trying simpleDayMonthYear on", date);
  if (typeof date === 'object' && date.seconds){
    date = dateFromObject(date)
  }
  // console.log("date transformed to ", date);

  if (!date || date===undefined){return null}

  const monthShorts = ["Jan", "Feb", "Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec",]
  const month = monthShorts[date.getMonth()]

  const dateOfMonth = date.getDate()
  const nth = function(d) {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
      case 1:  return "st";
      case 2:  return "nd";
      case 3:  return "rd";
      default: return "th";
    }
  }

  const year = date.getFullYear()
  return (dateOfMonth + nth(dateOfMonth) + " " + month + " " + year)

}


export const dateFromObject = function(date){
  if (date.seconds){
    return new Date(date.seconds * 1000)
  } else {
    return null
  }
}

export const dateFromSeconds = function(date){
  if (date){
    const dd = new Date(date*1000)
    return dd
  } else {
    return null
  }
}

export const parseDateObject = function parseDateObject(v){
    //Convert any firestore date objects to actual dates
    try {
      // console.log("checking to see if firestore ", v.at);
      v.at = v.at.toDate()
      // console.log("looks like a firestore date", v.at);
    } catch {
      // console.log("not a firestore date")
    }

    if (v?.at && v.at instanceof Date){
      return {
        "date": v.at,
        "string": simpleDayMonthYear(v.at)
       }
    } else if (v?.before){
      return {
        "date" : v?.before? v.before: new Date(),
        "string" : "Sometime in the past"
      }
    } else if (v?.after){
      return {
        "date" : v?.after? v.after: new Date(),
        "string" : "Sometime in the future"
      }
    } else {
      return {
        "date" : new Date(),
        "string" : ""
      }
    }
  }


export const tenancyTense = function tenancyTense(dates){
  var start = ""
  const now = new Date()

  if (dates.start?.at){
    if (dates.start.at < now){
      start = "past"
    } else (
      start = "future"
    )
  } else if (dates.start?.before){
    start = "past"
  } else if (dates.start?.after){
    start = "future"
  } else {
    console.log("could not work out tense of start");
  }

  var end
  if (dates.end?.at){
    if (dates.end.at < now){
      end = "past"
    } else (
      end = "future"
    )
  } else if (dates.end?.before){
    end = "past"
  } else if (dates.end?.after){
    end = "future"
  } else {
    console.log("could not work out tense of end");
  }

  var tense =""
  if (start=="past" && end=="past"){
    tense ="past"
  } else if (start=="past" && end =="future"){
    tense = "current"
  } else if (start=="future" && end =="future"){
    tense = "future"
  }
  return tense
}

export function dateOrTense(date){
  // console.log("making date tense with", date);
  // N.B fix where one date is known we should be able to assume. e.g if start was originally set to "future"
  const now = new Date()

  if (date?.at){
    return simpleDayMonthYear(date.at)
  } else if (date?.before){
    return ("Past")
  } else if (date?.after){
    return ("Future")
  }
}
