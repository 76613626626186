import React from "react";
import { useState, createContext, useContext, useEffect } from "react";

import { TransitionGroup } from 'react-transition-group' // ES6

import Select from "./Clause/Select.js"
import Text from "./Clause/Text.js"
import TextArea from "./Clause/TextArea.js"
import AreaSelector from "./Clause/AreaSelector.js"
import Number from "./Clause/Number.js"
import Currency from "./Clause/Currency.js"
import Rent from "./Clause/Rent.js"
import Date from "./Clause/Date.js"
import Email from "./Clause/Email.js"
import Phone from "./Clause/Phone.js"
import Plain from "./Clause/Plain.js"
import List from "./Clause/List.js"
import KeyValueList from "./Clause/KeyValueList.js"

// import {AgreementDataContext} from "../../App.js"
import {AgreementDataContext} from "../../screens/tenancyAgreements/Container.js"

export function getClauseById(agreementData, id){
  // N.B this is semi-duplicated in Container.js
  try {
    for (var s = 0; s < agreementData.sections.length; s++) {
      for (var c = 0; c < agreementData.sections[s].clauses.length; c++) {
        if (agreementData.sections[s].clauses[c].id == id){
          return (agreementData.sections[s].clauses[c])
        }
      }
    }
  } catch (e) {
    return null
  }
}

export function getClauseByValueReference(agreementData, reference){
  // N.B this is semi-duplicated in Container.js
  try{
    for (var s = 0; s < agreementData.sections.length; s++) {
      for (var c = 0; c < agreementData.sections[s].clauses.length; c++) {
        if (agreementData.sections[s].clauses[c].valueReference == reference){
          return (agreementData.sections[s].clauses[c])
        }
      }
    }
  } catch {
    return null
  }
}

export function getClauseValue(clauseData){
  // console.log("getting clause value with data", clauseData);
  if (clauseData.value){
    return (clauseData.value)
  } else if (clauseData.selectedOption){
    return (clauseData.selectedOption)
  } else {
    return null
  }
}

export function compare(compareValue, operator, compareWith) {
  // compareValue is the value in the tenancy agreement
  // compareWith is the value in this function to check against

  if (compareValue==null || operator==null || compareWith==null){
    // console.log("one of the comparison things is null: ", compareValue, operator, compareWith);
    return false
  }
  switch (operator) {
    case '>':   return compareValue > compareWith;
    case '<':   return compareValue < compareWith;
    case '>=':  return compareValue >= compareWith;
    case '<=':  return compareValue <= compareWith;
    case '==':  return compareValue == compareWith;
    case '!=':  return compareValue != compareWith;
    case '===': return compareValue === compareWith;
    case '!==': return compareValue !== compareWith;
    case 'includes': return compareValue.includes(compareWith);
    case 'longer': return compareValue.length > compareWith;
    case 'shorter': return compareValue.length < compareWith;
  }
}

function Clause({type, data, ...otherProps}){
  const {agreementData, setAgreementData} = useContext(AgreementDataContext);
  const [hide, setHide] = useState(false);
  // const [data, setData] = useState(agreementData.sections[otherProps.sectionIndex].clauses[otherProps.clauseIndex]);


  //TO DO: FIND OUT WHAT THE TYPE IS BY ACCESSING THE LIST OF CLAUSES
  // function ClauseSwitch(){
  //   // console.log("running ClauseSwitch with: ", data);
  //   switch(data.type.toLowerCase()) {
  //     case 'text':
  //       return (
  //         <Text {...otherProps}/>
  //       )
  //       break;
  //     case 'textarea':
  //       return (
  //         <TextArea {...otherProps}/>
  //       )
  //       break;
  //     case 'plain':
  //       return (
  //         <Plain {...otherProps}/>
  //       )
  //       break;
  //     case 'select':
  //       return (
  //         <Select {...otherProps}/>
  //       )
  //       break;
  //     case 'areaselector':
  //       return (
  //         <AreaSelector {...otherProps}/>
  //       )
  //       break;
  //     case 'currency':
  //       return (
  //         <Currency {...otherProps}/>
  //       )
  //       break;
  //     case 'number':
  //       return (
  //         <Number {...otherProps}/>
  //       )
  //       break;
  //     case 'date':
  //       return (
  //         <Date {...otherProps}/>
  //       )
  //     case 'email':
  //       return (
  //         <Email {...otherProps}/>
  //       )
  //       break;
  //     case 'phone':
  //       return (
  //         <Phone {...otherProps}/>
  //       )
  //       break;
  //     case 'list':
  //       return (
  //         <List {...otherProps}/>
  //       )
  //       break;
  //     default:
  //       return (null)
  //   }
  // }

  // return (ClauseSwitch())

  // function CheckShowIf(){
  //
  // }


  // console.log("finding deposit amount ", getClauseById(agreementData, "depositAmount"));

  function getComparisonValue(d){
    // console.log("getting compval for ", d);
    if(d.clause != null){
    // console.log("compval is a clause");
    // console.log("d.clause", d.clause);
    // console.log("compval the clause is ", getClauseById(agreementData, d.clause));
      const clauseToCompare = getClauseById(agreementData, d.clause)
      if (!clauseToCompare) return null
      return (getClauseValue(clauseToCompare))
    } else if (d.valueReference != null){
      // console.log("compval is a valueref: ", d.valueReference);
      const clauseToCompare = getClauseByValueReference(agreementData, d.valueReference)
      // console.log("got the clause to compare: ", clauseToCompare);
      if (!clauseToCompare) return null
      return (getClauseValue(clauseToCompare))
    } else if (d.value != null){
      // console.log("compval is a value");
      return (d.value)
    } else {
      // console.log("no luck on compval");
    }
  }

  useEffect(() => {
    const newAD = {...agreementData};
    if (hide == true){
      newAD.sections[otherProps.sectionIndex].clauses[otherProps.clauseIndex].value = null
      newAD.sections[otherProps.sectionIndex].clauses[otherProps.clauseIndex].hidden = true
      setAgreementData(newAD)
    } else {
      if (newAD.sections[otherProps.sectionIndex].clauses[otherProps.clauseIndex].hidden = true){
        newAD.sections[otherProps.sectionIndex].clauses[otherProps.clauseIndex].hidden = false
        setAgreementData(newAD)
      }
    }
  }, [hide]);

  useEffect(() => {
    // console.log("running clause.js UE", data.id);
    if (data.showIf){
      // console.log("got a showif inside UE");
      try {
        // console.log("compval in is: ", data.showIf.compareValue);
        const compareValue = getComparisonValue(data.showIf.compareValue)
        const operator = data.showIf.operator
        const compareWith = getComparisonValue(data.showIf.compareWith)

        // console.log("comparing whether compval", compareValue, " is ", operator, " than ", compareWith);

        if (compare(compareValue, operator, compareWith)) {
          // console.log("compval", compareValue, " is ", operator, " than ", compareWith);
          setHide(false)
          return
        } else {
          // console.log("compval", compareValue, " is not ", operator, " than ", compareWith);
          // set value for the conditional field in agreement data to be null
          if (agreementData.sections[otherProps.sectionIndex].clauses[otherProps.clauseIndex].value != null || agreementData.sections[otherProps.sectionIndex].clauses[otherProps.clauseIndex].hidden != true){
            // console.log("we need to make a clause value null because it has been hidden", otherProps.sectionIndex, otherProps.clauseIndex);
            const newAD = {...agreementData};
            // newAD.sections[otherProps.sectionIndex].clauses[otherProps.clauseIndex].value = null
            newAD.sections[otherProps.sectionIndex].clauses[otherProps.clauseIndex].hidden = true
            setAgreementData(newAD)
          }
          setHide(true)
        }
      } catch (e){
        console.error("error in performing the showIf comparison: ", e);
        setHide(false)
      }
    } else {
      // console.log("no showif inside UE");
      setHide(false)
    }
  }, [agreementData]);

  if (hide === true){
    return (null)
  } else {
    try {
      switch(data.type.toLowerCase()) {
        case 'text':
          // return (<p>text clause</p>)
          return (
            <Text id={data.id} {...otherProps}/>
          )
          break;
        case 'textarea':
        // return (<p>textarea clause</p>)
          return (
            <TextArea {...otherProps}/>
          )
          break;
        case 'plain':
          return (
            <Plain id={data.id} {...otherProps}/>
          )
          break;
        case 'select':
          // return (<p>select clause</p>)
          return (
            <Select {...otherProps}/>
          )
          break;
        case 'areaselector':
          return (
            <AreaSelector {...otherProps}/>
          )
          break;
        case 'currency':
        // return (<p>currency clause</p>)
          return (
            <Currency {...otherProps}/>
          )
          break;
        case 'rent':
        // return (<p>rent clause</p>)
          return (
            <Rent {...otherProps}/>
          )
          break;
        case 'number':
        // return (<p>number clause</p>)
          return (
            <Number {...otherProps}/>
          )
          break;
        case 'date':
          return (
            <Date {...otherProps}/>
          )
        case 'email':
          return (
            <Email {...otherProps}/>
          )
          break;
        case 'phone':
          return (
            <Phone {...otherProps}/>
          )
          break;
        case 'list':
          return (
            <List {...otherProps}/>
          )
          break;
        case 'keyvaluelist':
          return (
            <KeyValueList {...otherProps}/>
          )
          break;
        default:
        console.error("ERROR: could not find appropriate clause type:", data.type);
          return (null)
      }
    } catch (e) {
      console.error("Could not render the Clause for some reason");
    }

  }



  // if (data.showIf){
  //   console.log("got a showif");
  //   try {
  //     // console.log("compval in is: ", data.showIf.compareValue);
  //     const compareValue = getComparisonValue(data.showIf.compareValue)
  //     const operator = data.showIf.operator
  //     const compareWith = getComparisonValue(data.showIf.compareWith)
  //
  //     console.log("comparing whether compval", compareValue, " is ", operator, " than ", compareWith);
  //
  //     if (compare(compareValue, operator, compareWith)) {
  //       // console.log("compval", compareValue, " is ", operator, " than ", compareWith);
  //       return (
  //         <ClauseSwitch/>
  //       )
  //     } else {
  //       // console.log("compval", compareValue, " is not ", operator, " than ", compareWith);
  //       // set value for the conditional field in agreement data to be null
  //       if (agreementData.sections[otherProps.sectionIndex].clauses[otherProps.clauseIndex].value != null){
  //         console.log("we need to make a clause value null because it has been hidden", otherProps.sectionIndex, otherProps.clauseIndex);
  //         const newAD = {...agreementData};
  //         newAD.sections[otherProps.sectionIndex].clauses[otherProps.clauseIndex].value = null
  //         setAgreementData(newAD)
  //       }
  //
  //       return(<></>)
  //     }
  //   } catch (e){
  //     console.log("error in performing the showIf comparison: ", e);
  //   }
  // } else {
  //   // console.log("no show if for ", data);
  //   return (ClauseSwitch())
  // }


}

export default Clause
