import React from "react";
import { useState, createContext, useContext, useEffect } from "react";

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useParams,
    useNavigate,
    useLocation,
} from 'react-router-dom';

import SectionClauses from "../../components/tenancyAgreements/SectionClauses.js"
import ErrorBox from "../../components/tenancyAgreements/Clause/ErrorBox.js"
import AgreementStartSplashOverlay from "../../components/tenancyAgreements/AgreementStartSplashOverlay.js"
import ContinueButton from "../../components/ContinueButton.js"
import BackButton from "../../components/BackButton.js"
import FormProgressBar from "../../components/FormProgressBar.js"
import FormProgressBar2 from "../../components/FormProgressBar2.js"
import NavBar from "../../components/NavBar.js"
import Page from "../../components/Page.js"
import Clause from "../../components/tenancyAgreements/Clause.js"

// import {AgreementDataContext} from "../../App.js"
import {AgreementDataContext} from "../../screens/tenancyAgreements/Container.js"

function FullAgreement(){
  const {checkSectionComplete, agreementData, setAgreementData, agreementDataLoaded, uploadAgreementData, checkPreviousSectionsComplete, setAutoUpload} = useContext(AgreementDataContext);
  const { tenancyId, taId, sectionSlug } = useParams()
  const navigate = useNavigate();
  const location = useLocation()
  const [sectionIndex, setSectionIndex] = useState(null);
  const [error, setError] = useState();
  const [allValid, setAllValid] = useState(false);
  const [iClauses, setIClauses] = useState([]);
  const [initialClausesSet, setInitialClausesSet] = useState(false);

  useEffect(() => {
    console.log("on loading important AD is ", agreementData);
    window.scrollTo(0, 0)
  }, []);

  useEffect(() => {
    if (initialClausesSet == false){
      const tempIClauses = []
      agreementData.sections.map((item, sectionIndex) => {
        const tempSec = []
        agreementData.sections[sectionIndex].clauses.map((cItem, cIndex) => {
          if (cItem.important === true || (cItem.required && !(cItem.value || cItem.value == false || cItem.value?.length > 0))){
            tempSec.push (
              <Clause
                key={sectionIndex + ":" + cIndex + ":" + cItem.id}
                sectionIndex={sectionIndex}
                clauseIndex={cIndex}
                data={cItem}
              />
            )
          }
        })
        if (tempSec.length > 0){
          tempIClauses.push(
            <div style={{marginBottom: 120}}>
            <h1 className="SectionTitle" style={{marginTop:60, fontSize: 26, marginLeft: 0, textAlign: "left", color: "darkGrey"}}>{agreementData.sections[sectionIndex].title}</h1>
              {agreementData.sections[sectionIndex].starterText &&
                <p className="SectionStarterText">{agreementData.sections[sectionIndex].starterText}</p>
              }
            {tempSec}
          </div>
          )
        }
      })
      setIClauses(tempIClauses)
      setInitialClausesSet(true)
    }
  }, []);

  useEffect(() => {
    if(['inTenantSigning', 'testMode'].includes(agreementData.status)){
      setAllValid(true)
    } else {
      const [complete, incompleteSections] = checkPreviousSectionsComplete(agreementData.sections.length, true)
      console.log("all valid is ", complete, incompleteSections);
      setAllValid(complete)
      if (complete){
        setError()
      }
    }
  }, [agreementData]);


  useEffect(() => {
    console.log("section index has been updated to ", sectionIndex);
  }, [sectionIndex]);


  return (
      <div className="SectionContainer">
      <div className="SectionHeaderAll" >
        <div className="SectionHeaderMain">
          <div style={{float: 'left', position: 'absolute'}} >
            <BackButton onClick={() => {
                try {
                  navigate("/tenancies/" + tenancyId)
                  window.scrollTo(0, 0)
                } catch (e) {
                  console.log("defaulting back in browser");
                  navigate(-1)
                }

              }}
            />
          </div>
        <h1 className="SectionTitle">Important terms</h1>
        <p>Confirm and/or fill in the fields below. Your full tenancy agreement will then be created for you to view, sign and share with your tenants.</p>

        </div>
      </div>
          {iClauses}
      <ContinueButton
        style={{opacity: allValid?1:0.5}}
        onClick={async() => {
          window.analytics.track("tenancy_agreement.important.continue_to_full.click", { tenancyID:  tenancyId, agreementID: agreementData.id});
          if(['inTenantSigning', 'testMode'].includes(agreementData.status)){
            console.log("skipping validation due to status");
            navigate("../full")
            setAutoUpload(false)
          } else {
            const [complete, incompleteSections] = checkPreviousSectionsComplete(agreementData.sections.length, true)
            if (complete===true){
              console.log("all sections complete");

              // navigate("../fullt")
              await uploadAgreementData(
                () => {
                  console.log("TA Upload: Success ON OUTER AWAIT");
                  setError()
                  // alert("about to nav")
                  // navigate("../full")
                  setAutoUpload(false)
                  navigate("../full")
                },
                () => {
                  console.log("TA Upload: FAILED ON OUTER AWAIT");
                }
               )

            } else {
              setError({message: "You must complete all clauses"})
              window.analytics.track("tenancy_agreement.important.continue_to_full.incomplete", { tenancyID:  tenancyId, agreementID: agreementData.id});
              console.log("incompleteClauses when continuing", incompleteSections);
            }
          }
        }}
      >Continue to full agreement</ContinueButton>
      </div>
  )
}

export default FullAgreement
