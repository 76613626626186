import React from 'react'
import ReactMarkdown from 'react-markdown'

export default function Markdown({children, className}){

  return (
    <div className={className} >
      <ReactMarkdown>{children}</ReactMarkdown>
    </div>
  )
}
