import React from "react"
import axios from "axios"
// import ClauseOption from "./ClauseOption"
import { useState, createContext, useContext, useRef, useEffect } from "react";

import MultiLineP from "../../MultiLineP.js"
import ExpandingInput from "../../ExpandingInput.js"


import {AgreementDataContext} from "../../../screens/tenancyAgreements/Container.js"

function TextInput({text, icon, iconSlug, sectionIndex, clauseIndex}){
  const {agreementData, setAgreementData, editable:agreementEditable} = useContext(AgreementDataContext);
  const [expanded, setExpanded] = React.useState(false);
  const [data, setData] = React.useState(agreementData.sections[sectionIndex].clauses[clauseIndex]);
  const [errorMessage, setErrorMessage] = React.useState("")
  const [value, setValue] = useState(agreementData.sections[sectionIndex].clauses[clauseIndex].value || "");
  const [width, setWidth] = useState(10);
  // const [valid, setValid] = useState(false);
  const hackSpan = useRef();
  const inputRef = useRef();


  useEffect(() => {
    try {
      setWidth(Math.max(hackSpan.current.offsetWidth +10,10));
      if (hackSpan.current.offsetWidth > 375){
        // display as text which needs to be clicked to enable the input
      }
    } catch {}
    // console.log("is len: ", value.length, " greater than ", (1 || data.minChars), " : ", value.length >= (1 || data.minChars));
  }, [value]);


  function handleClick(event){
    if (data.description){
      setExpanded(!expanded)
      event.preventDefault()
    }
  }

  //
  if (data?.hidden === true){
    return (<></>)
  }



  // function ClauseText(){
  //     return (
  //       <p className="ClauseText placeholder">
  //         {data?.text?.pre}
  //         <span className="ClauseTextUnderlined placeholder">{data?.text?.placeholder}</span>
  //       {data?.text?.post}
  //       </p>
  //     )
  // }
  //
  function ClauseError(message){
    return (
      <div className="ClauseErrorBox">
        <img src="/error.svg" className="ClauseErrorIcon"/>
        <p className="ClauseErrorText">{message}</p>
      </div>
    )
  }

  //
  return (
    <div className={"Clause" + (expanded ? " expanded": "")} onClick={handleClick} key={data.id} id={"clause-" + data.id} id={data.id}>
      <div className="ClauseInner">
        <div className="clauseIcon">
          <img src={"/clause-image/" + data.iconSlug}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src="/clause-image/placeholder.svg";
            }}
            className="ClauseIcon"
            alt={text}
          />
        </div>
        <div className="ClauseTextContainer">
          <p className={"ClauseText" + (value && value.length >= (data.minChars || 1) ? "" :" placeholder")} onClick={() => {inputRef.current.focus()}}>
            {data?.text?.pre}
            <span >
              <ExpandingInput
                type="text"
                inputRef={inputRef}
                className={"ClauseTextInput number center" + (data?.editable==false? " not-editable" : "")}
                disabled={!agreementEditable}
                placeholder = {data?.text?.placeholder}
                value={value}
                onClick={(e) => {e.stopPropagation()}}
                onChange={(v) => {
                  if (!agreementEditable) return
                  console.log("Text input changed", v.target.value);
                  setValue(v.target.value)
                  const newAD = {...agreementData};
                  newAD.sections[sectionIndex].clauses[clauseIndex].value = v.target.value
                  if (v.target.value){
                    // Set this field as complete
                    newAD.sections[sectionIndex].clauses[clauseIndex].complete = true
                    setAgreementData(newAD)
                    // setAgreementData({...agreementData, testupdate : true})
                  } else {
                    // Set this field as not complete
                    newAD.sections[sectionIndex].clauses[clauseIndex].complete = false
                    setAgreementData(newAD)
                  }
                }}

              />
            </span>
            {data?.text?.post}
          </p>
        </div>
      </div>
      {expanded &&
        <div className="ClauseExpansion">
          <MultiLineP className="ClauseDescription">{data.description}</MultiLineP>
        </div>
      }
      {errorMessage && <ClauseError message={errorMessage}/>}
    </div>

  )

}

export default TextInput;
