import React from "react";
import SignaturePad from 'react-signature-canvas'

export default function Signature({sigRef, style}){

  return (
    <div className="SigPad" style={style}>
      <SignaturePad penColor='green' ref={sigRef}
        clearOnResize={false}
        canvasProps={{ className: 'sigCanvas'}} />
      <button
        style={{border: "0px solid silver", backgroundColor: "transparent", boxShadow: "none", borderRadius: "0 0 5px 5px", margin: 0, padding: 5, textTransform: "uppercase", color: "grey", width: "100%", borderTop: "1px solid silver"}}
        onClick={() => {sigRef.current.clear()}}>Clear</button>
    </div>
  )
}

// const signature = React.forwardRef((props, ref) => (
//   <div className="SigPad" style={props.style}>
//     <SignaturePad penColor='green' ref={ref}
//       canvasProps={{ className: 'sigCanvas'}} />
//     <button
//       style={{border: "0px solid silver", backgroundColor: "transparent", boxShadow: "none", borderRadius: "0 0 5px 5px", margin: 0, padding: 5, textTransform: "uppercase", color: "grey", width: "100%", borderTop: "1px solid silver"}}
//       onClick={() => {ref.current.clear()}}>Clear</button>
//   </div>
// ));
//
// export default signature
