import React from "react";

function ClauseIcon({slug, alt}){

  return (
    <div className="clauseIcon">
      <img src={"/clause-image/" + slug}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src="/clause-image/placeholder.svg";
        }}
        className="ClauseIcon"
        alt={alt}
      />
    </div>
  )
}

export default ClauseIcon
