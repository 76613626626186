import React from "react";

import { useAuth, } from "../contexts/AuthContext"



function Footer({white}){
  const {register, login, logOut} = useAuth()

  return (
    <div
      className={"Footer" + (white?" White":"")}

    >
      <p onClick={() => {
        console.log("signing out");
        logOut()
      }} style={{textAlign: "center", cursor: "pointer", color: white?'#4DA2B0':'white'}}>Sign out</p>
    </div>
  )
}

export default Footer
