import React, {useState, useEffect} from "react";

export default function AgreementStartSplashModal({id, onHide}){
  const [hide, setHide] = useState(localStorage.getItem("modal-hide-" + id, false));

  function handleHide(){
    setHide(true)
    if (onHide){
      onHide()
    }
  }

  if(hide){
    return
  }

  return (
    <div className="FullOverlay" onClick={(e) => {
        e.stopPropagation()
        handleHide()
      }}>
      <div className="Flex W100 AlignCenter JustifyCenter W100vh" >
        <div className="Flex Column ModalCard AlignCenter" >
          <h1>5 Minutes</h1>
          <h2>No lawyers, and your tenants can move right in</h2>
          <div className="ModalCardBottom Flex Column AlignCenter" >
            <ul>
              <li>It's quicker than a conversation</li>
              <li>You can come back to it later</li>
              <li>You're not bound to anything until you and the tenants sign</li>
              <li>After the first few sections it's much quicker</li>
              <li>Doesn't cost anything to create the agreement <br/><span style={{fontSize: "0.8em"}}>(you will need an active plan for signing)</span></li>
            </ul>
            <button className="Yellow H70 W100 MT20 Round" onClick={()=> {
              handleHide()
              if (id){
                localStorage.setItem("modal-hide-" + id, true);
              }
            }}>I'm ready</button>
          </div>
        </div>
      </div>
    </div>
  )
}
