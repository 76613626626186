import React from "react";
import { useState, useEffect, createContext, useContext, useRef } from "react";

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useParams,
    useNavigate,
    useLocation,
} from 'react-router-dom';

import { useAuth, } from "../../contexts/AuthContext"

export default function Home({}){
  const { agreementId } = useParams()
  const {currentUser} = useAuth()
  const navigate = useNavigate();
  const location = useLocation()

  useEffect(() => {
    if (!currentUser) return () => {
      console.log("no user")
      navigate("/login" + "?redirect=" + encodeURIComponent(window.location.pathname), { replace: true })
      // navigate("/login")
    }
    const getTenancy = async() => {
      const token = await currentUser.getIdToken()
      fetch('https://europe-west2-nc-e9104.cloudfunctions.net/find-tenancy-agreement2', {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "agreementId": agreementId,
          "showData": false
        })
      })
        .then(response => response.json())
        .then(data => {
          console.log("got response", data);
          if (data.path){
            navigate("/tenancies/" + data.tenancyId + "/agreements/" + data.agreementId )
          }

          // if (agreementData.status == "inTenantSigning" && ['manager', 'landlord', 'tenancyCreator'].includes(userTenancyRoles)){
          //   console.log("doc now in tenant signing");
          //   if (tenancy.managementPlan?.active === true){
          //     console.log("has active plan");
          //     navigate("/tenancies/" + tenancyId + "/agreements/" + taId + "/share")
          //   } else {
          //     console.log("no active plan, heading to plan select");
          //     navigate("/tenancies/" + tenancyId + "/agreements/" + taId + "/select-plan")
          //   }
          //   // navigate(location.pathname.split('/').slice(0,-1).join('/') + '/' + 'share' + location.search)
          // }

        })
        .catch(error => {
          console.log("error:", error);
          // setError({"type": "error", message: "We were not able to complete the signing. Please   email help@10nc.app  "})
          // setAwaitingResponse(false)
        })
      }

    getTenancy()

    // if (c != true){
    //   // There are incomplete sections so navigating back to those
    //   console.log("not all sections have been completed", is);
    //   navigate("/tenancies/" + tenancyId + "/agreements/" + taId + "/s/" + agreementData.sections[lis].slug + location.search)
    // }

  }, [currentUser]);

  return (
    <div
      className=""
    >
    </div>
  )
}
