import React from "react";
import { useState, createContext, useContext, useEffect } from "react";

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useParams,
    useNavigate,
    useLocation,
    Outlet,
} from 'react-router-dom';

import { getFirestore, doc, getDocs, getDoc, addDoc, setDoc, updateDoc, collection, collectionGroup, query, limit, orderBy, onSnapshot, docRef, where, orderByChild } from "firebase/firestore";
import { useAuth, } from "../../contexts/AuthContext"

import NavBar2 from "../../components/NavBar2.js"


const db = getFirestore();


export default function JoinTContainer({}){
  const navigate = useNavigate();
  const location = useLocation()
  const {auth, currentUser, register, login} = useAuth()
  const [email, setEmail] = useState(new URLSearchParams(location.search).get('email') || "");
  const {tenancyId} = useParams()
  const [awaitingResponse, setAwaitingResponse] = useState(false);
  const [propertyAddress, setPropertyAddress] = useState('');
  const [tenancyRequestData, setTenancyRequestData] = useState({});

  useEffect(() => {
    document.title = "Join a Tenancy on 10NC"
    console.log("Join T container load");
  }, []);

  useEffect(() => {
    sendRequest()
    // console.log("user inside container changed", currentUser);
  }, [currentUser]);


  async function sendRequest(){
    const reqData = {
      email: email,
      tenancyId: tenancyId,
    }

    const reqHeaders = {
      'Content-Type': 'application/json'
    }

    let token = null
    if (currentUser){
      token = await currentUser.getIdToken()
      reqHeaders['Authorization'] = 'Bearer ' + token
    }

    console.log("sending join T request", reqData, reqHeaders);

    fetch('https://europe-west2-nc-e9104.cloudfunctions.net/join-via-manager', {
      method: 'POST',
      headers: reqHeaders,
      body: JSON.stringify(reqData)
    })
      .then(response => response.json())
      .then(data => {
        console.log("got response", data);
        setTenancyRequestData(data)
        if (data?.tenancy?.propertyData?.singleLine){
          setPropertyAddress(data?.tenancy?.propertyData?.singleLine)
        }
        setAwaitingResponse(false)
      })
      .catch(error => {
        console.log("error:", error);
        setAwaitingResponse(false)
      })
  }



  return (
    <div className="FullSizeScreen">
      <NavBar2/>
      <div name="main" style={styles.mainContent}>
        <Outlet context={{
            email,
            setEmail,
            propertyAddress,
            sendRequest,
            tenancyRequestData
          }}/>
      </div>
    </div>
  )
}

const styles = {
  continueButton: {
    margin: 0,
    marginTop: 25,
    width: "100%",
    boxSizing: "borderBox",
  },
  formLabel: {
    fontFamily: "lato",
    fontSize: 24,
    color: "white",
    fontWeight: 200,
  },
  formLabelMini: {
    fontFamily: "lato",
    fontSize: 18,
    color: "white",
    fontWeight: 500,
    textAlign: "left",
    alignSelf: "flex-start",
  },
  topSection: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: 'column',
    alignItems: "center",
    width: "100%",
    paddingTop: 30,
    boxSizing: "borderBox",
    marginTop: "5vh",
    // minHeight: 100,
    // maxHeight: 100,
    // border: "2px solid pink",
  },
  midSection: {
    display: "flex",
    justifyContent: "center",
    flexDirection: 'column',
    alignItems: "center",
    width: "100%",
    flex: 1,
    // minHeight: 100,
    // maxHeight: 100,
    // height: 800,
    // border: "2px solid blue",
  },
  bottomSection: {
    display: "flex",
    justifyContent: "flexStart",
    flexDirection: 'column',
    alignItems: "center",
    width: "100%",
    // minHeight: 100,
    // maxHeight: 100,
    // height: 200,
    marginBottom: 50,
    boxSizing: "border-box",
    maxWidth: 620,
    // border: "2px solid green",
  },
  textBox : {
    backgroundColor: "white",
    minHeight: 65,
    borderColor: "#DDD9D9",
    borderWidth: 0.5,
    width: "100%",
    borderRadius: 10,
    justifyContent: "center",
    // padding: 12,
    fontFamily: "HelveticaNeue",
    fontSize: 18,
    color: "#48525D",
    paddingLeft: 12,
    paddingRight: 12,
    marginBottom: 10,
    boxSizing: "borderBox",
  },
  mainContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    // width: "100vw",
    // height: "100vh",
    height: "100%",
    // border: "2px solid cyan",
    // padding: 30,
    marginLeft: 30,
    marginRight: 30,
    alignItems:"center",
    justifyContent: "space-between",
    boxSizing: "border-box",
  },
  topText: {
    // flex: 1,
    justifyContent: "center",
    textAlign: "center",
  },
  headerp: {
    fontFamily: 'Bebas Neue',
    fontWeight: "bold",
    color: "white",
    fontSize: 47,
    marginBottom: 11,
    pAlign: "center",
  },
  subHeaderp: {
    fontFamily: "lato",
    // fontWeight: "bold",
    color: "white",
    fontSize: 19,
    pAlign: "center",
    paddingHorizontal: 20,
    marginBottom: 15,
  }
}
