import React from "react";

import BackButton from "./BackButton.js"
import Breadcrumbs from "./Breadcrumbs.js"
import { useNavigate } from 'react-router-dom';

function NavBar({hideBack, publicLogo, blue, transparent, showBreadcrumbs, onBackClick}){
  const navigate = useNavigate();


  let logo = "/10NC-logo/manager/"
  if (publicLogo){
    logo = "/10NC-logo/"
    // logo = "/10NC-logo/blue.png"
  }
  if (transparent){
    logo = logo + 'blue.svg'
  } else {
    logo = logo + "white.svg"
  }

  return (
    <>
      <div
        className={"NavBar2" + (transparent? " Transparent" : " Blue")}
      >
        <div className="NavBar2Inner" >
          <div className="NavBarLeft" >
            {!hideBack && <BackButton white={transparent? false : true} outline onClick={onBackClick}/>}
          </div>
          <div className="NavBarLogo"
            onClick={()=> navigate("/")}
          >
            <img src={logo} style={{height: publicLogo? 20: 30, marginBottom: transparent? 0 : 40, }}/>
          </div>
          <div className="NavBarRight" >

          </div>
        </div>
      </div>

      <div className={"NavBarMobile2" + (transparent? " Transparent" : " Blue")}>
        <div className="NavBarLogo" >
          <img src={logo} style={{height: publicLogo? 20: 30, marginBottom: 0, }}/>
        </div>
      </div>

      {showBreadcrumbs===true && <Breadcrumbs style={{marginTop: transparent?0:20}}/> }
    </>
  )
}

export default NavBar
