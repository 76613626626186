import React from "react";
import { useState, createContext, useContext, useEffect, useRef } from "react";

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useParams,
    useNavigate,
    useLocation,
    Outlet,
} from 'react-router-dom';

import { getFirestore, doc, getDocs, getDoc, addDoc, setDoc, updateDoc, collection, collectionGroup, query, limit, orderBy, onSnapshot, where, orderByChild } from "firebase/firestore";
import { useAuth, } from "../../contexts/AuthContext"

import NavBar2 from "../../components/NavBar2.js"


const db = getFirestore();


export default function NewTContainer({}){
  const navigate = useNavigate();
  const location = useLocation()
  const {auth, currentUser, register, login} = useAuth()
  const [passedEmail, setPassedEmail] = useState(new URLSearchParams(location.search).get('email'));
  const [email, setEmail] = useState(new URLSearchParams(location.search).get('email') || "");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");

  const [newPropertyAddress, setNewPropertyAddress] = useState();
  const [newPropertyData, setnewPropertyData] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [tenancyID, setTenancyID] = useState();
  const [tenancyRef, setTenancyRef] = useState();
  const [partialRef, setPartialRef] = useState();
  const partialRefPast = useRef()

  useEffect(() => {
    document.title = "Create a Tenancy on 10NC"
    console.log("New T container load");

  }, []);

  // Store partial submissions in the DB
  useEffect(() => {
    const uploadPartial = async() => {
      console.log("uploading partial", partialRef, partialRefPast.current);
      let docRef = null
      if (!partialRefPast.current){
        partialRefPast.current = true
        console.log("no partialref");
        docRef = doc(collection(db, "tenancyManagerPartials"))
      } else {
        // console.log("yes partial ref", partialRef);
        if (partialRef){
          docRef = partialRef
        }
      }
      if (docRef){
        setPartialRef(docRef)
        await setDoc(docRef, {email:email||"", createdAt: new Date(), newPropertyAddress: newPropertyAddress||"", newPropertyData: newPropertyData||{}} );
        console.log("uploaded partial", docRef.id);
      }
    }
    if (email || newPropertyData || newPropertyAddress){
      uploadPartial()
    }

  }, [email, newPropertyAddress, newPropertyData]);

  useEffect(() => {
    console.log("user inside container changed", currentUser);
  }, [currentUser]);

  useEffect(() => {
    console.log("tref inside container changed", tenancyRef);
  }, [tenancyRef]);

  // function createTenancy(){
  //   // Check whether there is already a property with this data
  //
  //
  //   // Create a property
  //
  //   // Create a Tenancy and link the property & add user roles
  //
  //   // Return the ID of the tenancy
  //
  // }

  async function createTenancy(){

    const user = auth.currentUser
    console.log("creating tenancy", newPropertyData, "with user", user);
    if (!user) {
      console.log("can't make tenancy because we have no user");
      return
    }

    // TO DO:
    // Check whether there is already a property with this data


    // Create a property

    // Create a Tenancy and link the property & add user roles

    const data = {
      users: {},
      dates : {
      },
      propertyData: {...newPropertyData[1],...{singleLine: newPropertyData[0]}},
      madeWithManager: true,
      createdAt: new Date()
    }
    data.users[user.uid] = {"roles": ["landlord", "manager", "tenancyCreator" ]}
    const docRef = await addDoc(collection(db, "tenancies"), data );
    console.log("created the tenancy", docRef.id);
    window.analytics.track("new_tenancy.created", {
      tenancyId: docRef.id,
      tenancyData:  data,
      addressSingleLine: newPropertyData[0],
      addressThoroughfare: data.propertyData.thoroughfare,
      addressPostcodeOutward: data.propertyData.postcode_outward,
      addressWard: data.propertyData.ward,
      addressPremise: data.propertyData.premise,
      addressDistrict: data.propertyData.district,
    });
    setTenancyRef(docRef)
    // setTenancyID(docRef.id)
    // navigate("success/" + docRef.id)
    navigate("/tenancies/" + docRef.id)
    return docRef.id
  }


  return (
    <div className="FullSizeScreen">
      <NavBar2/>
      <div name="main" style={styles.mainContent}>
        <Outlet context={{
            passedEmail,
            email,
            setEmail,
            firstName,
            setFirstName,
            lastName,
            setLastName,
            phone,
            setPhone,
            newPropertyAddress,
            setNewPropertyAddress,
            newPropertyData,
            setnewPropertyData,
            createTenancy,
            tenancyRef,
            tenancyID,
            setTenancyID,
          }}/>
      </div>
    </div>
  )
}

const styles = {
  continueButton: {
    margin: 0,
    marginTop: 25,
    width: "100%",
    boxSizing: "borderBox",
  },
  formLabel: {
    fontFamily: "lato",
    fontSize: 24,
    color: "white",
    fontWeight: 200,
  },
  formLabelMini: {
    fontFamily: "lato",
    fontSize: 18,
    color: "white",
    fontWeight: 500,
    textAlign: "left",
    alignSelf: "flex-start",
  },
  topSection: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: 'column',
    alignItems: "center",
    width: "100%",
    paddingTop: 30,
    boxSizing: "borderBox",
    marginTop: "5vh",
    // minHeight: 100,
    // maxHeight: 100,
    // border: "2px solid pink",
  },
  midSection: {
    display: "flex",
    justifyContent: "center",
    flexDirection: 'column',
    alignItems: "center",
    width: "100%",
    flex: 1,
    // minHeight: 100,
    // maxHeight: 100,
    // height: 800,
    // border: "2px solid blue",
  },
  bottomSection: {
    display: "flex",
    justifyContent: "flexStart",
    flexDirection: 'column',
    alignItems: "center",
    width: "100%",
    // minHeight: 100,
    // maxHeight: 100,
    // height: 200,
    marginBottom: 50,
    boxSizing: "border-box",
    maxWidth: 620,
    // border: "2px solid green",
  },
  textBox : {
    backgroundColor: "white",
    minHeight: 65,
    borderColor: "#DDD9D9",
    borderWidth: 0.5,
    width: "100%",
    borderRadius: 10,
    justifyContent: "center",
    // padding: 12,
    fontFamily: "HelveticaNeue",
    fontSize: 18,
    color: "#48525D",
    paddingLeft: 12,
    paddingRight: 12,
    marginBottom: 10,
    boxSizing: "borderBox",
  },
  mainContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    // width: "100vw",
    // height: "100vh",
    height: "100%",
    // border: "2px solid cyan",
    // padding: 30,
    marginLeft: 30,
    marginRight: 30,
    alignItems:"center",
    justifyContent: "space-between",
    boxSizing: "border-box",
  },
  topText: {
    // flex: 1,
    justifyContent: "center",
    textAlign: "center",
  },
  headerp: {
    fontFamily: 'Bebas Neue',
    fontWeight: "bold",
    color: "white",
    fontSize: 47,
    marginBottom: 11,
    pAlign: "center",
  },
  subHeaderp: {
    fontFamily: "lato",
    // fontWeight: "bold",
    color: "white",
    fontSize: 19,
    pAlign: "center",
    paddingHorizontal: 20,
    marginBottom: 15,
  }
}
