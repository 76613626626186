import React, {useState, useEffect} from "react";

import "../css/properties.css"


import PageBlue from "../components/PageBlue.js"
import Page3 from "../components/Page3.js"
import NavBar2 from "../components/NavBar2.js"
import Footer from "../components/Footer.js"

import {AiOutlineCloseCircle, AiOutlinePlusCircle} from "react-icons/ai"


import {parseDateObject, tenancyTense} from "../utility/dates"

import {
    Link,
    useParams,
    useNavigate,
} from 'react-router-dom';

import { getFirestore, doc, getDocs, getDoc, addDoc, collection, query, limit, orderBy,onSnapshot, docRef, where, orderByChild } from "firebase/firestore";
import { useAuth, } from "../contexts/AuthContext"

const db = getFirestore();


export default function Tenancies({}){
  const {currentUser} = useAuth()
  const [tenancies, setTenancies] = useState([]);
  const [tenancySections, setTenancySections] = useState();
  const [loaded, setLoaded] = useState();
  const navigate = useNavigate();

  async function getTenancies() {
    console.log("getting tenancies");

    const colRef = collection(db, "tenancies");
    const q = query(colRef, where("users." + currentUser.uid + ".roles", "array-contains", "landlord"))

    // SECURITY CONCERN: should only show tenancies where the role is given
    const colSnap = await getDocs(q)
    .then(snap => {
      const docs = snap.docs
      const ids = docs.map(x => x.id);
      console.log("ids: ", ids);
      const ts = snap.docs.map(doc => ({
          id: doc.id,
          data: doc.data()
        })
      )
      // console.log("docs", ts);
      setTenancies(ts)

      const future = []
      const current = []
      const past = []

      for(let i=0;i<ts.length;i++){
        console.log("id: ", ts[i].id);
        // console.log("addressString: ", ts[i].data.addressString);
        console.log("dates: ", ts[i].data.dates);
        if (!(ts[i].data.dates && ts[i].data.dates.start && ts[i].data.dates.end)){
          console.log("couldn't show tenancy ", ts[i]);
          continue;
        }
        const now = new Date
        const start = parseDateObject(ts[i].data.dates.start).date
        console.log("tenancy has a startsec of ", start);
        console.log("start", ts[i].data.dates.start);
        console.log("start parse" , parseDateObject(ts[i].data.dates.start));
        console.log("start parse D" , parseDateObject(ts[i].data.dates.start).date);

        const end = parseDateObject(ts[i].data.dates.end).date
        console.log("end is ", end);

        const tense = tenancyTense(ts[i].data.dates)
        console.log("tenancy tense is ", tense);

        if (tense=="future"){
          future.push(ts[i])
        } else if (tense=="current"){
          current.push(ts[i])
        } else if (tense =="past"){
          past.push(ts[i])
        } else {
          console.log("couldn't figure out time rank");
        }

      }


      const tenancySectionsObj = {future:future, current: current, past:past}
      setTenancySections(tenancySectionsObj)
      setLoaded(true)
      console.log("tenancy sections", tenancySectionsObj);
      console.log("tenancies", ts);

    })
    .catch(error => {
      // alert(error)
      console.log("error getting tenancies", error);
      setLoaded(true)
    });
  }

  useEffect(() => {
    getTenancies()
  }, []);

  return (
    <Page3>
      <h1 className="PageTitle white">Your Tenancies</h1>
      <div className="" style={{padding: 30}}>
        <div className="PropertiesGrid" style={{}}>

          {tenancies && tenancies.map((t, idx) =>
            <div className="PropertyBox White NoStretch MW300"
              onClick={() => {
                navigate(t.id)
              }}
              key={t.id}
              >
                <div className="PropertyBoxAddress" >
                  <p className="PropertyBoxL1">{t.data.propertyData.line_1 || t.data.propertyData.premise || t.data.propertyData.building_number}</p>
                  <p className="PropertyBoxL2">{t.data.propertyData.line_2 }</p>
                </div>
            </div>

          )}

        </div>
        <div className="" style={{textAlign: 'center', marginTop: 40, display: "flex", justifyContent: "center"}} onClick={() => {navigate("/new-tenancy")}}>
          <div className="PropertyBoxTenancyNew" >
            <AiOutlinePlusCircle size={30} color="#E4C28C"/>
          </div>
        </div>


      </div>

    </Page3>
  )
}
