import React, {useRef, useState, useEffect} from "react";
import validator from 'validator'
// import { useOutletContext } from "react-router-dom";

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useParams,
    useNavigate,
    useLocation,
    Outlet,
    useOutletContext,
} from 'react-router-dom';

// import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/style.css'

import ContinueButton from "../../components/ContinueButton.js"
import AddressInput from "../../components/inputs/AddressInput.js"
import PhoneInput from "../../components/inputs/Phone.js"
import AddressDropdown from "../../components/inputs/AddressDropdown.js"
import ErrorBox from "../../components/tenancyAgreements/Clause/ErrorBox.js"


export default function You({}){
  const emailRef = useRef()
  const navigate = useNavigate();
  const {email, setEmail, firstName, setFirstName, lastName, setLastName, phone, setPhone, wPropertyAddress, setNewPropertyAddress, newPropertyData, setnewPropertyData} = useOutletContext();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [slideOut, setSlideOut] = useState();



  useEffect(() => {
    if (!newPropertyData){
      console.log("no property data, navigating");
      navigate("../")
    }
    console.log("email", email);
    console.log("newPropertyData", newPropertyData);

    window.analytics.identify({
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone
    });
  }, []);

  return (
      <div name="main" style={styles.mainContent} >
        <div name="top" style={styles.topSection}>
          <img src="/join-tenancy-image.svg" style={{width: 250, height: 250}}/>
          <div style={styles.topText}>
            <p style={styles.headerp}>Let's make this tenancy!</p>
          </div>
        </div>
        <div name="mid" className={"NewTenancyMidSection" + (slideOut ? " SlideOutLeft" : " SlideInFromRight")}>
          <div style={{alignItems: "center", justifyContent: "center", display: "flex", flexDirection: "column", marginTop: 20}} >
            <p style={styles.formLabel2}>Tell us about you</p>
              <div style={{width: "100%", display: "table", alignItems: "center", flexDirection: "column", marginBottom: 50}} className="" >


                <div style={{display: "table-row"}} >
                  <div style={{display: "table-cell", paddingRight: 20}} >
                    <p style={styles.formLabel2}>Name</p>
                  </div>
                  <div style={{display: "table-cell", maxWidth: 400}} >
                    <div className="FlexGrid" >
                      <input type="text"
                        value={firstName}
                        onChange={(v) => {setFirstName(v.target.value)}}
                        onBlur={e => {
                          window.analytics.identify({
                            firstName: e.target.value
                          });
                          window.analytics.track("new_tenancy.you.input", {
                            firstName: e.target.value
                          });
                        }}
                        style={styles.nameBox}
                        type="" name=""
                        placeholder="Charlie"
                      />
                      <input type="text"
                        value={lastName}
                        onChange={(v) => {setLastName(v.target.value)}}
                        onBlur={e => {
                          window.analytics.identify({
                            lastName: e.target.value
                          });
                          window.analytics.track("new_tenancy.you.input", {
                            lastName: e.target.value
                          });
                        }}
                        style={styles.nameBox}
                        type="" name=""
                        placeholder="Landington"
                      />
                    </div>
                  </div>
                </div>

                {/* <div style={{display: "table-row"}} >
                  <div style={{display: "table-cell"}} >
                    <p style={styles.formLabel2}>Last name</p>
                  </div>
                  <div style={{display: "table-cell"}} >
                    <input type="text" value={lastName} onChange={(v) => {setLastName(v.target.value)}} style={styles.textBox} type="" name="" placeholder="Landington" />
                  </div>
                </div> */}

                <div style={{display: "table-row"}} >
                  <div style={{display: "table-cell"}} >
                    <p style={styles.formLabel2}>Email</p>
                  </div>
                  <div style={{display: "table-cell"}} >
                    <input
                      type="email"
                      value={email}
                      ref={emailRef}
                      onChange={(v) => {setEmail(v.target.value)}}
                      onBlur={e => {
                        window.analytics.identify({
                          email: e.target.value
                        });
                        window.analytics.track("new_tenancy.you.input", {
                          email: e.target.value
                        });
                      }}
                      style={styles.textBox}
                      type="" name=""
                      placeholder="bestlandlord@gmail.com"
                    />
                  </div>
                </div>

                <div style={{display: "table-row"}} >
                  <div style={{display: "table-cell"}} >
                    <p style={styles.formLabel2}>Phone</p>
                  </div>
                  <div style={{display: "table-cell"}} >
                    {/* <input
                      type="phone"
                      value={phone}
                      onChange={(v) => {setPhone(v.target.value)}}
                      onBlur={e => {
                        window.analytics.identify({
                          phone: e.target.value
                        });
                        window.analytics.track("new_tenancy.you.input", {
                          phone: e.target.value
                        });
                      }}
                      style={styles.textBox}
                      type="" name=""
                      placeholder="07712345667"
                    /> */}

                    <PhoneInput
                      value={phone}
                      onChange={(v) => {
                        setPhone(v)
                      }}
                      onBlur={e => {
                        window.analytics.identify({
                          phone: e.target.value
                        });
                        window.analytics.track("new_tenancy.you.input", {
                          phone: e.target.value
                        });
                      }}
                    />
                  {phone.slice(0,3) == "440" && <ErrorBox message="skip out the 0 at the start"/>}


                  </div>
                </div>

                <div style={{display: "table-row"}} >
                  <div style={{display: "table-cell"}} >
                  </div>
                  <div style={{display: "table-cell", paddingTop: 40}} >
                    <ContinueButton
                      disabled={loading}
                      type="submit"
                      style={{margin: 0, width: "100%"}}
                      colour="white"
                      onClick={() => {

                        window.analytics.track("new_tenancy.you.click_submit", {
                          firstName: firstName,
                          lastName: lastName,
                          email: email,
                          phone: phone
                        });

                        // if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailRef.current.value))){
                        let hasErrors = false
                        if (firstName == ""){
                          setError({ "message" : "Hey, what's your name?"})
                          hasErrors = true
                        }

                        if (validator.isEmail(emailRef.current.value)!=true){
                          setError({ "message" : "That doesn't look like an email"})
                          hasErrors = true
                        }

                        // if (validator.isMobilePhone(phone)!=true){
                        //   setError({ "message" : "That doesn't look like a phone"})
                        //   hasErrors = true
                        // }

                        if (phone.length < 12){
                          setError({ "message" : "That doesn't look like a phone"})
                          hasErrors = true
                        }

                        if (hasErrors) return

                        setLoading(true)
                        if (!newPropertyData){
                          setSlideOut(true)
                          setTimeout(() => {navigate("../")}, 500)

                        } else if (!email){
                          setError({"message" : "Please enter your email"})
                        } else {

                          if (false){

                          } else {
                            console.log("identifying");
                            window.analytics.identify({
                              firstName: firstName,
                              lastName: lastName,
                              email: email,
                              phone: phone
                            });

                            window.analytics.track("new_tenancy.you.submit_success", {
                              firstName: firstName,
                              lastName: lastName,
                              email: email,
                              phone: phone
                            });
                            setTimeout(() => {navigate("../password")}, 500)
                          }
                          // setError({type: "warn", "message" : "We're fixing an issue as we speak"})
                        }
                      }}
                    >
                      Confirm
                    </ContinueButton>
                    {error && <ErrorBox {...error}/>}
                  </div>
                </div>


              </div>
            </div>

        </div>




        {/* <div name="top" style={styles.bottomSection}>
          <ContinueButton disabled={loading} type="submit" style={{margin: 0, width: "100%"}} colour="white"
            onClick={() => {

              window.analytics.track("new_tenancy.you.click_submit", {
                firstName: firstName,
                lastName: lastName,
                email: email,
                phone: phone
              });

              // if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailRef.current.value))){
              let hasErrors = false
              if (firstName == ""){
                setError({ "message" : "Hey, what's your name?"})
                hasErrors = true
              }

              if (validator.isEmail(emailRef.current.value)!=true){
                setError({ "message" : "That doesn't look like an email"})
                hasErrors = true
              }

              if (validator.isMobilePhone(phone)!=true){
                setError({ "message" : "That doesn't look like a phone"})
                hasErrors = true
              }

              if (hasErrors) return

              setLoading(true)
              if (!newPropertyData){
                setSlideOut(true)
                setTimeout(() => {navigate("../")}, 500)

              } else if (!email){
                setError({"message" : "Please enter your email"})
              } else {

                if (false){

                } else {
                  console.log("identifying");
                  window.analytics.identify({
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    phone: phone
                  });

                  window.analytics.track("new_tenancy.you.submit_success", {
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    phone: phone
                  });
                  setTimeout(() => {navigate("../password")}, 500)
                }
                // setError({type: "warn", "message" : "We're fixing an issue as we speak"})
              }
            }}
          >
            Confirm
          </ContinueButton>
          {error && <ErrorBox {...error}/>}
        </div> */}

      </div>
  )
}

const styles = {
  addressDropdown: {
    backgroundColor: "white",
    minHeight: 65,
    borderColor: "#DDD9D9",
    borderWidth: 0.5,
    width: "100%",
    maxWidth: 400,
    borderRadius: 10,
    justifyContent: "center",
    // padding: 12,
    fontFamily: "HelveticaNeue",
    fontSize: 18,
    color: "#48525D",
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 10,
    marginBottom: 10,
    boxSizing: "border-box",
    overflowY: "scroll",
    maxHeight: 200,

    // height: 200,
    // display: "flex",
    // flexDirection: "column"
    // position: "absolute",
  },
  addressSuggestionRow: {
    borderBottom: "0.5px solid #7ebbc6",
    fontSize: 14,
    height: 40,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    boxSizing: "border-box"
  },
  addressSuggestionText: {
    margin: 0,
  },
  continueButton: {
    margin: 0,
    marginTop: 25,
    width: "100%",
    boxSizing: "border-box",
  },
  formLabel: {
    fontFamily: "lato",
    fontSize: 24,
    color: "white",
    fontWeight: 200,
  },
  formLabel2: {
    fontFamily: "arvo",
    fontSize: 18,
    color: "white",
    fontWeight: 200,
    marginBottom: 20,

  },
  formLabelMini: {
    fontFamily: "lato",
    fontSize: 18,
    color: "white",
    fontWeight: 500,
    textAlign: "left",
    alignSelf: "flex-start",
  },
  topSection: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: 'column',
    alignItems: "center",
    width: "100%",
    // paddingTop: 30,
    boxSizing: "border-box",
    // marginTop: "5vh",
    // minHeight: 100,
    // maxHeight: 100,
    // border: "2px solid pink",
  },
  midSection: {
    display: "flex",
    justifyContent: "center",
    flexDirection: 'column',
    alignItems: "center",
    width: "100%",
    flex: 1,
    // minHeight: 100,
    // maxHeight: 100,
    // height: 800,
    // border: "2px solid blue",
  },
  midSectionFromBottom: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: 'column',
    alignItems: "stretch",
    width: "100%",
    flex: 1,
    marginBottom: 40,
    // minHeight: 100,
    // maxHeight: 100,
    // height: 800,
    // border: "2px solid blue",
  },
  main : {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  bottomSection: {
    display: "flex",
    justifyContent: "flexStart",
    flexDirection: 'column',
    alignItems: "center",
    width: "100%",
    // minHeight: 100,
    // maxHeight: 100,
    // height: 200,
    marginBottom: 50,
    boxSizing: "border-box",
    maxWidth: 620,
    // border: "2px solid green",
  },
  textBox : {
    backgroundColor: "white",
    border: 0,
    minHeight: 65,
    width: "100%",
    maxWidth: 400,
    borderRadius: 10,
    justifyContent: "center",
    // padding: 12,
    fontFamily: "HelveticaNeue",
    fontSize: 18,
    color: "#48525D",
    paddingLeft: 12,
    paddingRight: 12,
    marginBottom: 10,
    boxSizing: "border-box",
  },
  nameBox : {
    backgroundColor: "white",
    border: 0,
    minHeight: 65,
    // width: "100%",
    maxWidth: 180,
    borderRadius: 10,
    justifyContent: "center",
    // padding: 12,
    fontFamily: "HelveticaNeue",
    fontSize: 18,
    color: "#48525D",
    paddingLeft: 12,
    paddingRight: 12,
    marginBottom: 10,
    boxSizing: "border-box",
  },
  mainContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    // width: "100vw",
    // minHeight: "100vh",
    minHeight: "100%",
    // border: "2px solid cyan",
    // padding: 30,
    // marginLeft: 30,
    // marginRight: 30,
    alignItems:"center",
    justifyContent: "space-between",
    boxSizing: "border-box",
  },
  topText: {
    // flex: 1,
    justifyContent: "center",
    textAlign: "center",
  },
  headerp: {
    fontFamily: 'Bebas Neue',
    fontFamily: 'Arvo',
    fontWeight: "bold",
    color: "white",
    fontSize: 36,
    margin: 0,
    marginBottom: 11,
    pAlign: "center",
  },
  subHeaderp: {
    fontFamily: "Arvo",
    // fontWeight: "bold",
    color: "white",
    fontSize: 26,
    pAlign: "center",
    paddingHorizontal: 20,
    marginBottom: 15,
  }
}
