import React, {useState, useEffect} from "react";
import FlatList from 'flatlist-react';
import ExpandingInput from "../ExpandingInput.js"

// import {StyleSheet, div, Text, TextInput, FlatList, TouchableOpacity} from "react-native";

function AddressInput({ onChange, onFocus, onSelected, onBlur, style, textStyle, placeholder, enableDontKnow, ...otherProps}) {
  const [showDropdown, setShowDropdown] = useState(false);
  // const [address, setAddress] = useState();
  const [addressText, setAddressText] = useState('');
  const [timer, setTimer] = useState();
  const [lastValue, setLastValue] = useState();
  // const [selectedAddress, setSelectedAddress] = useState();


  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);


  function getAutoComplete(text){
    console.log("getting autocomplete for ", text);
    const encodedText = encodeURIComponent(text)
    return fetch("https://api.ideal-postcodes.co.uk/v1/autocomplete/addresses?api_key=ak_l8fw4suesSAZlnZ6D0hnnflwtKZkQ&q=" + encodedText)
      .then((response) => response.json())
      .then((json) => {
        console.log("JSON", json);
        if (json.result && json.result.hits){
          setAutoCompleteOptions(json.result.hits)
        }
        return json;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    console.log("auto options: ", autoCompleteOptions);
  }, [autoCompleteOptions]);


  function autoCompleteOptionComp(item){
    return (
      <div onClick={() => {
          // setSelectedAddress(item)
          // setAddressText(item.suggestion)
          onChange(item.suggestion)
          setShowDropdown(false)
          if (onSelected){
            console.log("running on selected from address input");
            onSelected(item)
          }

        }}
      >
        <div style={{minHeight: 40, justifyContent: "center", borderTopWidth: 1, borderTopColor: "#EBECEF"}}>
          <p style={styles.text}>{item.suggestion}</p>
        </div>
      </div>
    )
  }


  return (
    <div style={{...styles.box, ...style}}>
      <div style={styles.textContainer}>
        <ExpandingInput
          onChange={(v) => {
            // setSelectedAddress(null)
            console.log("onChangeText inside 1", v);
            onChange(v)
            setAddressText(v.target.value)
            setLastValue(v)
            if (onSelected){
              onSelected(null)
            }
            setShowDropdown(true)
            if (timer){
              console.log("cancelling timer")
              clearTimeout(timer)
            }
            const thisTimer = setTimeout(() => {
              getAutoComplete(v.target.value)
            }, 1000);
            setTimer(thisTimer)
          }}
          value={addressText}
          placeholder={placeholder}
          onFocus={()=> {
            // setShowDropdown(true)
            if(onFocus){onFocus()}
          }}
          onBlur={()=> {
            // setShowDropdown(false)
            // onBlur()
          }}
          {...otherProps}

        />
      </div>
      {showDropdown &&
        <div style={styles.dropDown}>
          <FlatList
            list={enableDontKnow?[...autoCompleteOptions, {suggestion: "Address not listed?"}]:autoCompleteOptions}
            renderWhenEmpty={() => <div></div>}
            style={{maxHeight: 150}}
            scrollEnabled={true}
            showsVerticalScrollIndicator={false}
            renderItem={(item, index) => (
              <div key={index.toString()}
                style={{cursor: 'pointer'}}
                onClick={() => {
                  console.log("clicked on an option: ", item.suggestion);
                    // setSelectedAddress(item)
                    setAddressText(item.suggestion)
                    onChange(item.suggestion)
                    setShowDropdown(false)

                    if (onSelected){
                      console.log("running on selected from address input");
                      onSelected(item)
                    }

                  }}
                >
                <div style={{minHeight: 40, justifyContent: "center", borderTopWidth: 1, borderTopColor: "#EBECEF"}}>
                  <p style={styles.text}>{item.suggestion}</p>
                </div>
              </div>
            )}
            keyExtractor={(item, index) => index.toString()}
          />
        </div>
      }
    </div>
  );
}

const styles = {
  // box : {
  //   backgroundColor: "white",
  //   minHeight: 65,
  //   borderColor: "#DDD9D9",
  //   borderWidth: 0.5,
  //   width: "100%",
  //   borderRadius: 10,
  //   justifyContent: "center",
  //   padding: 12,
  // },
  box : {
    backgroundColor: "white",
    minHeight: 65,
    borderColor: "#DDD9D9",
    borderWidth: 0.5,
    width: "100%",
    borderRadius: 10,
    justifyContent: "center",
    // padding: 12,
    // borderColor: "red",
    // borderWidth: 1,
  },
  textInput: {
    fontFamily: "HelveticaNeue",
    fontSize: 18,
    color: "#48525D",
    width: "100%",
    // borderColor: "brown",
    // borderWidth: 1,
  },
  textContainer: {
    flexDirection: "row",
    width: "100%",
    // minHeight: 65,
    padding: 12,
    alignItems: "center",
    // borderColor: "blue",
    // borderWidth: 1,

  },
  dropDown: {
    // paddingHorizontal: 12,
    // padding: 12,
    // border: '1px solid silver',
    // backgroundColor: '#FAFAFA'
  }
};

export default AddressInput;
