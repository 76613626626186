import React from "react";
import { useState, createContext, useContext, useRef } from "react";
import {sendEmailLink} from "../firebase.js"

import { getFirestore, doc, addDoc, setDoc, collection, document, } from "firebase/firestore";

import Page from "../components/Page.js"
import { useAuth, } from "../contexts/AuthContext"

const db = getFirestore();

function Account(){
  const messageRef = useRef()
  const {currentUser} = useAuth()

  // const [email, setEmail] = useState(window.localStorage.getItem('emailForSignIn', 'email') ?? '');

  return (
    <Page style={{alignItems: "center", justifyContent: "center", textAlign: "center"}}>
      <h1 className="AgreementMakerH1">Your account</h1>
      <div style={{flexDirection: "column", display: "flex", justifyContent: "center", alignItems: "center", marginTop: 50}}>
        <p>Account detail changes, including deletion, need to be approved manually currently</p>
        <p>Please submit a request below</p>
        {
          // <select>
          //   <option>hello</option>
          //   <option>hello</option>
          //   <option>hello</option>
          // </select>
        }
        <textarea ref={messageRef} name="" cols="" rows="10" style={{width: "100%", maxWidth: 500, borderWidth: 3, borderColor: "#7EBBC6", borderRadius: 8, fontSize: 17, padding: 10, marginTop: 30}}></textarea>
        <button
          style={{width: 150, padding: 10, backgroundColor: "#7EBBC6", borderRadius: 8, borderWidth: 0, color: "white", margin: 10, fontSize: 18}}
          onClick={async() => {

            const docRef = await addDoc(collection(db, "accountChangeRequests"), {
              uid: currentUser.uid,
              message: messageRef.current.value
            })
            .then(snap => {
              console.log("successfully sent request");
            })
            .catch(error => {
              console.log("error uploading: ", error);
            })
          }}
        >
          Submit
        </button>
      </div>
    </Page>
  )
}

export default Account
