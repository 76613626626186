import React, {useState, useEffect, useRef, forwardRef} from "react";
import { useNavigate } from 'react-router-dom';


import OverviewBox from "./OverviewBox"
import DateInput from "../inputs/Date.js"

import {useFirstRender} from "../../utility/events.js"

import { getFirestore, doc, collection, updateDoc } from "firebase/firestore";

const db = getFirestore();


export default function DatesBox({tenancyId, start, end, dates}){
  const navigate = useNavigate()
  const [tenancyStartDate, setTenancyStartDate] = useState();
  const [tenancyEndDate, setTenancyEndDate] = useState();
  const firstRender = useFirstRender();
  const [complete, setComplete] = useState(false);


  useEffect(() => {
    if (start){
      try {
        let newStart = new Date(start*1000).toISOString().split('T')[0]
        setTenancyStartDate(newStart)
      } catch{
      }
    }
    if (end){
      let newEnd = new Date(end*1000).toISOString().split('T')[0]
      setTenancyEndDate(newEnd)
    }

  }, [start, end]);

  useEffect(() => {
    // MAKE SURE THE START IS BEFORE THE END AND VICE VERSA
  }, [tenancyStartDate, tenancyEndDate]);

  async function updateTenancyValue(){
    const tRef = doc(db, "tenancies", tenancyId)
    const updatedValues = {
      "updatedAt": new Date(),
    }

    if (tenancyStartDate){
      updatedValues["dates.start.at"] = new Date(tenancyStartDate)
    } else {
      updatedValues["dates.start.at"] = null
    }

    if (tenancyEndDate){
      updatedValues["dates.end.at"] = new Date(tenancyEndDate)
    } else {
      updatedValues["dates.end.at"] = null
    }

    if (updatedValues["dates.start.at"] && updatedValues["dates.end.at"]) setComplete(true)

    console.log("updating tenancy dates", updatedValues);
    await updateDoc(tRef, updatedValues);
  }

  // useEffect(() => {
  //
  //   if (!firstRender && (tenancyStartDate || tenancyEndDate)){
  //     const updatedValues = {
  //       "updatedAt": new Date(),
  //     }
  //
  //     if (tenancyStartDate){
  //       updatedValues["dates.start.at"] = new Date(tenancyStartDate)
  //     }
  //
  //     if (tenancyEndDate){
  //       updatedValues["dates.end.at"] = new Date(tenancyEndDate)
  //     }
  //
  //     console.log("updating dates", updatedValues);
  //     const sendUpdate = async() => {
  //       const tRef = doc(db, "tenancies", tenancyId)
  //       await updateDoc(tRef, updatedValues);
  //     }
  //   }
  // }, [tenancyStartDate]);


  return (
    <OverviewBox title="Set dates" complete={!!(tenancyStartDate && tenancyEndDate)} className="MH260">
      <div style={{paddingTop:20, paddingBottom: 20}} >
        <p className="FormLabelUpper">{new Date(tenancyStartDate) < new Date() ? "The tenancy started on" : "The tenancy will start on"}</p>
        <DateInput
          className=""
          value={tenancyStartDate}
          onChange={(v) => {
            setTenancyStartDate(v)
            window.analytics.track("tenancy_overview.dates.start.change", { tenancyID:  tenancyId});
          }}
          onChangeComplete={(v) => {
            updateTenancyValue()
          }}
        />
        <div className="MT30"/>
        <p className="FormLabelUpper">{new Date(tenancyEndDate) < new Date() ? "it ended on" : "it will end on"}</p>
        <DateInput
          className=""
          value={tenancyEndDate}
          onChange={(v) => {
            setTenancyEndDate(v)
            window.analytics.track("tenancy_overview.dates.end.change", { tenancyID:  tenancyId});

          }}
          onChangeComplete={(v) => {updateTenancyValue()}}
        />

      </div>
    </OverviewBox>
  )
}
