import React from "react";
import { useState, useRef, useEffect } from "react";

export default function DateInput({value, min, max, editable, onChange, onChangeComplete, className,  ...otherProps}){
  // const [dateValue, setdateValue] = useState();
  const [humanDate, setHumanDate] = useState();
  const [showHuman, setShowHuman] = useState(true);
  const inputRef = useRef();
  const firstRender = useRef(true);
  const beenFocused = useRef(false);
  const previousValue = useRef(value);
  const valueAtFocus = useRef(value)


  useEffect(() => {
    makeHuman()
    if (!value){
      setShowHuman(false)
    }
  }, [value]);

  useEffect(() => {
    if (firstRender.current === false){
      if (showHuman === false ){
        inputRef.current.focus()
      }
    }
    firstRender.current = false
  }, [showHuman]);



  function checkChange(){

    if (firstRender.current === false && beenFocused!= false){
      // if (valueAtFocus.current != value){
      //   console.log("value has changed since focus from ", valueAtFocus.current, " to ", value);
      //   onChangeComplete(value)
      // }
      if (value != previousValue.current){
        console.log("value has changed from ", previousValue.current, " to ", value);
        onChangeComplete(value)
      } else {
        // console.log("value hasn't changed from ", previousValue.current, " to ", value);
      }
      previousValue.current = value
    }
  }


  function makeHuman(){
    // console.log(new Date(value).toLocaleDateString("en-GB", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }));
    try {
      if (!value){
        setHumanDate("")
        return
      }
      const humanised = new Date(value).toLocaleDateString("en-GB", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
      setHumanDate(humanised)
    } catch {
      setHumanDate("")
    }
  }


  return (
    <>
      <input
        className={"ClauseTextInput" + (editable==false? " not-editable" : "") + " " + className}
        type="date"
        ref={inputRef}
        value={value || ""}
        style={showHuman ? {display: "none"} : {margin: 0, minHeight: 30}}
        {...otherProps}
        onChange={(v) => {
          // console.log("onChange: ", v);
          if (editable === false) return
          onChange(v.target.value)
        }}
        onBlur={() => {
          if (value){
            makeHuman()
            setShowHuman(true)
          }
          checkChange()
        }}
        onFocus={(e) => {
          valueAtFocus.current = e.target.value
          console.log("date focussed");
          beenFocused.current = true
        }}
      />
      {showHuman &&
        <p
          className={"ClauseTextInput" + (editable==false? " not-editable" : "") + " " + className}
          style={{margin: 0, minHeight: 30, display: showHuman? "block":"none"}}
          onClick={() => {
            setShowHuman(false)
          }}
        >
          {humanDate}
        </p>
      }
    </>
  )
}
