import React, {useState, useEffect, useRef, forwardRef} from "react";
import { useNavigate } from 'react-router-dom';


import OverviewBox from "./OverviewBox"
// import KeyValueList from "./KeyValueList"
import MultiUser from "../inputs/MultiUser"

import {useFirstRender} from "../../utility/events.js"

import { getFirestore, doc, collection, updateDoc } from "firebase/firestore";

const db = getFirestore();

export default function DatesBox({tenancy, tenancyId}){
  const navigate = useNavigate()
  const firstRender = useFirstRender();
  const [tenants, setTenants] = useState(tenancy?.futureTenants || []);
  // const [tenants, setTenants] = useState([
  //   {
  //     "name": {"first" : "Charlie", "last": "Morton"},
  //     "id" : "asdfasj345fadzx",
  //     "email" : "charlie@lawya.com"
  //   },
  //   {
  //     "name": {"first" : "Tom", "last": "Smith"},
  //     "id" : "8reghiuerw",
  //     "email" : "tom@lawya.com"
  //   },
  //   {
  //     "name": {"first" : "Jeff", "last": "Finks"},
  //     "email" : "jeff@lawya.com"
  //   },
  //   {
  //     "name": {"first" : "Steve",},
  //     "email" : "steve@lawya.com"
  //   },
  //   {
  //     "name": {"first" : "Bad",},
  //     "email" : "steve lawya.com"
  //   },
  //   {
  //     "name": {"first" : "Bob",},
  //   },
  //   {
  //     "email" : "tenant365somelongo@lawya.com"
  //   },
  // ]);

  // useEffect(() => {
  //   setTenants([
  //     {
  //       "name": {"first" : "Charlie", "last": "Morton"},
  //       "id" : "asdfasj345fadzx",
  //       "email" : "charlie@lawya.com"
  //     },
  //     {
  //       "name": {"first" : "Tom", "last": "Smith"},
  //       "id" : "8reghiuerw",
  //       "email" : "tom@lawya.com"
  //     },
  //     {
  //       "name": {"first" : "Jeff", "last": "Finks"},
  //       "email" : "jeff@lawya.com"
  //     },
  //     {
  //       "name": {"first" : "Steve",},
  //       "email" : "steve@lawya.com"
  //     },
  //     {
  //       "name": {"first" : "Bad",},
  //       "email" : "steve lawya.com"
  //     },
  //     {
  //       "name": {"first" : "Bob",},
  //     },
  //     {
  //       "email" : "tenant365somelongo@lawya.com"
  //     },
  //   ])
  //   console.log("set tenants");
  // }, []);

  async function updateTenancyValue(v){
    const tRef = doc(db, "tenancies", tenancyId)

    // How do we deal with deletes & conflicts
    // e.g same email
    // removing an existing 'user'

    const updatedValues = {
      "updatedAt": new Date(),
      "futureTenants" : v
    }
    console.log("updating tenancy values", updatedValues);
    await updateDoc(tRef, updatedValues);
  }


  return (
    <OverviewBox title="Add tenants">
      <div style={{paddingTop:20, paddingBottom: 20}} >
        <MultiUser
          value={tenants}
          onChange={v => {
            console.log("tenants changed to ", v);
            updateTenancyValue(v)
            setTenants(v)
          }}
        />

      </div>
      <p className="OverviewBoxNote">Tenants will not be invited or notified until you complete the tenancy agreement</p>
    </OverviewBox>
  )
}
