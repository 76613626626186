import React from "react";
import { useState, createContext, useContext, useEffect, useRef } from "react";

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useParams,
    useNavigate,
    useLocation,
    Outlet,
} from 'react-router-dom';

import { getFirestore, doc, getDocs, getDoc, addDoc, setDoc, updateDoc, collection, collectionGroup, query, limit, orderBy, onSnapshot, docRef, where, orderByChild } from "firebase/firestore";

import Page from "../../components/Page.js"
import AccessDenied from "../../components/AccessDenied.js"
import Breadcrumbs from "../../components/Breadcrumbs.js"
import FloatingAgreementNav from "../../components/tenancyAgreements/FloatingAgreementNav.js"

import { useAuth, } from "../../contexts/AuthContext"
import { delayedTimer } from "../../utility/events"

export const AgreementDataContext = createContext();

export function useAgreementData(){
  return useContext(AgreementDataContext)
}

const db = getFirestore();

export default function Container(){
  const { tenancyId, taId, sectionSlug} = useParams()
  const {currentUser} = useAuth()
  const navigate = useNavigate();
  const location = useLocation()
  // const [agreementData, setAgreementData] = useState(JSON.parse(window.localStorage.getItem('agreementData:' + taId)))
  const [agreementData, setAgreementData] = useState({})
  const [initialAgreementData, setInitialAgreementData] = useState()
  const [agreementDataLoaded, setAgreementDataLoaded] = useState(false);
  const [TAMissing, setTAMissing] = useState(false);
  const [accessDenied, setAccessDenied] = useState(false);
  const [userTenancyRoles, setUserTenancyRoles] = useState([]);
  const [tenancy, setTenancy] = useState();
  const [editable, setEditable] = useState();
  const [initialADSet, setinitialADSet] = useState(false);
  const [iClauses, setIClauses] = useState();
  const [autoUpload, setAutoUpload] = useState(false);
  const uploadTimer = useRef();
  const lastUploadTime = useRef(new Date());
  const [windowFocussed, setWindowFocussed] = useState(true);

  useEffect(() => {
    document.title = "10NC - Agreement"
  }, []);

  // useEffect(() => {
  //   console.log("TA container loaded, running TA useeffect");
  //   getTA(tenancyId, taId)
  // }, []);

  // ROUTING USE EFFECT
  useEffect(() => {

    // N.B users should be able to view the agreement even if we're in a signing state
    // This should be used to enforce screens that the users CANNOT see based on state, not for dynamically routing
    if (agreementData.sections){
      const [c, is, lis, his] = checkPreviousSectionsComplete(agreementData.sections.length)
      if (['inTenantSigning'].includes(agreementData.status)){
        if (userTenancyRoles.includes('manager')|| userTenancyRoles.includes('landlord')){
          // If the user is a manager/landlord do the following

        } else if (userTenancyRoles.includes('tenant')){
          // if the user is a tenant do the following
        }
      } else if (['inLandlordSigning'].includes(agreementData.status)){
        if (userTenancyRoles.includes('manager')|| userTenancyRoles.includes('landlord')){
          // If the user is a manager/landlord do the following

        } else if (userTenancyRoles.includes('tenant')){
          // if the user is a tenant do the following
        }
      } else {

      }
    }


  }, [agreementData.status, userTenancyRoles, currentUser]);

  useEffect(() => {
    console.log("Agreement Status changed: ", agreementData.status);

    if (['inTenantSigning',].includes(agreementData.status)){
      // The document is in signing mode so not editable
      setEditable(false)
    }

  }, [agreementData.status]);

  useEffect(() => {
    console.log("Agreement data editable changed: ", agreementData.editable);
    if (!(agreementData.editable === null || agreementData.editable === undefined)){
      setEditable(agreementData.editable)
    }
  }, [agreementData.editable]);

  useEffect(() => {
    console.log("trying to get a TA snapshot");
    // getTA(tenancyId, taId)
    const unsub = onSnapshot(
      doc(db, "tenancies", tenancyId, "tenancyAgreements", taId),
      (doc) => {
        setAccessDenied(false)
        if (doc.empty){
          console.log("NO TA FOUND");
          setTAMissing(true)
          setAgreementDataLoaded(true)
        } else {
          setTAMissing(false)
          const snapData = doc.data()
          // console.log("Cloud TA change: ", doc.data());
          console.log("Cloud TA change: ", snapData);
          // getTADetailsAsync(snapData)

          const localTaData = JSON.parse(window.localStorage.getItem('agreementData:' + taId))
          // console.log("got local ta data", localTaData);
          if(localTaData && localTaData.sections && localTaData.sections.length > 0 && (!snapData?.updatedAt?.seconds || (localTaData?.updatedAt?.seconds > snapData?.updatedAt?.seconds) )){
            console.log("local TA data is newer so using that", localTaData?.updatedAt?.seconds, " vs ", snapData?.updatedAt?.seconds, localTaData);
            setAgreementData(localTaData)
            setAgreementDataLoaded(true)
          } else {
            console.log("cloud TA data is newer so using that", localTaData?.updatedAt?.seconds, " vs ", snapData?.updatedAt?.seconds, snapData);
            getTADetailsAsync(snapData)
          }
        }
      },
    (error) => {
      console.log("ERROR GETTING TA", error, error.code, error.message);
      if (error.code == "permission-denied"){

      }
      setAccessDenied(true)
    }
  )
      // .catch(error => {
      //   console.log("ERROR GETTING TA", error);
      // })
    ;
    return unsub
  }, []);

  useEffect(() => {
    if(currentUser){
      const unsub = onSnapshot(doc(db, "tenancies", tenancyId), (doc) => {
        if (doc.empty){
        } else {
          const tData = doc.data()
          console.log("Cloud T change: ", tData);
          setTenancy(tData)
          const tenancyRoles = tData.users[currentUser.uid].roles
          console.log("user roles", tenancyRoles);
          setUserTenancyRoles(tenancyRoles)
        }
      });
      return unsub
    }
  }, [currentUser]);

  useEffect(() => {
    console.log("agreement data changed", agreementData);
  }, [agreementData]);

  // useEffect(() => {
  //   if (taId && agreementData && agreementData.sections && agreementData.sections.length > 0 ){
  //     const dictToStore = {
  //       ...agreementData,
  //       ...{
  //         updatedAt: {seconds: Math.floor(Date.now() / 1000), nanoseconds: 0},
  //         "agreementDataSource" : "localStorage"
  //       }
  //     }
  //     console.log("saving to localStorage", dictToStore);
  //     window.localStorage.setItem(
  //       'agreementData:' + taId,
  //       JSON.stringify(dictToStore)
  //     );
  //   } else {
  //     console.log("not saving to local storage", agreementData);
  //   }
  // }, [agreementData]);


  useEffect(() => {
    // N.B this doesn't trigger on any old change
    function doAutoUpload(){
      if (uploadTimer.current) {
        try{
          console.log("autoUpload: clearing timer");
          clearTimeout(uploadTimer.current);
        } catch {
          console.log("autoUpload: couldn't clear upload timer");
        }
      }

      try {
        console.log("autoUpload uploading");
        console.log("autoUpload location", location);
        lastUploadTime.current = new Date()
        uploadAgreementData(
        )
      } catch (e) {
        console.log("error with autoupload", e)
      }
    }

    if (autoUpload === true && windowFocussed==true){
      const timeSinceLastUpload = (new Date() - lastUploadTime.current )
      console.log("autoUpload: timeSinceLastUpload", timeSinceLastUpload);
      if (timeSinceLastUpload > 15000){
        console.log("autoUpload:  been >10s since last upload");
        doAutoUpload()
      } else {
        console.log("autoUpload:  timeSinceLastUpload < 10000, setting timer", timeSinceLastUpload);

        uploadTimer.current = setTimeout(() => {
          console.log("autoUpload: timer running");
          doAutoUpload()
        }, 15*1000)
      }


    }
    return clearTimeout(uploadTimer.current)
  }, [agreementData]);

  useEffect(() => {
    // User has switched back to the tab
    const onFocus = () => {
      setWindowFocussed(true)
      console.log("onwindow focus");
    };

    // User has switched away from the tab (AKA tab is hidden)
    const onBlur = () => {
      setWindowFocussed(false)
      console.log("onwindow blur");
    };

    window.addEventListener("focus", onFocus);
      window.addEventListener("blur", onBlur);
      // Calls onFocus when the window first loads
      onFocus();
      // Specify how to clean up after this effect:
      return () => {
          window.removeEventListener("focus", onFocus);
          window.removeEventListener("blur", onBlur);
        };
  }, []);



  // useEffect(() => {
  //   if (initialADSet == false && agreementData){
  //     const tempIClauses = []
  //     agreementData.sections.map((item, sectionIndex) => {
  //       const tempSec = []
  //       agreementData.sections[sectionIndex].clauses.map((cItem, cIndex) => {
  //         if (cItem.important === true || (cItem.required && !(cItem.value || cItem.value == false || cItem.value?.length > 0))){
  //           tempSec.push (
  //             <Clause
  //               key={sectionIndex + ":" + cIndex + ":" + cItem.id}
  //               sectionIndex={sectionIndex}
  //               clauseIndex={cIndex}
  //               data={cItem}
  //             />
  //           )
  //         }
  //       })
  //       if (tempSec.length > 0){
  //         tempIClauses.push(
  //           <div style={{marginBottom: 120}}>
  //           <h1 className="SectionTitle" style={{marginTop:60, fontSize: 26, marginLeft: 0, textAlign: "left", color: "darkGrey"}}>{agreementData.sections[sectionIndex].title}</h1>
  //             {agreementData.sections[sectionIndex].starterText &&
  //               <p className="SectionStarterText">{agreementData.sections[sectionIndex].starterText}</p>
  //             }
  //           {tempSec}
  //         </div>
  //         )
  //       }
  //     })
  //     setIClauses(tempIClauses)
  //     setInitialADSet(true)
  //   }
  // }, []);




  const getTADetailsAsync = async(ta2) => {
    console.log("getting ta details asyncly", ta2);
    const newTA = {...ta2}
    console.log("newTA", newTA);

    let callsRemaining = 0

    function isLastCall(){
      if (callsRemaining == 0){
        console.log("no more calls", newTA);
        setAgreementData(newTA)
        setInitialAgreementData(newTA)
        setAgreementDataLoaded(true)
      }
    }

    for (var s = 0; s < ta2.sections.length; s++) {
      for (var c = 0; c < ta2.sections[s].clauses.length; c++) {
        callsRemaining += 1
        const si = s
        const ci = c
        // console.log("AS clause si:", si, " ci:",ci, " remaining: ", callsRemaining);
        // console.log("clause: ", ta.sections[s].clauses[c]);
        // const clause = await getDoc(ta.sections[s].clauses[c].ref)
        const clause = getDoc(ta2.sections[s].clauses[c].ref)
          .then(snap => {
            // console.log("AS clause si:", si, " ci:",ci, " remaining: ", callsRemaining, "    DONE");
            // console.log("full clause: ", snap.data());
            const fullClause = snap.data()
            // console.log("clause has id ", ta.sections[s].clauses[c].ref.id);
            newTA.sections[si].clauses[ci] = {...fullClause, ...newTA.sections[si].clauses[ci], ...{id: newTA.sections[si].clauses[ci].ref.id}}
            callsRemaining -= 1
            isLastCall()
          })
          .catch(error => {
            console.log("couldn't get clause: ", error);
            callsRemaining -= 1
          })
      }
    }
  }

  const uploadAgreementData = async(onSuccess, onError, start, end) => {
    console.log("TA upload: preparing", agreementData);
    const docRef = doc(db, "tenancies", tenancyId, "tenancyAgreements", taId);
    return await getDoc(docRef)
    .then(async snap => {
      if (snap.exists()) {
        const taSnapData = await snap.data()
        const TAtoFill = {...taSnapData}
        console.log("TA upload: TAtoFill ", TAtoFill);
        if (!start) start = 0
        if (!end) end = agreementData.sections.length
        // Lets get just the values from each and insert that into the cloud object
        for (var s = 0; s < agreementData.sections.length; s++) {
          if (start > s > end ){
            console.log("TA upload: skipping over section ", s);
            continue;
          }
          TAtoFill.sections[s].complete = agreementData.sections[s].complete || null
          for (var c = 0; c < agreementData.sections[s].clauses.length; c++) {
            const currentClauseValue = agreementData.sections[s].clauses[c].value
            if (true){
              // Make sure that the clause hasn't got jumbled. May be wise to change this to iterate through all in case they have
              if(agreementData.sections[s].clauses[c].id === TAtoFill.sections[s].clauses[c].ref.id){
                // If the clause has an undefined value, replace it with null so firestore doesn't cry about it
                if (currentClauseValue === undefined || currentClauseValue === null){
                  TAtoFill.sections[s].clauses[c].value = null
                } else if (currentClauseValue != null){
                  console.log("TA upload: Clause value not null",s,c, currentClauseValue);
                  TAtoFill.sections[s].clauses[c].value = currentClauseValue
                }
                // TAtoFill.sections[s].clauses[c].value = agreementData.sections[s].clauses[c].value || null
                TAtoFill.sections[s].clauses[c].hidden = agreementData.sections[s].clauses[c].hidden || null
                TAtoFill.sections[s].clauses[c].complete = agreementData.sections[s].clauses[c].complete || null
              } else {
                console.log("    TA upload: clause jumble", s,c);
              }
            }
          }
        }
        // We now have a tenancy agreement with values ready to upload
        TAtoFill.updatedAt = new Date()
        // const TAtoFill2 = {...TAtoFill}
        // for (var s = 0; s < agreementData.sections.length; s++) {
        //   for (var c = 0; c < agreementData.sections[s].clauses.length; c++) {
        //     delete TAtoFill2.sections[s].clauses[c].ref
        //   }
        // }
        console.log("TA upload: starting upload", TAtoFill);
        // console.log("TA upload 2: starting upload", TAtoFill2);
        // throw 'TA upload: fake error'
        // const docRef2 = doc(db, "tenancies", tenancyId, "tenancyAgreements", taId + "-test");
        await setDoc(docRef, TAtoFill)
        // await setDoc(docRef, TAtoFill)
        .then(snap => {
          console.log("TA upload: done", snap);
          onSuccess()
        })
        .catch(error => {
          console.log("TA upload: error", error);
          onError(error)
        })
      } else {
        console.log("TA upload: could't find ta");
        onError("couldn't find TA")
      }
    })
    .catch(error => {
      console.log("TA upload: error when getting TA to update", error);
      if (onError)onError(error)
    })

  }


  function getValueByValueReference(reference){
    // console.log("getting value by valueReference", reference);
    // N.B this is semi-duplicated in Clause.js
    try{
      for (var s = 0; s < agreementData.sections.length; s++) {
        for (var c = 0; c < agreementData.sections[s].clauses.length; c++) {
          if (agreementData.sections[s].clauses[c].valueReference == reference){
            return (agreementData.sections[s].clauses[c].value)
          }
        }
      }
    } catch {
      return null
    }
  }

  function getValueById(id){
    // N.B this is semi-duplicated in Container.js
    try {
      for (var s = 0; s < agreementData.sections.length; s++) {
        for (var c = 0; c < agreementData.sections[s].clauses.length; c++) {
          if (agreementData.sections[s].clauses[c].id == id){
            return (agreementData.sections[s].clauses[c].value)
          }
        }
      }
    } catch (e) {
      return null
    }
  }

  function checkSectionComplete(sectionIndex){
    // return ([true,[]])
    // N.B this is semi-duplicated in Container.js
    try {
      var allComplete = true
      //Commenting this out temporarily to disable validation
      const incompleteClauses = []
      for (var i = 0; i < agreementData.sections[sectionIndex].clauses.length; i++) {
        if (
          (
            agreementData.sections[sectionIndex].clauses[i].required==true
            && agreementData.sections[sectionIndex].clauses[i].type!='Plain'
            && agreementData.sections[sectionIndex].clauses[i].hidden !=true
          )
          && !(
            agreementData.sections[sectionIndex].clauses[i].selectedOption
            || agreementData.sections[sectionIndex].clauses[i].value
            || agreementData.sections[sectionIndex].clauses[i].value === false
            || agreementData.sections[sectionIndex].clauses[i].value === 0
          )
        ){
          // console.log("incomplete:", agreementData.sections[sectionIndex].clauses[i]);
          // console.log(agreementData.sections[sectionIndex].clauses[i]?.name, "incomplete", "hidden:", agreementData.sections[sectionIndex].clauses[i].hidden);
          incompleteClauses.push({id: agreementData.sections[sectionIndex].clauses[i]?.id, name: agreementData.sections[sectionIndex].clauses[i]?.name, value: agreementData.sections[sectionIndex].clauses[i]?.value})
          allComplete = false
          // console.log("all clauses not complete: ",
          //   agreementData.sections[sectionIndex].clauses[i],
          //   " : ",
          //   agreementData.sections[sectionIndex].clauses[i].selectedOption
          // );
          // break
        }
      }
      // console.log("incompleteClauses", incompleteClauses);

      if (incompleteClauses.length == 0 ){
        console.log("all clauses complete in section", sectionIndex);
        return ([true,incompleteClauses])
      } else {
        return ([false, incompleteClauses])
      }
    } catch (e) {
      console.log("error whie checking section complete", sectionIndex, e);
      return ([false, []])
    }
  }

  function onAgreementChange(){
    console.log("agreement changed");
  }

  function checkPreviousSectionsComplete(currentSectionIndex, ignoreCompleteFlag){
    var allComplete = true
    //Commenting this out temporarily to disable validation
    const incompleteSections = []
    const incompleteSectionIndexes = []
    for (var i = 0; i < currentSectionIndex; i++) {
      const [c, ics] = checkSectionComplete(i)
      // console.log("checking complete for section ", i,c, ics);

      // Check whether the section has incomplete but required clauses
      // AND it has been marked explicitly as complete (by the user submitting)
      if (c!=true || (ignoreCompleteFlag != true && agreementData.sections[i].complete != true)){
        incompleteSections.push({section: i, clauses: ics})
        incompleteSectionIndexes.push(i)
      }
    }

    if (incompleteSections.length == 0 ){
      console.log("all previous sections complete", currentSectionIndex);
      return ([true, [], 0, agreementData.sections.length])
    } else {
      const lowestIncomplete = Math.min(...incompleteSectionIndexes)
      const highestIncomplete = Math.max(...incompleteSectionIndexes)
      return ([false, incompleteSections, lowestIncomplete, highestIncomplete])
    }
  }


  function hasUserSigned(uid){

    // N.B this is semi-duplicated in Container.js
    try {
      if ( !agreementData.signatures || agreementData.signatures.length < 1){
        return false
      }

      const signatureIds = []
      for (var i = 0; i < agreementData.signatures.length; i++) {
        const sUid = agreementData.signatures[i]?.signer?.uid
        if (sUid) signatureIds.push(sUid)
      }
      // console.log("users who have signed: ", signatureIds);
      // console.log("checking whether ", uid, "is in ", signatureIds, signatureIds.includes(uid));

      if(signatureIds.includes(uid)){
        return true
      } else {
        return false
      }
    } catch (e) {
      return null
    }
  }

  function userIsLandlordOrManager(uid){
    // N.B this is semi-duplicated in Container.js
    try {
      if (userTenancyRoles.includes('manager')|| userTenancyRoles.includes('landlord')){
        return true
      } else {
        return false
      }
    } catch (e) {
      return null
    }
  }



  if (TAMissing && agreementDataLoaded){
    return (
      <Page>
        <div className="" >
          <p>We could not find this tenancy agreement</p>
        </div>
      </Page>
    )
  }

  if (accessDenied){
    return(
      <Page>
          <AccessDenied/>
      </Page>
    )
  }

  if (agreementDataLoaded){
    return (
      <AgreementDataContext.Provider
        value={{
          agreementData,
          setAgreementData,
          agreementDataLoaded,
          initialAgreementData,
          uploadAgreementData,
          getValueByValueReference,
          getValueById,
          userTenancyRoles,
          tenancy,
          checkSectionComplete,
          checkPreviousSectionsComplete,
          editable,
          hasUserSigned,
          userIsLandlordOrManager,
          onAgreementChange,
          autoUpload,
          setAutoUpload,
        }}>
        <Page hideBackNav={false} showBreadcrumbs={false} onBackClick={() => { navigate("/tenancies/" + tenancyId, { replace: true }) }}>
          {/* <FloatingAgreementNav/> */}
          <Outlet />
        </Page>
      </AgreementDataContext.Provider>
    )
  }

  return(
    <Page center>
      <div className="" >
      </div>
    </Page>
  )

}
