import React, {useState, useEffect} from "react";

import "../css/properties.css"
import "../css/tenancies.css"

import {
  Navigate,
    Link,
    useParams,
    useNavigate,
    useLocation,
    Outlet,
    useOutletContext,
} from 'react-router-dom';


import PageBlue from "../components/PageBlue.js"
import PageWhite from "../components/PageWhite.js"
import NavBar2 from "../components/NavBar2.js"
import Footer from "../components/Footer.js"
import SquareButton from "../components/SquareButton.js"
import OverviewBox from "../components/tenancy/OverviewBox.js"
import ComplianceBox from "../components/tenancy/ComplianceBox.js"
import DatesBox from "../components/tenancy/DatesBox.js"
import TenantsBox from "../components/tenancy/TenantsBox.js"
import DepositBox from "../components/tenancy/DepositBox.js"
import RentBox from "../components/tenancy/RentBox.js"

import {parseDateObject, tenancyTense} from "../utility/dates"

import { getFirestore, doc, getDocs, getDoc, addDoc, collection, collectionGroup,  query, limit, orderBy, onSnapshot, docRef, where, orderByChild } from "firebase/firestore";
import { useAuth, } from "../contexts/AuthContext"

const db = getFirestore();


export default function Tenancies({}){
  const {currentUser} = useAuth()
  const {tenancyId} = useParams()
  const navigate = useNavigate();
  const [makingAgreement, setMakingAgreement] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [tenancy, setTenancy] = useState({});
  const [taID, setTaID] = useState();

  useEffect(() => {
    if (tenancy?.propertyData?.line_1){
      document.title = tenancy?.propertyData?.line_1 + " - 10NC"
    } else {
      document.title = "Tenancy overview"
    }
  }, [tenancy?.propertyData?.line_1]);


  //This should be moved to tenancy container (maybe not as this isn't within) or somewhere similar
  async function makeAgreement(){

    setMakingAgreement(true)
    const reqData = {
      templateId: "qt8TBkeADCcfe2kz5anf",
      importantOnly: true,
      tenancyId: tenancyId,
    }
    console.log("Creating TA with data", reqData);
    const token = await currentUser.getIdToken()

    fetch('https://europe-west2-nc-e9104.cloudfunctions.net/create-ta-from-template-1', {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(reqData)
    })
      .then(response => response.json())
      .then(data => {
        console.log("got response", data);
        // navigate("/tenancies/" + data.tenancy_id + "/agreements/" + data.tenancy_agreement_id )
        navigate("/tenancies/" + data.tenancy_id + "/agreements/" + data.tenancy_agreement_id + "/important")
        setMakingAgreement(false)
      })
      .catch(error => {
        console.log("error:", error);
        // setError({type:"warn", message: "Oops, we're fixing this as we speak. Check back in a few hours."})
        setMakingAgreement(false)
      })
  }

  async function getTAs(){
    console.log("getting TAs");
    const colRef = collection(db, "tenancies", tenancyId, "tenancyAgreements")
    console.log("got colref");
    const colSnap = await getDocs(colRef)
    .then(snap => {
      const docs = snap.docs
      // const ids = docs.map(x => x.id);
      // console.log("ids: ", ids);
      if (docs.length >0){
        setTaID(docs[0].id)
        console.log("taid", docs[0].id);
        // doc.data()
      } else {
        console.log("no TA");
        setTaID()
      }
      // setLoaded(true)

    })
    .catch(error => {
      console.log("error" , error);
      // setLoaded(true)
    });
  }


  async function getTenancy(){
    setLoaded(false)
    console.log("getting T ", tenancyId);
    const docRef = doc(db, "tenancies", tenancyId);
    const docSnap = await getDoc(docRef)
    .then(doc => {
      if (doc.exists()){
        console.log("exists");
      } else {
        console.log("no t doc");
      }
      console.log("then ");
      // const docs = snap.docs
      const tData = doc.data()
      console.log("t data", tData);
      setTenancy(tData)
      setLoaded(true)

    })
    .catch(error => {
      console.log("error" , error);
      // alert(error)
      setLoaded(true)
    });
  }

  // useEffect(() => {
  //   getTenancy()
  //   getTAs()
  // }, []);

  // Get tenancy
  useEffect(() => {
    const unsub = onSnapshot(doc(db, "tenancies", tenancyId),
      (doc) => {
        const tData = doc.data()
        console.log("t data", tData);
        setTenancy(tData)
        setLoaded(true)
      },
      (error) => {
        console.log("error" , error);
        setLoaded(true)
      },
  );
    return unsub
  }, []);

  // Get tenancy agreements
  useEffect(() => {
    const colRef = collection(db, "tenancies", tenancyId, "tenancyAgreements")
    const unsubscribe = onSnapshot(colRef,
      (snap) => {
        const docs = snap.docs
        if (docs.length >0){
          setTaID(docs[0].id)
          console.log("taid", docs[0].id);
        } else {
          console.log("no TA");
          setTaID()
        }
      },
      (error) => {
        console.log("error" , error);
      }
  );
  }, []);



  if (!currentUser){
    navigate("/login" + "?redirect=" + encodeURIComponent(window.location.pathname), { replace: true })
    return
  }

  if (!loaded){
    return
  }

  return (
    <PageWhite showBreadcrumbs>

      <h1 className="PageTitle">Tenancy Overview</h1>
      <div className="Flex Column AlignCenter" style={{padding: 0, marginTop: 15}}>
        <div className="Flex Column AlignCenter" >
          <div className="" style={{position: "relative", width: 320, minHeight :275, marginBottom: 30, overflow: 'hidden'}}>
            <img src="/tenancy-home-main-image.svg" style={{width: "100%"}}/>
            <div className="TextOverlay CenterText Bottom" >
              <p className="" style={{fontFamily: "Bebas", color: "white", fontSize: 38, fontWeight: "lighter"}}>{tenancy.propertyData.line_1}</p>
              <p className="" style={{fontFamily: "Lato", color: "white", fontSize: 17, fontWeight: "lighter", marginBottom: 30}}>Setup your new tenancy</p>
            </div>
          </div>
        </div>
        <div className="Flex Column">
          <h2 className="TenancyOverviewSectionTitle"> Getting Started</h2>
          <div className="OverviewGrid">
          {/*
            <OverviewBox number={1} title="Set Dates">
              <div className="Flex Column AlignStart JustifyStart W100 PT20" >
              <p style={{color: "#A6ACB2", whiteSpace: "noWrap", fontSize: 18}}>The tenancy will start on</p>

              <p style={{color: "#A6ACB2", whiteSpace: "noWrap", fontSize: 18}}>The tenancy will end on</p>
              </div>
            </OverviewBox>
          */}

              <DatesBox tenancyId={tenancyId} start={tenancy?.dates?.start?.at?.seconds} end={tenancy?.dates?.end?.at?.seconds} dates={tenancy.dates}/>
              <RentBox rent={tenancy.rent} tenancyId={tenancyId} tenancy={tenancy}/>
              <DepositBox tenancyId={tenancyId} tenancy={tenancy}/>


              <TenantsBox tenancy={tenancy} tenancyId={tenancyId} />
              {/* <OverviewBox title="Landlord"/>
              <OverviewBox title="References"/>
              <OverviewBox title="Plan"/> */}


            <OverviewBox title="Tenancy Agreement" style={{maxHeight: 240}}>
              <div className="Flex Column AlignCenter JustifySpaceAround H100pc" >
                <div className="Flex Column AlignCenter JustifySpaceAround H100pc" style={{display: "flex", flex: "1 0", maxHeight: 150}}>
                  <div className="PT20" style={{display: "flex", flex: 1}}/>
                  <div className="" style={{display: "flex"}}>
                    {taID &&
                      <button
                        className="Yellow Square H50"
                        onClick={() => {
                          window.analytics.track("edit_agreement.click", { tenancyID:  tenancyId});

                          // navigate("/tenancies/" + tenancyId + "/agreements/" + taID)
                          navigate("/tenancies/" + tenancyId + "/agreements/" + taID + "/important")
                        }}
                      >
                        Edit Agreement
                      </button>
                    }
                    {!taID &&
                      <button
                        className="Yellow Square H50"
                        disabled={makingAgreement}
                        onClick={() => {
                          window.analytics.track("create_agreement.click", { tenancyID:  tenancyId});
                          makeAgreement()
                        }}
                      >Create Agreement</button>
                    }
                  </div>

                  <div style={{display: "flex", flex: "1 0"}}>
                    <p className="CenterText PT20">Get the paperwork sorted & signed to finalise the tenancy</p>
                  </div>
                </div>
              </div>
            </OverviewBox>


          <ComplianceBox value={tenancy.complianceChecklist} tenancyId={tenancyId} onChange={() => {}}/>

          </div>
        </div>
      </div>
    </PageWhite>
  )
}
