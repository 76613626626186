import React from "react"
import axios from "axios"
// import ClauseOption from "./ClauseOption"
import { useState, createContext, useContext, useRef, useEffect } from "react";

import MultiLineP from "../../MultiLineP.js"
import MultiLineInput from "../../inputs/MultiLineInput.js"

import {AgreementDataContext} from "../../../screens/tenancyAgreements/Container.js"

function TextInput({text, style, icon, iconSlug, sectionIndex, clauseIndex}){
  const {editable:agreementEditable, agreementData, setAgreementData} = useContext(AgreementDataContext);
  const [expanded, setExpanded] = React.useState(false);
  const [data, setData] = React.useState(agreementData.sections[sectionIndex].clauses[clauseIndex]);
  const [errorMessage, setErrorMessage] = React.useState("")
  const [value, setValue] = useState(agreementData.sections[sectionIndex].clauses[clauseIndex].value || "");
  // const [valid, setValid] = useState(false);
  const [height, setHeight] = useState(30);
  const inputRef = useRef();

  useEffect(() => {
    setHeight(inputRef.current.scrollHeight + 5)
    // console.log("agreementEditable:", agreementEditable);
  }, []);


  function handleClick(event){
    if (data.description){
      setExpanded(!expanded)
      event.preventDefault()
    }
  }

  //
  if (data?.hidden === true){
    return (<></>)
  }

  function ClauseError(message){
    return (
      <div className="ClauseErrorBox">
        <img src="/error.svg" className="ClauseErrorIcon"/>
        <p className="ClauseErrorText">{message}</p>
      </div>
    )
  }

  //
  return (
    <div className={"Clause" + (expanded ? " expanded": "")} onClick={handleClick} key={data.id} id={"clause-" + data.id} id={data.id}>
      <div className="ClauseInner">
        <div className="clauseIcon">
          <img src={"/clause-image/" + data.iconSlug}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src="/clause-image/placeholder.svg";
            }}
            className="ClauseIcon"
            alt={text}
          />
        </div>
        <div className="ClauseTextContainer">
          <p className={"ClauseText"} onClick={() => {inputRef.current.focus()}}>
            {data?.text?.pre}
          </p>
          <textarea
            className={"ClauseTextInputArea" + (data?.indent? " indent" : "") + (data?.underline? " underline" : "") + (data?.bottomLine? " bottomLine" : "") + (data?.noBottomLine? " noBottomLine" : "") + (data?.editable==false? " not-editable" : "")}
            disabled={!agreementEditable}
            type="textarea"
            placeholder={data?.text?.placeholder.replaceAll("\\n", '\n')}
            value={value}
            ref={inputRef}
            style={{...style, height, ...{maxWidth: 500}}}
            onClick={(e)=> {
              console.log("clicked on the input");
              e.stopPropagation();
            }}
            onInput={() => {
              // console.log("offset height: ", inputRef.current.offsetHeight);
              setHeight(inputRef.current.scrollHeight)
            }}
            onChange={(v) => {
              if (!agreementEditable) return
              // if (agreementEditable == false) return
              // console.log("offset height2: ", inputRef.current.offsetHeight);
              setValue(v.target.value)
              const newAD = {...agreementData}
              newAD.sections[sectionIndex].clauses[clauseIndex].value = v.target.value
              if (v.target.value){
                // Set this field as complete
                newAD.sections[sectionIndex].clauses[clauseIndex].complete = true
                setAgreementData(newAD)
                // setAgreementData({...agreementData, testupdate : true})
              } else {
                // Set this field as not complete
                newAD.sections[sectionIndex].clauses[clauseIndex].complete = false
                setAgreementData(newAD)
              }
            }}
          />
          <p className={"ClauseText" } onClick={() => {inputRef.current.focus()}}>
            {data?.text?.post}
          </p>
        </div>
      </div>
      {expanded &&
        <div className="ClauseExpansion">
          <MultiLineP className="ClauseDescription">{data.description}</MultiLineP>
        </div>
      }
      {errorMessage && <ClauseError message={errorMessage}/>}
    </div>

  )

}

export default TextInput;
