import React from "react";

import NavBar from "./NavBar.js"
import NavBar2 from "./NavBar2.js"
import Footer from "./Footer.js"
import Breadcrumbs from "./Breadcrumbs.js"

import {Route,  Navigate, useNavigate } from 'react-router-dom'
import {useAuth} from '../contexts/AuthContext'

function Page({hideBack, publicRoute, noPadding, children, style, header, center, footer, showBreadcrumbs}){
  const {currentUser} = useAuth()
  const navigate = useNavigate()


  if (!publicRoute && !currentUser) {
    console.log("this page is not a public route and we don't have a current user");
    // navigate("/login" + "?redirect=" + encodeURIComponent(window.location.pathname), { replace: true })
    return (<Navigate to={"/login" + "?redirect=" + encodeURIComponent(window.location.pathname)} replace/>)
    return
  }

  const extraStyles = {}
  if(center) extraStyles.alignItems = 'center'
  if(center===false) extraStyles.alignItems = 'flex-start'

  // extraStyles.alignItems = center


  return (
    <div
      className="Page3 White"
    >
    <div className="PageTop" name="PageTop" >
      {!(header === false) && <NavBar2 hideBack={!!hideBack} transparent={false} showBreadcrumbs={showBreadcrumbs} publicLogo={currentUser && !currentUser.isManager}/>}

      <div className="PadH30" >
          {children}
      </div>
    </div>

      <Footer white/>
    </div>
  )
}

export default Page
