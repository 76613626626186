import React, {useState, useEffect, forwardRef, useImperativeHandle} from "react";
import { fadeIn } from 'react-animations'
import validator from 'validator'

import "../../../css/animations.css"


import { BiError } from "react-icons/bi";

import ExpandingInput from "../../ExpandingInput.js"
import ErrorTriangle from "../../ErrorTriangle.js"

function Item(props, ref){
  const {item, key, index, colour, onChange, numbered, lettered, onClick, editable, handleItemDelete} = {...props}
  const [expanded, setExpanded] = useState(false);
  const [itemError, setItemError] = useState();

  // console.log("onclick is", onClick);

  const listItemPlaceholder = "/list-person-icon.svg"

  useEffect(() => {
    if (Object.keys(item).length === 0) {
      console.log("no item");
      setExpanded(true)
    } else {
      console.log("has item", item);
    }
  }, [item]);


  let name = [item?.name?.first, item?.name?.last].join(" ")
  if (!name || name ==" "){
    if (item.email){
      name = item.email
    } else {
      name = "Tenant " + (index + 1)
    }
  }

  function expand(){
    setExpanded(true)
  }


  function shrink(){
    if (expanded){
      setExpanded(false)
      if (!(item.email || item.mobile)){
        setItemError(true)
        return
      }
      if (item.email && validator.isEmail(item.email)!=true){
        setItemError(true)
        return
      }
      setItemError(false)
    }
  }

  function expandOrShrink(){
    if (expanded){
      shrink()
    } else {
      expand()
    }
  }

  useImperativeHandle(ref, () => ({
    expanded: expanded,
    expand: () => {
      console.log("running expand");
      expand()
    },
    shrink: () => {
      shrink()
    }
  }));

  return (
    <li className="SelectedAreaRow" key={index}
      onClick={(e) => {
        expandOrShrink()
        if (onClick) onClick()
        e.stopPropagation()
      }}
      onBlur={(e) => {
        console.log("item blurred", name);
      }}
    >
      <div className="PeopleRowLeft">
      {numbered && <p className="ClauseListNumber">{index + 1}</p>}
      {lettered && <p className="ClauseListNumber">{(index + 10).toString(36).toUpperCase()}</p>}
      <img
        src={listItemPlaceholder}
        className="PeopleListItemIcon"
        style={{backgroundColor: colour, borderRadius: 100, alignSelf: 'flexStart'}}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src="/list-item-icon.svg";
        }}
      />

      <div className="" style={{display:"table", width: "auto", verticalAlign: "top"}}>
        {!expanded && <p style={{overflowWrap: "anywhere", margin: 0}}>{name}</p>}
        {expanded &&
          <div style={{display: expanded ? "block" : "none"}} onClick={e => {e.stopPropagation()}}>
          <div className="MultiUserFormRow" >
            <p className="FormLabelUpper">First name</p>
            <ExpandingInput
              type="text"
              style={{marginBottom: 10}}
              minWidth={30}
              placeholder="Tenant's first name"
              value={item?.name?.first}
              extraWidth={15}
              className={"ClauseTextInput" + (editable==false? " not-editable" : "")}
              disabled={editable === false}
              onChange={(v) => {
                const newVal = {
                  ...item,
                  name: {
                    ...item.name,
                    first: v.target.value
                  }
                }
                onChange(newVal)
              }}
            />
          </div>
          <div className="MultiUserFormRow" >
            <p className="FormLabelUpper">Last name</p>
            <ExpandingInput
              type="text"
              style={{marginBottom: 10}}
              minWidth={30}
              value={item?.name?.last}
              placeholder="Tenant's last name"
              value={item?.name?.last}
              extraWidth={15}
              className={"ClauseTextInput" + (editable==false? " not-editable" : "")}
              disabled={editable === false}
              onChange={(v) => {
                const newVal = {
                  ...item,
                  name: {
                    ...item.name,
                    last: v.target.value
                  }
                }
                onChange(newVal)
              }}
            />
          </div>
          <div className="MultiUserFormRow"  >
            <p className="FormLabelUpper">Email</p>
            <ExpandingInput
              type="text"
              style={{marginBottom: 10}}
              minWidth={30}
              value={item?.email}
              placeholder="tenants@email.com"
              extraWidth={15}
              className={"ClauseTextInput" + (editable==false? " not-editable" : "")}
              disabled={editable === false}
              onChange={(v) => {
                const newVal = {
                  ...item,
                  email: v.target.value
                }
                onChange(newVal)
              }}
              validate={(v) => {
                return (validator.isEmail(v))
              }}
            />
          </div>
          <div className="MultiUserFormRow" >
            <p className="FormLabelUpper">Mobile number</p>
            <ExpandingInput
              type="phone"
              style={{marginBottom: 10}}
              minWidth={30}
              value={item?.mobile}
              placeholder="+44 07712345678"
              extraWidth={15}
              className={"ClauseTextInput" + (editable==false? " not-editable" : "")}
              disabled={editable === false}
              onChange={(v) => {
                const newVal = {
                  ...item,
                  mobile: v.target.value
                }
                onChange(newVal)
              }}
              validate={(v) => {
                return (validator.isMobilePhone(v))
              }}
            />
          </div>
        </div>
      }
      </div>

      <ErrorTriangle show={itemError=== true && !expanded} width={20} height={20} style={{marginLeft: 10}}/>
    </div>
    {editable!= false &&
      <div className="SelectedAreaRowRight" onClick={(e) => {
        handleItemDelete(index)
        e.stopPropagation()
      }}>
        <img src="/delete.svg" className="DeleteIcon"/>
      </div>
    }
  </li>
  )
}

Item = forwardRef(Item)

export default Item
