import React, {useState, useEffect} from "react";
import "../css/animations.css"

import { BiError } from "react-icons/bi";


export default function ErrorTriangle({show, pulse, color, width, height, style}){
  const [animate, setAnimate] = useState(true);
  const [showHelp, setShowHelp] = useState(false);

  function reAnimate(){
    setAnimate(false)
    setAnimate(true)
  }

  if (show!= false){
    return (
      <div style={{position: 'relative'}} >
        <BiError
          className={animate?"ErrorAlertShake":""}
          color={color || "#D66E6E"}
          style={{...{height: height || 20, width: width || 20, position: "relative"}, ...style}}
          onClick={() => {
            // setShowHelp(true)

          }}
        />
        {showHelp &&
          <div style={{position: "absolute", bottom: 0, backgroundColor: "#D66E6E", color: "white"}} >
            <p>This is an error</p>
          </div>
        }
        </div>
    )
  } else return

}
