import React from "react"
import axios from "axios"
// import ClauseOption from "./ClauseOption"
import { useState, createContext, useContext, useRef, useEffect } from "react";

import ExpandingInput from "../../ExpandingInput.js"
import ErrorBox from "./ErrorBox.js"
import MultiLineP from "../../MultiLineP.js"
import SimpleSelect from "../../inputs/SimpleSelect.js"

import {nthDay} from "../../../utility/dates.js"

import {AgreementDataContext} from "../../../screens/tenancyAgreements/Container.js"

function Rent({text, icon, iconSlug, sectionIndex, clauseIndex}){
  const {agreementData, setAgreementData, editable:agreementEditable} = useContext(AgreementDataContext);
  const [expanded, setExpanded] = React.useState(false);
  const [data, setData] = React.useState(agreementData.sections[sectionIndex].clauses[clauseIndex]);
  const [errorMessage, setErrorMessage] = React.useState("")
  const [value, setValue] = useState(agreementData.sections[sectionIndex].clauses[clauseIndex].value || {period: "month"});
  const [rentAmount, setRentAmount] = useState();
  const [rentDay, setRentDay] = useState(1);
  const [rentPeriod, setRentPeriod] = useState("month");
  // const [valid, setValid] = useState(false);
  const [showConditional, setShowConditional] = useState(false);
  const [conditionalText, setConditionalText] = useState();
  const hackSpan = useRef();
  const inputRef = useRef();

  useEffect(() => {
    const newAD = {...agreementData};
    newAD.sections[sectionIndex].clauses[clauseIndex].value = value
    if (value && value.amount && value.day && value.period){
      // Set this field as complete
      newAD.sections[sectionIndex].clauses[clauseIndex].complete = true
      setAgreementData(newAD)
      // setAgreementData({...agreementData, testupdate : true})
    } else {
      // Set this field as not complete
      newAD.sections[sectionIndex].clauses[clauseIndex].complete = false
      setAgreementData(newAD)
    }
  }, [value]);


  function handleClick(event){
    if (data.description){
      setExpanded(!expanded)
      event.preventDefault()
    }
  }

  if (data?.hidden === true){
    return (<></>)
  }

  // return(
  //   <p>RENT</p>
  // )

  return (
    <div className={"Clause" + (expanded ? " expanded": "")}  onClick={handleClick} id={data.id}>
      <div className="ClauseInner">
        <div className="clauseIcon">
          <img src={"/clause-image/" + data.iconSlug}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src="/clause-image/placeholder.svg";
            }}
            className="ClauseIcon"
            alt={text}
          />
        </div>
        <div className="ClauseTextContainer">
          {conditionalText}
          {!showConditional &&
          <p className={"ClauseText" + (value && (value.amount && value.day && value.period) ? "" :" placeholder")} onClick={() => {}}>
            Rent of £
            <span >
              <ExpandingInput
                type="number"
                min={data.min}
                max={data.max}
                minWidth={30}
                extraWidth={15}
                className={"ClauseTextInput currency" + (data?.editable==false? " not-editable" : "")}
                value={value.amount}
                disabled={!agreementEditable}
                onChange={(v) => {
                  if (!agreementEditable) return
                  console.log("amount changed so new v is ", {...value, ...{amount: v.target.value}});
                  setValue({...value, ...{amount: v.target.value}})
                  setRentAmount(v.target.value)
                  // const newAD = {...agreementData};
                  // newAD.sections[sectionIndex].clauses[clauseIndex].value = v.target.value
                  // if (v.target.value){
                  //   // Set this field as complete
                  //   newAD.sections[sectionIndex].clauses[clauseIndex].complete = true
                  //   setAgreementData(newAD)
                  //   // setAgreementData({...agreementData, testupdate : true})
                  // } else {
                  //   // Set this field as not complete
                  //   newAD.sections[sectionIndex].clauses[clauseIndex].complete = false
                  //   setAgreementData(newAD)
                  // }
                }}
              />
            </span>
            will be due on the
            <span >
              <ExpandingInput
                type="number"
                min={1}
                max={365}
                minWidth={0}
                extraWidth={0}
                className={"ClauseTextInput right" + (data?.editable==false? " not-editable" : "")}
                value={value.day}
                disabled={!agreementEditable}
                onChange={(v) => {
                  if (!agreementEditable) return
                  // setValue(v.target.value)
                  setValue({...value, ...{day: v.target.value}})
                }}
              />
            </span>
            {nthDay(value.day)} day of each
            <span>
            <SimpleSelect
              value={value.period}
              disabled={!agreementEditable}
              onChange={(v) => {
                if (!agreementEditable) return
                setValue({...value, ...{period: v.target.value}})
              }}
              options={[
                {text:"Week", value: "week"},
                {text:"Month", value: "month"},
                {text:"Year", value: "year"},
              ]}
            />
            </span>
          </p>
        }
        </div>
      </div>
      {expanded &&
        <div className="ClauseExpansion">
          <MultiLineP className="ClauseDescription">{data.description}</MultiLineP>
        </div>
      }
      {errorMessage && <ErrorBox message={errorMessage}/>}
    </div>

  )

}

export default Rent;
