import React from "react";
import { useState, createContext, useContext, useEffect } from "react";

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useParams,
    useNavigate,
    useLocation,
} from 'react-router-dom';

import {AgreementDataContext} from "../../screens/tenancyAgreements/Container.js"
import BackButton from "../BackButton.js"
import { useAuth, } from "../../contexts/AuthContext"


function ShareTA({}){
  const {
    tenancy,
    agreementData,
    setAgreementData,
    getValueByValueReference,
    userTenancyRoles,
    checkPreviousSectionsComplete,
    hasUserSigned,
    userIsLandlordOrManager,
    setAutoUpload,
  } = useContext(AgreementDataContext);
  const { tenancyId, taId, sectionSlug } = useParams()
  const {currentUser} = useAuth()

  const navigate = useNavigate();
  const location = useLocation()
  const [signingUsers, setSigningUsers] = useState([]);

  useEffect(() => {
    document.title = "Agreement status"
  }, []);




  useEffect(() => {
    function validateStatus(){

      if(['inTenantSigning', 'testMode'].includes(agreementData.status)){
        console.log("skipping validation due to status");
        setAutoUpload(false)
      } else {
        const [complete, is, lis, his] = checkPreviousSectionsComplete(agreementData.sections.length, true)
        if (complete!=true){
          if (userIsLandlordOrManager(currentUser.uid)){
            // The user is a ll/manager so they must go back and edit
            navigate("/tenancies/" + tenancyId + "/agreements/" + taId + "/s/" + agreementData.sections[lis].slug + location.search)
            return
          }
        }
      }

      // now do signing validation
      if (hasUserSigned(currentUser.uid)){
        console.log("this user has signed the agreement", currentUser.uid);
        // If they are a manager but the tenancy has no plan, redirect
        if (userIsLandlordOrManager(currentUser.uid) && tenancy.managementPlan?.active != true){
          console.log("no managementPlan so navigating");
          navigate("/tenancies/" + tenancyId + "/agreements/" + taId + "/select-plan")
        }

      } else {
        console.log("this user has not yet signed the agreement", currentUser.uid, agreementData.signatures);
        // If the user hasn't signed and they're either
          // a manager or
          // a tenant and it's their time to sign
        // redirect to signing
        if (userIsLandlordOrManager(currentUser.uid) || ['inTenantSigning'].includes(agreementData.status)){
          console.log("navigating to signing");
          navigate("/tenancies/" + tenancyId + "/agreements/" + taId + "/sign")
        }
      }
    }

    validateStatus()

  }, [tenancy.managementPlan]);

  useEffect(() => {
    let users = []
    const tenants = getValueByValueReference('tenants')
    if (tenants){
      tenants.forEach( u => {
        users.push({...u, ...{role: 'tenant', status: 'signed'}})
      })
    }
    console.log("tenants",tenants);

    // N.B this is commented out while we decide how to do landlord signing

    // const landlords = getValueByValueReference('landlords')
    // if (landlords){
    //   landlords.forEach( u => {
    //     users.push({...u, ...{role: 'landlord', status: 'signed'}})
    //   })
    // }
    // console.log("landlords",landlords);

    console.log("signing users", users);
    users.map((item, index) => {
      console.log("    got a user", item);
    })
    setSigningUsers(users)
  }, []);

  return (
    <div
      className="AgreementFinalisePage"
    >
      <div className="SectionHeaderAll" >
        <div style={{float: 'left', position: 'absolute'}} >
          <BackButton onClick={() => {
            navigate(-1)
            try {
                navigate(location.pathname.split('/').slice(0,-1).join('/') + '/s/' + agreementData.sections[agreementData.sections.length-1].slug + location.search)
                window.scrollTo(0, 0)
            } catch (e) {
              console.log("defaulting back in browser");
              navigate(-1)
            }
          }}/>
        </div>
      </div>
      <h1 className="AgreementMakerH1" style={{marginTop: 0, marginBottom: 10}}>{"Nearly there!"}</h1>
      <h2 className="AgreementMakerH2" style={{marginTop: 0, marginBottom: 20}}>We're waiting on some signatures</h2>
      {/* <h2 className="AgreementMakerH2" style={{marginTop: 0, marginBottom: 20}}>N.B once it's been shared, the agreement can no longer be edited</h2> */}

      {/*
      <div className="TASignaturesStatusListBox" style={{marginTop: 30}}>
        <ul className="TASignaturesStatusList">
          {signingUsers.map((item, index) => (
            <li className={"TASignaturesStatusListItem " + item.status}><span><span className="TASigsListName">{item.name || item.email}</span><span className="TASigsListRole"> - {item.role}</span></span><div className={"TASigsListStatusBadge " + item.status}>{item.status || "not signed"}</div></li>
          ))
          }
        </ul>
      </div>
      */}

      {/*
      <div className="TAShareBox" >
        <p style={{fontSize: 18, marginBottom: 0}}>Share this link with the tenants</p>
        <p style={{marginTop: 5, marginBottom: 25}}>They will need to use the email address you stated for them</p>
        <input className="ShareLinkInputBox" type="" name="" value="https://manager.10nc.app/ta/345hargdgfjhdfsg8e349" disabled/>
      </div>
      */}

      <div className="TAShareBox" >
        <p style={{fontSize: 18, marginBottom: 0}}>We have emailed all tenants</p>
        <p style={{marginTop: 5, marginBottom: 25}}>Make sure to check spam and look for emails from '10nc.app'</p>
      </div>
    </div>
  )
}

export default ShareTA
