import React from "react"
import axios from "axios"
import { useState, createContext, useContext, useEffect } from "react";

import ExpandingInput from "../../ExpandingInput.js"
import ClauseMainText from "./ClauseMainText.js"
import MultiLineP from "../../MultiLineP.js"

import {AgreementDataContext} from "../../../screens/tenancyAgreements/Container.js"


function AreaSelector({text, icon, iconSlug,sectionIndex, clauseIndex}){
  const {agreementData, setAgreementData, editable:agreementEditable} = useContext(AgreementDataContext);
  const [expanded, setExpanded] = React.useState(false);
  const [data, setData] = React.useState(agreementData.sections[sectionIndex].clauses[clauseIndex]);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [value, setValue] = useState(agreementData.sections[sectionIndex].clauses[clauseIndex].value || []);

  useEffect(() => {
    console.log("area value is", value);
    if(!value){
      setValue([
        {text: "The whole property", type: ""},
        {text: "The kitchen", type: "kitchen"},
        {text: "The bathroom", type: "bathroom"},
        {text: "The bedroom", type: "bedroom"},
      ])
    }
  }, []);


  useEffect(() => {
    const areaTypeMap = {
      "toilet" : "bathroom",
      "loo" : "bathroom",
      "wc" : "bathroom",
      "bath" : "bathroom",
      "kitchen" : "kitchen",
      "test" : "bedroom",
      "bedroom" : "bedroom",
      "bed" : "bedroom",
      "study" : "study",
      "bunk" : "bedroom",
      "whole property" : "whole-property",
      "whole flat" : "whole-property",
      "whole house" : "whole-property",
      "whole" : "whole-property",
      "garden" : "garden",
      "parking" : "parking",
      "drive" : "parking",
      "house" : "house",
    }
    for (var i = 0; i < value.length; i++) {
      var matched = false
      for (var key in areaTypeMap){
        if(value[i].text.toLowerCase().includes(key)){
          value[i].type = areaTypeMap[key]
          matched = true
        }
      }
      if (!matched) value[i].type = "custom"
    }
    setAgreementData(() => {
      const newAD = {...agreementData};
      newAD.sections[sectionIndex].clauses[clauseIndex].value = value
      return (newAD)
    })

  }, [value]);


  if (data?.hidden === true){
    return (<></>)
  }


  function ClauseError({message}){
    return (
      <div className="ClauseErrorBox">
        <img src="/error.svg" className="ClauseErrorIcon"/>
        <p className="ClauseErrorText">{message}</p>
      </div>
    )
  }

  function handleItemDelete(i){
    console.log("clicked on ", text);
    setValue(() => (value.slice(0,i).concat(value.slice(i+1, value.length))))
  }

  function handleItemAdd(){
    if (!agreementEditable) return
    console.log("clicked on ", text);
    setValue([...value, {text:"", type: ""}])
  }

  function handleClick(event){
    if (data.description){
      setExpanded(!expanded)
      event.preventDefault()
    }
  }

  return (
    <div className={"Clause" + (expanded ? " expanded": "")} id={data.id}>
      <div className="ClauseInner" onClick={handleClick}>
        <div className="clauseIcon">
          <img src={"/clause-image/" + data.iconSlug}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src="/clause-image/placeholder.svg";
            }}
            className="ClauseIcon"
            alt={text}
          />
        </div>
        <div className="ClauseTextContainer">
          <ClauseMainText>
            {data?.text?.pre}
          </ClauseMainText>
        </div>

      </div>
      <div className="SelectedAreas" onClick={(e) => e.stopPropagation()}>
        {value.map((item, index) => (
          <div className="SelectedAreaRow" key={index} >
            <div className="SelectedAreaRowLeft">
            <img
              src={"/clause-image/area-icons/" + item.type + ".svg"}
              className="SelectedAreaIcon"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src="/clause-image/area-icons/placeholder.svg";
              }}
            />
            <ExpandingInput
              className="ClauseTextInput"
              value={item.text}
              style={{marginLeft: 10, marginRight: 10}}
              placeholder="describe the area"
              disabled={!agreementEditable}
              onChange={(v) => {
                if (!agreementEditable) return
                const nV = [...value]
                nV[index].text = v.target.value
                setValue(nV)
              }}
            />

          </div>
          {agreementEditable &&
            <div className="SelectedAreaRowRight" onClick={() => {handleItemDelete(index)}}>
              <img src="/delete.svg" className="DeleteIcon"/>
            </div>
          }
        </div>
          ))}
      </div>
      {agreementEditable &&
        <div className="SelectedAreasAddButton" onClick={handleItemAdd}>
          <img src="/add-icon-grey.svg" className="SelectedAreaIcon"/>
        </div>
      }
      <p className="ClauseText" style={{marginLeft: 82}}>
        {data?.text?.post}
      </p>
      {expanded &&
        <div className="ClauseExpansion">
          <MultiLineP className="ClauseDescription">{data.description}</MultiLineP>
        </div>
      }
      {errorMessage && <ClauseError message={errorMessage}/>}
    </div>
  )

}

export default AreaSelector;
