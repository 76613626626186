import React from "react";
import { useState, createContext, useContext, useEffect } from "react";

import Clause from "../../components/tenancyAgreements/Clause.js"

import {AgreementDataContext} from "../../screens/tenancyAgreements/Container.js"

function SectionClauses({ sectionIndex, importantOnly}){
  const {agreementData, setAgreementData, agreementDataLoaded, initialAgreementData} = useContext(AgreementDataContext);

  useEffect(() => {
    // console.log("Section clauses running with SI", sectionIndex);
    // console.log("clauses should be", agreementData.sections[sectionIndex].clauses);
    // console.log("initialAgreementData ", initialAgreementData);
  }, []);



  return (
    <div className="Section">
      <div className="SectionClauses">
        {agreementData.sections[sectionIndex].clauses.map((item, index) => {
          // return (<p>{item.name} : {JSON.stringify(item.value)}</p>)
          // if (!item.value){
          //   return (<p>no value clause</p>)
          // }
          // if (item.important){
          //   return (<p>important clause</p>)
          // }
          // if (!item.id){
          //   return (<p>noid clause</p>)
          // }

          // if (['list, text', 'number', 'currency', 'rent'].includes(item.type.toLowerCase())){
          //   return <p>bad type</p>
          // }
          // return (
          //   <p>{item.type.toLowerCase()}</p>
          // )

          // return <p>clause</p>
            return (
              <Clause
                key={sectionIndex + ":" + index + ":" + item.id}
                sectionIndex={sectionIndex}
                clauseIndex={index}
                data={item}
              />
            )

      })}

      </div>
    </div>
  )

  return (
    <div className="Section">
      <div className="SectionClauses">
        {agreementData.sections[sectionIndex].clauses.map((item, index) => {
            return (
              <Clause
                key={sectionIndex + ":" + index + ":" + item.id}
                sectionIndex={sectionIndex}
                clauseIndex={index}
                data={item}
              />
            )

      })}

      </div>
    </div>
  )

  return (
    <div className="Section">
      <div className="SectionClauses">
        {agreementData.sections[sectionIndex].clauses.map((item, index) => {
          let initialValue = null
          if (initialAgreementData){
            try{
              initialValue = initialAgreementData.sections[sectionIndex].clauses[index].value
            } catch {
              console.log("error getting initial data for section clauses", sectionIndex);
            }
          }
          if (importantOnly && item.important!= true && (item.required != true || (initialValue || initialValue === 0  || initialValue?.length > 0))) {
            // console.log("Not important");
            return (<p>I'm being skipped</p>)
          } else {
            return (
              <Clause
                key={sectionIndex + ":" + index + ":" + item.id}
                sectionIndex={sectionIndex}
                clauseIndex={index}
                data={item}
              />
            )
          }
      })}

      </div>
    </div>
  )
}

export default SectionClauses
