import React, {useContext, useState, useEffect} from "react";
import {auth} from '../firebase'
import {
  getAuth,
  sendSignInLinkToEmail,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  updateProfile,
} from 'firebase/auth'

import {Link, useNavigate} from 'react-router-dom'

import NavBar from "../components/NavBar.js"
import Page from "../components/Page.js"

const AuthContext = React.createContext()

export function useAuth(){
  return useContext(AuthContext)
}

export function AuthProvider({children}){
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate()
  const [beenWaiting, setBeenWaiting] = useState(0);

  useEffect(() => {
    setTimeout(() => {setBeenWaiting(1000)}, 1000)
    setTimeout(() => {setBeenWaiting(2000)}, 2000)
  }, []);

  // useEffect(() => {
  //   if (!loading && !currentUser){
  //     console.log("jog on son");
  //     navigate('/login')
  //   }
  // }, [loading, currentUser]);

  useEffect(() => {
    console.log("auth provider loaded: ", currentUser);
    const unsub = onAuthStateChanged(auth,  (user) => {
      setCurrentUser(user)
      setLoading(false)
      console.log("auth state changed");
      if (user){
        console.log("uid: ", user.uid);
        let createdAt = null
        try {
          createdAt = new Date(user.metadata.creationTime).toISOString()
        } catch {}
        window.analytics.identify(user.uid, {
          email: user.email,
          createdAt: createdAt
        })
      } else {
        console.log('user has been logged out');
        window.analytics.reset()
      }
    })
    return unsub
  }, []);

  function register(email, password){
    return createUserWithEmailAndPassword(auth, email, password)
  }

  function login(email, password){
    return signInWithEmailAndPassword(auth, email, password)
  }

  function logOut(){
    console.log("doing sign out now");
    return signOut(auth)
  }

  const value={
    currentUser,
    register,
    login,
    logOut,
    auth,
    updateProfile,
  }

  // useEffect(() => {
  //   if (!loading && !currentUser){
  //     console.log("looks like this user is not logged in");
  //     navigate('/login')
  //   }
  // }, [loading, currentUser]);


  if (!loading && currentUser){
    return(
      <AuthContext.Provider value={value}>
        {children}
      </AuthContext.Provider>
    )
  }

  // if (!loading && !currentUser){
  //   return(
  //     <AuthContext.Provider value={value}>
  //       <Page center>
  //         <p style={{color: 'coral', fontSize: 24}}>Access Denied..</p>
  //       </Page>
  //     </AuthContext.Provider>
  //   )
  // }

  if (loading){
    return(
      <AuthContext.Provider value={value}>
        <div className="Page">
          {beenWaiting > 1000 &&
            <div className="" style={{textAlign: "center"}} >
              <img src="/Spinner-1s-200px.gif" style={{height: 80, marginTop: 80}}/>
              <p>Please wait while we authenticate you..</p>
            </div>
          }
        </div>
      </AuthContext.Provider>
    )
  }




  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )

  return (
    <AuthContext.Provider value={value}>
      {!loading && currentUser && children}
      {loading &&
        <div className="Page">
          <NavBar/>
        <div className="" style={{textAlign: "center"}} >
          <img src="/Spinner-1s-200px.gif" style={{height: 80, marginTop: 80}}/>
          <p>Please wait while we authenticate you..</p>
        </div>
        </div>
      }
      {!loading && !currentUser &&
        <div className="Page center" >
          <NavBar/>
          <p>Access Denied..</p>
        </div>
      }
    </AuthContext.Provider>
  )
  // {!loading && children}

}
