import React, {} from "react";

function FormProgressBar({props, length, activeIndex, color, style}) {

  const percentage =  ((activeIndex)/length)*100

  return (
      <div className="formProgressBar2">
        <div className="formProgressBar2Complete" style={{width : percentage +"%"}}></div>
      </div>
  );
}



export default FormProgressBar;
