import React from "react";

import { BsCheck2Circle, BsCheckCircle, BsCircle } from "react-icons/bs";
import { MdOutlineRadioButtonUnchecked, MdOutlineCircle, MdOutlineCheckCircleOutline } from "react-icons/md";


export default function CheckListLineItem({children, checked, onClick, onCheck}){

  function handleCheck(e){
    e.stopPropagation()
    onCheck(!checked)
  }

  function handleClick(e){
    e.stopPropagation()
    onClick()
  }

  return (
    <li className="No-icon">
      <div className="Flex AlignCenter" onClick={handleClick}>
        <div className="Flex AlignCenter" onClick={handleCheck}>
          {!checked && <MdOutlineCircle color="#4DA2B0" style={{height: 22, width: 22}}/>}
          {checked && <MdOutlineCheckCircleOutline color="#A6ACB2" style={{height: 22, width: 22}}/>}
        </div>
        <div className={"Flex ML15" + (checked? " checked":"")} >
          {children}
        </div>
      </div>
    </li>
  )
}
