import React, {useRef, useState, useEffect} from "react";

export default function Component({
  containerStyle,
  inputStyle,
  dropdownStyle,
  suggestionRowStyle,
  suggestionTextStyle,
  onChange,
  onFocus,
  onSelected,
  onBlur,
  getFullData,
  ...otherProps
}){
  const [timer, setTimer] = useState();
  const [lastValue, setLastValue] = useState();
  const [addressText, setAddressText] = useState('');
  const [selectedAddress, setSelectedAddress] = useState();
  const [showDropdown, setShowDropdown] = useState(false);
  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);


  function getAutoComplete(text){
    console.log("getting autocomplete for ", text);
    const encodedText = encodeURIComponent(text)
    return fetch("https://api.ideal-postcodes.co.uk/v1/autocomplete/addresses?api_key=ak_l8fw4suesSAZlnZ6D0hnnflwtKZkQ&q=" + encodedText)
      .then((response) => response.json())
      .then((json) => {
        // console.log("JSON", json);
        if (json.result && json.result.hits){
          setAutoCompleteOptions(json.result.hits)
          setShowDropdown(true)
        }
        return json;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    console.log("auto options: ", autoCompleteOptions);
  }, [autoCompleteOptions]);


  return (
    <div
      style={{...styles.container, ...containerStyle}}
    >
      <input
        type="text"
        style={{...styles.textBox, ...inputStyle}}
        type=""
        name=""
        onChange={(v) => {
          setSelectedAddress(null)
          if(onChange)onChange(v)
          setAddressText(v.target.value)
          setLastValue(v)
          if (onSelected){
            onSelected(null)
          }
          setShowDropdown(true)
          if (timer){
            // console.log("cancelling timer")
            clearTimeout(timer)
          }
          const thisTimer = setTimeout(() => {
            getAutoComplete(v.target.value)
          }, 500);
          setTimer(thisTimer)
        }}
        value={addressText}
        onFocus={()=> {
          // setShowDropdown(true)
          if(onFocus){onFocus()}
        }}
        onBlur={()=> {
          // setShowDropdown(false)
          // onBlur()
        }}
        {...otherProps}
      />
    <div style={styles.holder} >
      {showDropdown && autoCompleteOptions.length > 0 &&
        <div style={{...styles.addressDropdown, dropdownStyle}} >
        {
          autoCompleteOptions.map((item, index) => (
            <div
              key={"asdf" + index}
              style={{...styles.addressSuggestionRow, ...suggestionRowStyle}}
              onClick={async() => {
                console.log("clicked on an option: ", item.suggestion);
                  // setSelectedAddress(item)
                  setAddressText(item.suggestion)
                  if (onChange) onChange(item.suggestion)
                  setShowDropdown(false)
                  if (onSelected){
                    // console.log("running on selected from address input");
                    if (getFullData){
                      if (!item.urls){return null}
                      let url = ""
                      if (item?.urls.umprn){
                        // console.log("got umprn", newPropertyData.current.urls.umprn);
                        url = item?.urls.umprn
                      } else if (item?.urls.udprn){
                        // console.log("got udprn", newPropertyData.current.urls.udprn);
                        url = item?.urls.udprn
                      } else {
                        // console.log("got no address identifier");
                        setSelectedAddress()
                        onSelected([item.suggestion])
                      }

                      // Now that we have the identifier get the full data
                      const apiURL = "https://api.ideal-postcodes.co.uk" + url + "?api_key=ak_kmdcbe2udADioU7Yh0S2sl3fktIAo"
                      // console.log("apiURL ", apiURL);
                      const fullAddressData = await fetch(apiURL)
                      .then((response) => response.json())
                      .then((json) => {
                        // console.log("full address data is", json);
                        setSelectedAddress(item.suggestion, json)
                        onSelected([item.suggestion, json.result])
                      })
                      .catch((error) => {
                        // console.log("error fetching full data", error);
                        onSelected([item.suggestion])
                      });
                  } else {
                    onSelected([item.suggestion])
                  }

                }
              }
            }>
              <p style={{...styles.addressSuggestionText, ...suggestionTextStyle}} >{item.suggestion}</p>
            </div>
          ))
        }
      </div>
      }
    </div>

    </div>
  )
}

const styles = {
  container: {
    width: "100%",
    boxSizing: "border-box",
    padding: 0,
    margin: 0,
    maxWidth: 400,
    // textAlign: "center"
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "center",
    // alignItems: "center"
  },
  addressDropdown: {
    backgroundColor: "white",
    minHeight: 65,
    borderColor: "#DDD9D9",
    borderWidth: 0.5,
    width: "100%",
    borderRadius: 10,
    justifyContent: "center",
    // padding: 12,
    fontFamily: "HelveticaNeue",
    fontSize: 18,
    color: "#48525D",
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 10,
    marginBottom: 10,
    boxSizing: "border-box",
    overflowY: "scroll",
    maxHeight: 200,
    maxWidth: 400,
    // height: 200,
    // display: "flex",
    // flexDirection: "column"
    position: "absolute",
    // left: 0
  },
  addressSuggestionRow: {
    borderBottom: "0.5px solid #7ebbc6",
    fontSize: 14,
    height: 40,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    boxSizing: "border-box",
    cursor: 'pointer'
  },
  addressSuggestionText: {
    margin: 0,
  },
  textBox : {
    backgroundColor: "white",
    minHeight: 65,
    // borderColor: "#DDD9D9",
    // borderWidth: 0.5,
    border: 0,
    width: "100%",
    maxWidth: 400,
    borderRadius: 10,
    justifyContent: "center",
    // padding: 12,
    fontFamily: "HelveticaNeue",
    fontSize: 18,
    color: "#48525D",
    paddingLeft: 12,
    paddingRight: 12,
    marginBottom: 10,
    boxSizing: "border-box",
  },
}
