import React, {useState, useEffect, useImperativeHandle, forwardRef} from "react";
import { MdClose } from "react-icons/md";


function AgreementStartSplashModal(props, ref){
  // const {id, onHide, persistHide} = {...props}
  const id = props.id
  const [hide, setHide] = useState(false);


  function handleHide(){
    setHide(true)
  }

  function handleShow(){
    console.log("showing");
    setHide(false)
  }

  useImperativeHandle(ref, () => ({
    hide: hide,
    show: handleShow,
    print: () => {
      console.log("printing");
    }
  }));

  if(hide){
    return
  }


  return (
    <div className="FullOverlay ScrollV P10 PB20"
      onClick={(e) => {
        e.stopPropagation()
        if (props.dismissAnywhere){
          handleHide()
        }
      }}
    >
      <div className="Flex W100  JustifyCenter P20" style={{minHeight: '100vh'}}>
        <div style={{...props.contentContainerStyle}} className="Flex Column ModalCard MarginAuto AlignCenter W100" style={{"position": "relative"}} onClick={(e) => {e.stopPropagation()}}>
          {props.children}
          {props.showClose && <MdClose color="#A6ACB2" style={{height:20, width: 20, position: "absolute", top: 12, right: 12, cursor: "pointer"}} onClick={handleHide}/>}
        </div>
      </div>
    </div>
  )
}

AgreementStartSplashModal = forwardRef(AgreementStartSplashModal)

export default AgreementStartSplashModal
