import React, {useRef, useState, useEffect} from "react";
import validator from 'validator'

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useParams,
    useNavigate,
    useLocation,
    Outlet,
    useOutletContext
} from 'react-router-dom';

import ContinueButton from "../../components/ContinueButton.js"
import AddressInput from "../../components/inputs/AddressInput.js"
import AddressDropdown from "../../components/inputs/AddressDropdown.js"
import ErrorBox from "../../components/tenancyAgreements/Clause/ErrorBox.js"

import { getFirestore, doc, getDocs, getDoc, addDoc, setDoc, updateDoc, collection, collectionGroup, query, limit, orderBy, onSnapshot, where, orderByChild } from "firebase/firestore";

import { useAuth, } from "../../contexts/AuthContext"

const db = getFirestore();

export default function Password({}){
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const {email, setEmail, firstName, setFirstName, lastName, setLastName, phone, setPhone, newPropertyAddress, setNewPropertyAddress, newPropertyData, setnewPropertyData, createTenancy} = useOutletContext();
  const {currentUser, register, login, updateProfile} = useAuth()
  const [loading, setLoading] = useState(false);
  const [hideConfirm, setHideConfirm] = useState(false);

  useEffect(() => {
    if (!newPropertyData){
      navigate("../")
    } else if (!email){
      // navigate("../email")
      navigate("../you")
    }
  }, []);

  const handleLogin = (callback) => {
    setLoading(true)
    if (!email && password){
      setError({message: "Must provide a valid email and password"})
      setLoading(false)
    }

    login( email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        console.log("user logged in as", user);
        console.log("current user within login is", currentUser);
        const newTID = createTenancy()
          .then((id)=> {
            console.log("newTID", id)
          })
          .catch((error) => {console.log("CT error", error)})
        setLoading(false)
        setError()
        // navigate("/")
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        if (errorCode === "auth/wrong-password"){
          setError({message: "Looks like you already have an account but that was the wrong password"})
        }else {
          setError({message: error.message})
        }
        setLoading(false)
      });
  }


  const handleRegister = (callback) => {

    setLoading(true)
    if (!password || !email) {
      setError({message: "Must provide a valid email and password"})
      setLoading(false)
      return null
    }

    if (password != passwordConfirm) {
      setError({message: "Password's don't match"})
      setLoading(false)
      return null
    }

    register( email, password)
      .then(async(userCredential) => {
        // Signed in
        const user = userCredential.user;
        console.log("user is ", user);
        console.log("current user within register is", currentUser);
        await addUserToFirestore(user.uid)
        try {
          const updateDict = {
            displayName: firstName + " " + lastName,
          }
          if (phone){
            updateDict.phone = "+" + phone
          }
          updateProfile(user, updateDict)
        } catch {}

        window.analytics.identify(user.uid, {
          firstName: firstName,
          lastName: lastName,
          email: email,
          phone: phone,
        });

        window.analytics.track("account.register", {uid: user.uid})

        // ...
        setLoading(false)
        setError()
        const newTID = createTenancy()
          .then((id)=> {console.log("newTID", id)})
          .catch((error) => {console.log("CT error", error)})
        // navigate("/")
      })
      .catch((error) => {
        const errorCode = error.code;
        console.log(errorCode);
        if (errorCode === "auth/email-already-in-use"){
          setHideConfirm(true)
          handleLogin()
        } else {
          setHideConfirm(false)
          setError({message: error.message})
          setLoading(false)
        }
        // ..
      });
  }

  const addUserToFirestore = async(uid) => {
    console.log("begginning adding user to firestore", uid);
    const userRef = doc(db, "users", uid);
    const userFields = {
        id: uid,
        created: new Date(),
        roles: ["landlord"],
    }
    try {
      await setDoc(userRef, userFields)
      .then(async (resp) => {
        console.log("succesfully added user to firestore");
        // now create a private subcollection
        await setDoc(doc(db, "users", uid, "private", "preferences"), {
        })

        await setDoc(doc(db, "users", uid, "private", "info"), {
          "email": email,
          "name" : {
            "first": firstName,
            "last": lastName,
          },
          "phone": phone,
          "onboarding" : {
            "complete" : false,
          },

        })

      })
    } catch (error) {
      console.log("error adding user to firestore", error);
    }

  }

  return (
      <div name="main" style={styles.mainContent} >
        <div name="top" style={styles.topSection}>
          <img src="/join-tenancy-image.svg" style={{width: 250, height: 250}}/>
          <div style={styles.topText}>
            <p style={styles.headerp}>Let's make this tenancy!</p>
          </div>
        </div>
        <div name="mid" className="NewTenancyMidSection">

          <div style={{width: "100%", display: "flex", alignItems: "center", flexDirection: "column", marginBottom: 30}} className="" >
            <p style={styles.formLabel2}>{hideConfirm? "What is your password?" : "Create a password to save progress"}</p>
            <input type="password" value={password} onChange={(v) => {setPassword(v.target.value)}} ref={passwordRef} style={styles.textBox} name="password" placeholder="************" />
            {!hideConfirm && <input type="password" value={passwordConfirm} onChange={(v) => {setPasswordConfirm(v.target.value)}} ref={passwordConfirmRef} style={styles.textBox} name="passwordConfirm" placeholder="***********" />}
          </div>
        </div>
        <div name="bottom" style={styles.bottomSection}>
          <ContinueButton disabled={loading} style={{margin: 0, width: "100%"}} colour="white"
            onClick={() => {
              if (!newPropertyData){
                setError({"message" : "Please select a property"})
              } else if (!email){

              } else {
                if (hideConfirm){
                  handleLogin()
                } else {
                  handleRegister()
                }
                // setError({type: "warn", "message" : "We're fixing an issue as we speak"})
              }
            }}
          >
            create the tenancy
          </ContinueButton>
          <p className="CenterText" style={{color: "white", marginTop: 20, fontSize:18}}>You won't be charged at this stage, don't worry!</p>
          {error && <ErrorBox {...error}/>}
        </div>
      </div>
  )
}

const styles = {
  addressDropdown: {
    backgroundColor: "white",
    minHeight: 65,
    borderColor: "#DDD9D9",
    borderWidth: 0.5,
    width: "100%",
    maxWidth: 400,
    borderRadius: 10,
    justifyContent: "center",
    // padding: 12,
    fontFamily: "HelveticaNeue",
    fontSize: 18,
    color: "#48525D",
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 10,
    marginBottom: 10,
    boxSizing: "border-box",
    overflowY: "scroll",
    maxHeight: 200,

    // height: 200,
    // display: "flex",
    // flexDirection: "column"
    // position: "absolute",
  },
  addressSuggestionRow: {
    borderBottom: "0.5px solid #7ebbc6",
    fontSize: 14,
    height: 40,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    boxSizing: "border-box"
  },
  addressSuggestionText: {
    margin: 0,
  },
  continueButton: {
    margin: 0,
    marginTop: 25,
    width: "100%",
    boxSizing: "border-box",
  },
  formLabel: {
    fontFamily: "lato",
    fontSize: 24,
    color: "white",
    fontWeight: 200,
  },
  formLabel2: {
    fontFamily: "arvo",
    fontSize: 18,
    color: "white",
    fontWeight: 200,
    marginBottom: 20,

  },
  formLabelMini: {
    fontFamily: "lato",
    fontSize: 18,
    color: "white",
    fontWeight: 500,
    textAlign: "left",
    alignSelf: "flex-start",
  },
  topSection: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: 'column',
    alignItems: "center",
    width: "100%",
    // paddingTop: 30,
    boxSizing: "border-box",
    // marginTop: "5vh",
    // minHeight: 100,
    // maxHeight: 100,
    // border: "2px solid pink",
  },
  midSection: {
    display: "flex",
    justifyContent: "center",
    flexDirection: 'column',
    alignItems: "center",
    width: "100%",
    flex: 1,
    // minHeight: 100,
    // maxHeight: 100,
    // height: 800,
    // border: "2px solid blue",
  },
  midSectionFromBottom: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: 'column',
    alignItems: "stretch",
    width: "100%",
    flex: 1,
    marginBottom: 40,
    // minHeight: 100,
    // maxHeight: 100,
    // height: 800,
    // border: "2px solid blue",
  },
  main : {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  bottomSection: {
    display: "flex",
    justifyContent: "flexStart",
    flexDirection: 'column',
    alignItems: "center",
    width: "100%",
    // minHeight: 100,
    // maxHeight: 100,
    // height: 100,
    marginBottom: 50,
    // marginTop: 30,
    boxSizing: "border-box",
    maxWidth: 620,
    // border: "2px solid green",
  },
  textBox : {
    backgroundColor: "white",
    border: 0,
    minHeight: 65,
    width: "100%",
    maxWidth: 400,
    borderRadius: 10,
    justifyContent: "center",
    // padding: 12,
    fontFamily: "HelveticaNeue",
    fontSize: 18,
    color: "#48525D",
    paddingLeft: 12,
    paddingRight: 12,
    marginBottom: 10,
    boxSizing: "border-box",
  },
  mainContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    // width: "100vw",
    // minHeight: "100vh",
    minHeight: "100%",
    // border: "2px solid cyan",
    // padding: 30,
    // marginLeft: 30,
    // marginRight: 30,
    alignItems:"center",
    justifyContent: "space-between",
    boxSizing: "border-box",
  },
  topText: {
    // flex: 1,
    justifyContent: "center",
    textAlign: "center",
  },
  headerp: {
    fontFamily: 'Bebas Neue',
    fontFamily: 'Arvo',
    fontWeight: "bold",
    color: "white",
    fontSize: 36,
    margin: 0,
    marginBottom: 11,
    pAlign: "center",
  },
  subHeaderp: {
    fontFamily: "Arvo",
    // fontWeight: "bold",
    color: "white",
    fontSize: 26,
    pAlign: "center",
    paddingHorizontal: 20,
    marginBottom: 15,
  }
}
