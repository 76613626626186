import React, { useRef, useEffect } from "react";

export function useOutsideAlerter(ref, callback) {
  useEffect(() => {

    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback()
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export function useFirstRender() {
  const firstRender = useRef(true);

  useEffect(() => {
    firstRender.current = false;
  }, []);

  return firstRender.current;
}


// export function useDelayedTimer(callback, delay, error){
//   const timer = useRef()
//   if (timer.current) {
//     try{
//       clearTimeout(timer.current);
//     } catch {}
//   }
//
//   timer.current = setTimeout(() => {
//     try {
//       callback()
//     } catch (e) {
//       error(e)
//     }
//   }, delay)
// }
