import React, {useState, useEffect, useRef, forwardRef} from "react";
import { useNavigate } from 'react-router-dom';

import OverviewBox from "./OverviewBox"
import DateInput from "../inputs/Date.js"
import SimpleSelect from "../inputs/SimpleSelect.js"
import ExpandingInput from "../ExpandingInput.js"

import {useFirstRender} from "../../utility/events.js"
import {nthDay} from "../../utility/dates.js"

import { getFirestore, doc, collection, updateDoc } from "firebase/firestore";

const db = getFirestore();

export default function RentBox({tenancy, rent, tenancyId, editable, min, max}){
  const navigate = useNavigate()
  const firstRender = useFirstRender();
  const [rentAmount, setRentAmount] = useState(rent?.amount || "");
  const [rentDueDay, setRentDueDay] = useState(rent?.dueDay || "");
  const [rentInterval, setRentInterval] = useState(rent?.interval || "month");
  const amountRef = useRef(rent?.amount || "")
  const intervalRef = useRef(rent?.interval || "month")
  const collectionTypeRef = useRef()
  const dueDayRef = useRef(rent?.dueDay || "")
  const updateTimer = useRef()
  const [dueDayMaxVal, setDueDayMaxVal] = useState(365);
  const [collectionType, setCollectionType] = useState();

  useEffect(() => {
    let rentDay = 1
    if (tenancy?.rent?.dueDay){
      rentDay = tenancy.rent.dueDay
    } else {
      try {
        rentDay = new Date(tenancy?.dates?.start?.at.seconds * 1000).getDate()
      } catch (e) {
        rentDay = 1
      }
    }
    if (!rentDay){
      rentDay = 1
    }
    // const dd = Math.min(rentDay, dueDayMax())
    const dd = Math.min(rentDay, 365)
    setRentDueDay(dd)
    dueDayRef.current = dd
  }, [tenancy?.rent?.dueDay]);

  useEffect(() => {
    // setDueDayMaxVal(dueDayMax())
  }, [rentInterval]);

  useEffect(() => {
    if (!rent?.interval){
      setRentInterval("month")
      intervalRef.current = "month"
    }
  }, []);


  useEffect(() => {
    if (rentDueDay){
      const dd = Math.min(rentDueDay, dueDayMax())
      setRentDueDay(dd)
      dueDayRef.current = dd
    }
  }, [rentInterval]);

  async function updateTenancyValue(){
    const tRef = doc(db, "tenancies", tenancyId)
    const updatedValues = {
      "updatedAt": new Date(),
      "rent.amount" : amountRef.current || null,
      "rent.interval" : intervalRef.current || null,
      "rent.dueDay" : dueDayRef.current || null,
    }
    console.log("updating tenancy values", updatedValues);
    await updateDoc(tRef, updatedValues);
  }

  function delayedUpdate(){
    clearTimeout(updateTimer.current);
    updateTimer.current = setTimeout(() => {
      // console.log("now updating w", rentAmount, rentInterval, rentDueDay)
      updateTenancyValue()
    }, 500)
  }

  function dueDayMax(){
    console.log("checking max with i", rentInterval, intervalRef.current);
    switch (rentInterval || intervalRef.current) {
      case "week":
        return 7
      case "fortnight":
        return 14
      case "month":
        return 28
      case "year":
        return 365
      default:
        return 365
    }
    return 365
  }


  return (
    <OverviewBox title="Rent" complete={(rentAmount && rentInterval && rentDueDay) ? true : false} className="MH260">
        <div style={{paddingTop:20, paddingBottom: 20}} >
          <p className="FormLabelUpper">Total rent amount</p>
          <div className="Flex" >
            <span className="ClauseText" style={{whiteSpace: "noWrap"}}>
              &nbsp;£
              <ExpandingInput
                type="number"
                min={1}
                minWidth={50}
                extraWidth={20}
                className={"ClauseTextInput currency" + (editable==false? " not-editable" : "")}
                value={rentAmount}
                disabled={editable === false}
                onChange={(v) => {
                  window.analytics.track("tenancy_overview.rent.amount_change", { tenancyID:  tenancyId});
                  if (v.target.value >= 0 || v.target.value == null){
                    setRentAmount(v.target.value)
                    amountRef.current = v.target.value
                    delayedUpdate()
                  } else {
                    setRentAmount(1)
                    amountRef.current = 1
                  }
                }}
              />
            per
            <SimpleSelect
              value={intervalRef.current}
              disabled={editable === false}
              onChange={(v) => {
                window.analytics.track("tenancy_overview.rent.interval_change", { tenancyID:  tenancyId});
                if (editable === false) return
                // console.log("setting rent inteval to ", v.target.value);
                setRentInterval(v.target.value)
                intervalRef.current = v.target.value
                delayedUpdate()
              }}
              options={[
                {text:"week", value: "week"},
                {text:"fortnight", value: "fortnight"},
                {text:"month", value: "month"},
                {text:"year", value: "year"},
              ]}
            />
            </span>
          </div>


          <div className="MT30"/>
          <p className="FormLabelUpper">due on the</p>
            <span className="ClauseText" style={{whiteSpace: "noWrap"}}>
              <ExpandingInput
                type="number"
                min={1}
                max={dueDayMaxVal}
                minWidth={0}
                extraWidth={0}
                className={"ClauseTextInput right" + (editable===false? " not-editable" : "")}
                value={rentDueDay}
                disabled={editable===false}
                onChange={(v) => {
                  window.analytics.track("tenancy_overview.rent.due_day_change", { tenancyID:  tenancyId});
                  if (editable === false) return
                  // setValue(v.target.value)
                  const dd = Math.max(1, Math.min(v.target.value, dueDayMax()))
                  // console.log("dd2 is ", dd);
                  setRentDueDay(dd)
                  dueDayRef.current = dd
                  delayedUpdate()
                }}
              />
            {nthDay(rentDueDay)} day of each {rentInterval}
          </span>

          {/* <div className="MT30"/>
          <p className="FormLabelUpper">paid</p>
            <span className="ClauseText" style={{whiteSpace: "noWrap"}}>
              <SimpleSelect
                value={collectionType}
                disabled={editable === false}
                onChange={(v) => {
                  // window.analytics.track("tenancy_overview.rent.interval_change", { tenancyID:  tenancyId});
                  if (editable === false) return
                  setCollectionType(v.target.value)
                }}
                options={[
                  {text:"Manually", value: "manually"},
                  {text:"Automatically", value: "automatically"},
                ]}
              />
            {collectionType == "manually" && "by the tenant"}
            {collectionType == "automatically" && " using 10NC"}
          </span> */}

        </div>

        <div className="Flex Column MT10 AlignCenter">
          {/* <button className="Blue Square">Rent collected automatically</button> */}
        </div>
    </OverviewBox>
  )
}
