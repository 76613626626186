import React, {useRef, useState, useEffect} from "react";

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useParams,
    useNavigate,
    useLocation,
    Outlet,
    useOutletContext
} from 'react-router-dom';

import ContinueButton from "../../components/ContinueButton.js"
import AddressInput from "../../components/inputs/AddressInput.js"
import AddressDropdown from "../../components/inputs/AddressDropdown.js"
import ErrorBox from "../../components/tenancyAgreements/Clause/ErrorBox.js"

import { useAuth, } from "../../contexts/AuthContext"

export default function Landing({}){
  const emailRef = useRef()
  const navigate = useNavigate();
  const [error, setError] = useState();
  const {currentUser} = useAuth()
  const {email, setEmail, propertyAddress, sendRequest} = useOutletContext();



  useEffect(() => {
  }, []);


  if (!propertyAddress){
    return (
      <div name="main" style={styles.mainContent} >
        <div name="top" style={styles.topSection}>
          <img src="/join-tenancy-image.svg" style={{width: 300, height: 300}}/>
          <div style={styles.topText}>
            {/* <p style={styles.headerp}>{propertyAddress}</p> */}
          <p style={styles.subHeaderp}>Sorry this tenancy is not ready to join yet</p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div name="main" style={styles.mainContent} >
      <div name="top" style={styles.topSection}>
        <img src="/join-tenancy-image.svg" style={{width: 300, height: 300}}/>
        <div style={styles.topText}>
          <p style={styles.headerp}>{propertyAddress}</p>
          <p style={styles.subHeaderp}>Join the tenancy on 10NC</p>
        </div>
      </div>
      <div name="mid" style={styles.midSection}>

        <div style={{width: "100%", display: "flex", alignItems: "center", flexDirection: "column", marginBottom: 50}} className="" >
        </div>
      </div>
      <div name="top" style={styles.bottomSection}>
        <ContinueButton style={{margin: 0, width: "100%"}} colour="white"
          onClick={() => {
            if (!currentUser){
              navigate("../email")
            } else {
              console.log("you are a user");
              sendRequest()
            }
          }}
        >
          Join
        </ContinueButton>
        {error && <ErrorBox {...error}/>}
      </div>
    </div>
  )
}

const styles = {
  addressDropdown: {
    backgroundColor: "white",
    minHeight: 65,
    borderColor: "#DDD9D9",
    borderWidth: 0.5,
    width: "100%",
    maxWidth: 400,
    borderRadius: 10,
    justifyContent: "center",
    // padding: 12,
    fontFamily: "HelveticaNeue",
    fontSize: 18,
    color: "#48525D",
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 10,
    marginBottom: 10,
    boxSizing: "border-box",
    overflowY: "scroll",
    maxHeight: 200,

    // height: 200,
    // display: "flex",
    // flexDirection: "column"
    // position: "absolute",
  },
  addressSuggestionRow: {
    borderBottom: "0.5px solid #7ebbc6",
    fontSize: 14,
    height: 40,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    boxSizing: "border-box"
  },
  addressSuggestionText: {
    margin: 0,
  },
  continueButton: {
    margin: 0,
    marginTop: 25,
    width: "100%",
    boxSizing: "border-box",
  },
  formLabel: {
    fontFamily: "lato",
    fontSize: 24,
    color: "white",
    fontWeight: 200,
  },
  formLabel2: {
    fontFamily: "arvo",
    fontSize: 18,
    color: "white",
    fontWeight: 200,
  },
  formLabelMini: {
    fontFamily: "lato",
    fontSize: 18,
    color: "white",
    fontWeight: 500,
    textAlign: "left",
    alignSelf: "flex-start",
  },
  topSection: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: 'column',
    alignItems: "center",
    width: "100%",
    boxSizing: "border-box",
    // paddingTop: 30,
    // marginTop: "5vh",
    // minHeight: 100,
    // maxHeight: 100,
    // border: "2px solid pink",
  },
  midSection: {
    display: "flex",
    justifyContent: "center",
    flexDirection: 'column',
    alignItems: "center",
    width: "100%",
    flex: 1,
    // minHeight: 100,
    // maxHeight: 100,
    // height: 800,
    // border: "2px solid blue",
  },
  midSectionFromBottom: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: 'column',
    alignItems: "stretch",
    width: "100%",
    flex: 1,
    marginBottom: 40,
    // minHeight: 100,
    // maxHeight: 100,
    // height: 800,
    // border: "2px solid blue",
  },
  main : {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  bottomSection: {
    display: "flex",
    justifyContent: "flexStart",
    flexDirection: 'column',
    alignItems: "center",
    width: "100%",
    // minHeight: 100,
    // maxHeight: 100,
    // height: 200,
    marginBottom: 50,
    boxSizing: "border-box",
    maxWidth: 620,
    // border: "2px solid green",
  },
  textBox : {
    backgroundColor: "white",
    minHeight: 65,
    border: 0,
    width: "100%",
    maxWidth: 400,
    borderRadius: 10,
    justifyContent: "center",
    // padding: 12,
    fontFamily: "HelveticaNeue",
    fontSize: 18,
    color: "#48525D",
    paddingLeft: 12,
    paddingRight: 12,
    marginBottom: 10,
    boxSizing: "border-box",
  },
  mainContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    // width: "100vw",
    // minHeight: "100vh",
    minHeight: "100%",
    // border: "2px solid cyan",
    // padding: 30,
    // marginLeft: 30,
    // marginRight: 30,
    alignItems:"center",
    justifyContent: "space-between",
    boxSizing: "border-box",
  },
  topText: {
    // flex: 1,
    justifyContent: "center",
    textAlign: "center",
  },
  headerp: {
    fontFamily: 'Bebas Neue',
    fontFamily: 'Arvo',
    fontWeight: "bold",
    color: "white",
    fontSize: 36,
    margin: 0,
    marginBottom: 11,
    pAlign: "center",
  },
  subHeaderp: {
    fontFamily: "Arvo",
    // fontWeight: "bold",
    color: "white",
    fontSize: 26,
    pAlign: "center",
    paddingHorizontal: 20,
    marginBottom: 15,
    marginTop: 0,
  }
}
