import React from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useParams,
    useNavigate,
    useLocation,
    Outlet,
} from 'react-router-dom';

function SectionTest({}){
  const { taId, sectionSlug } = useParams()

  return (
    <div
      className=""
    >
    <p>This is a section</p>
    <p>taID is {taId}</p>
    <p>slug is {sectionSlug}</p>
    </div>
  )
}

export default SectionTest
