import React, {useState, useContext, useEffect} from "react";

import {
    useNavigate,
    useParams,
} from 'react-router-dom';

import SectionClauses from "../../components/tenancyAgreements/SectionClauses.js"
import ErrorBox from "../../components/tenancyAgreements/Clause/ErrorBox.js"
import AgreementStartSplashOverlay from "../../components/tenancyAgreements/AgreementStartSplashOverlay.js"
import ContinueButton from "../../components/ContinueButton.js"
import BackButton from "../../components/BackButton.js"
import FormProgressBar from "../../components/FormProgressBar.js"
import FormProgressBar2 from "../../components/FormProgressBar2.js"
import NavBar from "../../components/NavBar.js"
import Page from "../../components/Page.js"

import ExpandingInput from "../../components/ExpandingInput.js"

import {AgreementDataContext} from "../../screens/tenancyAgreements/Container.js"


export default function FullAgreement({}){
  // const [agreementData, setAgreementData] = useState();
  const {checkSectionComplete, agreementData, setAgreementData, agreementDataLoaded, uploadAgreementData, checkPreviousSectionsComplete} = useContext(AgreementDataContext);
  const { tenancyId, taId, sectionSlug } = useParams()
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [allValid, setAllValid] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  useEffect(() => {
    // console.log("FT AD change", agreementData);
  }, [agreementData]);


  return (
      <div className="SectionContainer">
      <div className="SectionHeaderAll" >
        <div className="SectionHeaderMain">
          <div style={{float: 'left', position: 'absolute'}} >
            <BackButton onClick={() => {
                try {
                  navigate("/tenancies/" + tenancyId + "/agreements/" + taId + "/important")
                } catch (e) {
                  console.log("defaulting back in browser");
                  navigate(-1)
                }

              }}
            />
          </div>
        <h1 className="SectionTitle">Full tenancy agreement</h1>

        </div>
      </div>
        <div style={{ maxHeight: 600, padding: 30, paddingTop: 100, border: "1px solid #dfdddd", overflowY: "scroll",   boxShadow: "#eaeaea 0px 0px 10px inset"}} >
        {agreementData.sections.map((item, sectionIndex) => {
          return (
          <div name="section" style={{marginBottom: 150}}>
            <h1 className="SectionTitle" style={{marginTop:20}}>{agreementData.sections[sectionIndex].title}</h1>

              <SectionClauses
                sectionIndex={sectionIndex}
              />
          </div>
        )
      })}


        </div>
      <ContinueButton
        onClick={async() => {
          if(['inTenantSigning', 'testMode'].includes(agreementData.status)){
            console.log("skipping validation due to status");
            navigate("../sign")
          } else {
            const [complete, incompleteSections] = checkPreviousSectionsComplete(agreementData.sections.length, true)
            if (complete===true){
              console.log("all sections complete");

              // navigate("../fullt")
              await uploadAgreementData(
                () => {
                  console.log("TA Upload: Success ON OUTER AWAIT");
                  setError()
                  // alert("about to nav")
                  // navigate("../full")
                  navigate("../sign")
                },
                () => {
                  console.log("TA Upload: FAILED ON OUTER AWAIT");
                }
               )

            } else {
              setError({message: "You must complete all clauses"})
              console.log("incompleteClauses when continuing", incompleteSections);
            }
          }
        }}
      >Continue to signing</ContinueButton>
      {error && <ErrorBox {...error}/>}
      </div>
  )
}
