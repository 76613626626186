import React from "react";
import { useState, useEffect, createContext, useContext, useRef } from "react";

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useParams,
    useNavigate,
    useLocation,
} from 'react-router-dom';

import {AgreementDataContext} from "../../screens/tenancyAgreements/Container.js"
import { useAuth, } from "../../contexts/AuthContext"

export default function Home({}){
  const {tenancy, agreementData, setAgreementData, getValueByValueReference, userTenancyRoles, checkPreviousSectionsComplete} = useContext(AgreementDataContext);
  const { tenancyId, taId, sectionSlug } = useParams()
  const {currentUser} = useAuth()
  const navigate = useNavigate();
  const location = useLocation()

  useEffect(() => {
    if (!currentUser){
      navigate("/login" + "?redirect=" + encodeURIComponent(window.location.pathname), { replace: true })
      // navigate("/login" , { replace: true })

    } else {
      const [c, is, lis, his] = checkPreviousSectionsComplete(agreementData.sections.length)
      if (c != true){
        // There are incomplete sections so navigating back to those
        console.log("not all sections have been completed", is);
        navigate("/tenancies/" + tenancyId + "/agreements/" + taId + "/s/" + agreementData.sections[lis].slug + location.search, { replace: true })
      }
    }

  }, []);

  return (
    <div
      className=""
    >
    </div>
  )
}
