import React from "react"
import axios from "axios"
import { useState, createContext, useContext, useEffect } from "react";

import ExpandingInput from "../../ExpandingInput.js"
import MultiLineP from "../../MultiLineP.js"


import {AgreementDataContext} from "../../../screens/tenancyAgreements/Container.js"

function List({text, icon, iconSlug,sectionIndex, clauseIndex, listItemPlaceholder}){
  const {agreementData, setAgreementData, editable:agreementEditable} = useContext(AgreementDataContext);
  const [expanded, setExpanded] = React.useState(false);
  const [data, setData] = React.useState(agreementData.sections[sectionIndex].clauses[clauseIndex]);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [value, setValue] = useState(agreementData.sections[sectionIndex].clauses[clauseIndex].value || []);
  const [editable, setEditable] = useState([data.editable, agreementEditable].every(v => v != false));

  useEffect(() => {
    console.log("got a list with data", data);
    console.log("editable for list clause " + data.id + " is ", editable);
    console.log("list editable from data", data.editable);

  }, []);

  useEffect(() => {
    setAgreementData(() => {
      const newAD = {...agreementData};
      newAD.sections[sectionIndex].clauses[clauseIndex].value = value
      return (newAD)
    })

  }, [value]);


  if (data?.hidden === true){
    return (<></>)
  }

  function ClauseText(){
      return (
        <p className="ClauseText">
          {data?.text}
        </p>
      )
  }

  function ClauseError({message}){
    return (
      <div className="ClauseErrorBox">
        <img src="/error.svg" className="ClauseErrorIcon"/>
        <p className="ClauseErrorText">{message}</p>
      </div>
    )
  }

  function handleClick(event){
    if (!data.description){
    } else {
      setExpanded(!expanded)
    }
    event.preventDefault()
  }

  function handleItemDelete(i){
    console.log("clicked on ", text);
    setValue(() => (value.slice(0,i).concat(value.slice(i+1, value.length))))
  }

  function handleItemAdd(){
    console.log("clicked on ", text);
    setValue([...value, {text:"", type: ""}])
  }

  return (
    <div className={"Clause" + (expanded ? " expanded": "")} onClick={handleClick} id={data.id}>
      <div className="ClauseInner">
        <div className="clauseIcon">
          <img src={"/clause-image/" + data.iconSlug}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src="/clause-image/placeholder.svg";
            }}
            className="ClauseIcon"
            alt={text}
          />
        </div>
        <div className="ClauseTextContainer">
          <ClauseText/>
        </div>

      </div>
      <ul className="SelectedAreas">
        {value.map((item, index) => (
          <li className="SelectedAreaRow" key={index} >
            <div className="SelectedAreaRowLeft">
            {data.numbered && <p className="ClauseListNumber">{index + 1}</p>}
            {data.lettered && <p className="ClauseListNumber">{(index + 10).toString(36).toUpperCase()}</p>}
            <img
              src={("/clause-image/area-icons/" + item.type + ".svg") || listItemPlaceholder}
              className="SelectedAreaIcon"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src="/list-item-icon.svg";
              }}
            />
          {editable===false &&
            <p className="ClauseListItem">{item.text}</p>
          }
          {!(editable==false) &&
            <ExpandingInput
              className="ClauseTextInput"
              value={item.text}
              style={{marginLeft: 10, marginRight: 10}}
              disabled={!agreementEditable}
              onChange={(v) => {
                if (!agreementEditable) return
                const nV = [...value]
                nV[index].text = v.target.value
                setValue(nV)
              }}
            />
          }


          </div>
          {editable &&
            <div className="SelectedAreaRowRight" onClick={() => {handleItemDelete(index)}}>
              <img src="/delete.svg" className="DeleteIcon"/>
            </div>
          }
        </li>
          ))}
      </ul>
      {!(editable===false) &&
        <div className="SelectedAreasAddButton" onClick={handleItemAdd}>
          <img src="/add-icon-grey.svg" className="SelectedAreaIcon"/>
        </div>
      }
    <p className="ClauseText" style={{marginLeft: 82}}>
      {data?.text?.post}
    </p>
    {expanded &&
      <div className="ClauseExpansion">
        {data.description && <MultiLineP className="ClauseDescription">{data.description}</MultiLineP>}

        {editable == false &&
          <p className="SelectNotEditableWarning" > Note: This has been set automatically based on the other information provided about the tenancy. It cannot be edited.
          </p>
        }
      </div>
    }
      {errorMessage && <ClauseError message={errorMessage}/>}
    </div>
  )

}

export default List;
