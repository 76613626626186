// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, getDocs, initializeFirestore } from "firebase/firestore/lite";
import {
  getAuth,
  sendSignInLinkToEmail,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
} from 'firebase/auth'


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA0OQ0HfLUdPbU-2RfQc34E01aq8lIkxAk",
  authDomain: "nc-e9104.firebaseapp.com",
  projectId: "nc-e9104",
  storageBucket: "nc-e9104.appspot.com",
  messagingSenderId: "1062100568750",
  appId: "1:1062100568750:web:87177cb16e63f5c9117b3f",
  measurementId: "G-9GX8SYCGD8"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig, {
  ignoreUndefinedProperties: true
});
export const analytics = getAnalytics(app);
// export const db = getFirestore(app, {ignoreUndefinedProperties: true });
export const db = initializeFirestore(app, {ignoreUndefinedProperties: true, cacheSizeBytes: 41943041})
// const db = firestore.firestore();
// db.settings({ ignoreUndefinedProperties: true })

export const auth = getAuth();



// // Get a list of cities from your database
// async function getCities(db) {
//   console.log("running test");
//   const citiesCol = collection(db, 'testCollection');
//   console.log("test col", citiesCol);
//   const citySnapshot = await getDocs(citiesCol);
//   console.log("test snap", citySnapshot);
//   const cityList = citySnapshot.docs.map(doc => doc.data());
//   console.log("test res", cityList);
//   return cityList;
// }
//
// getCities(db)


// const signInWithEmailLink = async () => {
//   try {
//     const res = await signInWithPopup(auth, googleProvider);
//     const user = res.user;
//     const q = query(collection(db, "users"), where("uid", "==", user.uid));
//     const docs = await getDocs(q);
//     if (docs.docs.length === 0) {
//       await addDoc(collection(db, "users"), {
//         uid: user.uid,
//         name: user.displayName,
//         authProvider: "google",
//         email: user.email,
//       });
//     }
//   } catch (err) {
//     console.error(err);

  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: 'https://10nc.app/for-landlords',
    // This must be true.
    handleCodeInApp: true,
    iOS: {
      bundleId: 'com.10nc.app'
    },
    android: {
      packageName: 'com.10nc.android',
      installApp: true,
      minimumVersion: '12',
    },
    dynamicLinkDomain: '10nc.page.link'
  };

  // const signInWithEmailLink = async () => {
  //   try {
  //     const res = await signInWithPopup(auth, googleProvider);
  //     const user = res.user;
  //     const q = query(collection(db, "users"), where("uid", "==", user.uid));
  //     const docs = await getDocs(q);
  //     if (docs.docs.length === 0) {
  //       await addDoc(collection(db, "users"), {
  //         uid: user.uid,
  //         name: user.displayName,
  //         authProvider: "google",
  //         email: user.email,
  //       });
  //     }
  //   } catch (err) {
  //     console.error(err);

  onAuthStateChanged(auth, (user) => {
    console.log("auth state changed");
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      const uid = user.uid;
      console.log("uid: ", user.uid);
      // ...
    } else {
      console.log("user is signed out");
      // User is signed out
      // ...
    }
  });


  export function sendEmailLink(email){
    sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem('emailForSignIn', email);
        console.log("sent email link to ", email);
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log("error sending email link", errorMessage);
        // ...
      });
  }


// console.log("imported firebase");
