import React from "react";

import { AiFillCheckCircle } from "react-icons/ai";

export default function OverviewOutlineBox({children, number, autoNumber, showNumber, title, complete, style, className}){

  return (
    <div
      className={"OutlineGridBox OverviewBox" + (autoNumber? "AutoNumber":"") + (complete? " Complete": "") + " " + (className? className: "")}
      style={style}
      onClick={() => {

      }}
      >
      {showNumber!= false &&
        <div className="NumberCircle TopLeft" >
          <p>{""}</p>
        </div>
      }
      <h2>{title}</h2>
      {children}
      {complete===true &&
        <AiFillCheckCircle color="#B6D488" style={{
            width: 22,
            height: 22,
            position: "absolute",
            bottom: 10,
            right: 10,
          }}/>
      }
    </div>
  )
}
