import React, {useEffect, useState, useRef} from "react";

export default function MultlineInput({ onChange, indent, underline, bottomLine, editable, resizeable, style, ...otherProps}){
  const [height, setHeight] = useState(30);
  const inputRef = useRef();

  useEffect(() => {
    if(!inputRef) console.error("Must provide a ref for MultiLineInput");
    setHeight(inputRef?.current?.scrollHeight + 5)
  }, []);

  return (
    <textarea
      className={"ClauseTextInputArea" + (indent? " indent" : "") + (underline? " underline" : "") + (bottomLine? " bottomLine" : "") + (resizeable===false? " noResize" : "") + (editable==false? " not-editable" : "")}
      type="textarea"
      ref={inputRef}
      style={{...style, height}}
      onInput={() => {
        setHeight(inputRef?.current?.scrollHeight || 30)
        // console.log("mli scroll height: ", inputRef.current.scrollHeight);
        // console.log("mli offset height: ", inputRef.current.offsetHeight);
      }}
      onChange={onChange}
      {...otherProps}
    />
  )
}
